import React from "react";
import styled from 'styled-components';

var mobile = require('is-mobile');
const IS_MOBILE = mobile();

const Container = styled.div`
    box-sizing: border-box;
    color: black;
    height: 1rem;
    width: 100%;
    font-size: 0.8125rem;
    font-weight: 400;
    font-family: Roboto;
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      box-sizing: border-box;
      a {
        color: black !important;
      }
    };
    &.mobile {
      height: auto;
      .content {
        justify-content: center;
        align-items: center;
        padding: 2.5rem 0;
        flex-direction: column-reverse;
        gap: 1rem;
        p {
          margin: 0;
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
      }
    }
`


const Footer = ({children, ...props}) => {
  return (
    <Container {...props}  className={IS_MOBILE ? "mobile" : ""}>
      <div className="content">
        <p>© Copyright 2024 – Honest 
          Resume. <a href='/terms_and_conditions.html' target='__blank'>Terms and 
          conditions</a> – <a href='/privacy_policy.html' target='__blank'>Privacy 
          policy</a> – <a href='/cookie_policy.html' target='__blank'>Cookie policy</a></p>
      </div>

    </Container>
  );
}

export default Footer;