import React from "react";
import styled from 'styled-components';
import Spinner from "./Spinner";
import { useAtom } from 'jotai';
import { loadingAtom } from "../atoms";

const Container = styled.div`
  display: ${props => props.$isLoading ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  z-index: 999;
`

const LoadingScreen = () => {
  const [ isLoading ] = useAtom(loadingAtom);
  return (
    <Container $isLoading={isLoading}>
      <Spinner/>
    </Container>
  );
}

export default LoadingScreen;