import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import Select from 'react-select/creatable';
import { useAtom } from 'jotai';
import { skillAtom } from "../atoms";
import { MdClose } from 'react-icons/md';

const Container = styled.div`
display: flex;
gap: 2rem;
align-items: center;
.skill-select {
  width: 50%;
  .skill-select__control {
    border: none !important;
    border-bottom: 1px solid var(--color-gray0) !important;
    box-shadow: none !important;
    .skill-select__value-container {
      padding: 0;
      min-height: 3.5rem;
    }
    .skill-select__placeholder {
      color:  var(--color-gray1);
      font-size: 1rem;
      padding: 0;
    }
  }
  .skill-select__menu {
    margin-top: -1px !important;
    padding: 0 !important;
    .skill-select__menu-list {
      padding: 0 !important;
      .skill-select__option {
        font-size: 0.9rem !important;
      }
    }
  }
}

.yoe-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  > svg {
    position: absolute;
    top: 0.5rem;
    right: -2rem;
    color: var(--color-gray1);
    cursor: pointer;
    display: none;
  }
}

&:hover {
  .yoe-wrapper {
    > svg {
      display: block;
    }
  }
}

.yoe-select {
  min-width: 5rem;
  max-width: 5rem;
  .yoe__control {
    border: none !important;
    box-shadow: none;
    color: black;
    font-weight: 400;
    font-family: Roboto;
    padding-left: 0;
    border: none !important;
    border-bottom: 1px solid var(--color-gray0) !important;
    box-shadow: none !important;
    .yoe__value-container {
      padding: 0;
      min-height: 3.5rem;
    }
    .yoe__placeholder {
      color: var(--color-gray1);
      font-size: 0.9em;
    }
    .yoe__single-value {
      color: black; 
      font-weight: 400 !important;
      font-family: Roboto;
      font-size: 0.9em;
    }
  }
  .yoe__menu {
    margin-top: 0 !important;
    padding: 0 !important;
    .yoe__menu-list {
      padding: 0 !important;
      .yoe__option {
        color: black;
        height: 2rem;
        font-weight: 400 !important;
        font-family: Roboto;
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
        padding: 0.5rem;
        font-size: 0.9em;
        &.yoe__option--is-selected {
          color: white;
        }
        &.yoe__option--is-focused {
          
        }
      }
    }
  }
}

`

const YOE_OPTIONS = [
  {label: '1', value: '1'}, 
  {label: '2', value: '2'}, 
  {label: '3', value: '3'}, 
  {label: '4', value: '4'},
  {label: '5', value: '5'}, 
  {label: '6', value: '6'}, 
  {label: '7', value: '7'}, 
  {label: '8', value: '8'},
  {label: '9', value: '9'}, 
  {label: '10+', value: '10+'}
];

const SkillYOESelector = ({ 
  skillValue: initialSkillValue, 
  yoeValue: initialYOEValue,
  forbiddenSkills = [],
  onChange,
  onDelete
 }) => {
  const selectInputRef = useRef();
  const [skills] = useAtom(skillAtom);
  const [options, setOptions] = useState([]);
  const [skillValue, setSkillValue] = useState(initialSkillValue);
  const [yoeValue, setYOEValue] = useState(initialYOEValue || '1');

  const createOption = (label) => ({
    label: label.toLowerCase().trim(),
    value: label.toLowerCase().trim(),
  });

  useEffect(() => {
    if (skills) {
      const allowedSkills = skills.filter(item => !forbiddenSkills.includes(item));
      setOptions(allowedSkills.map(item => createOption(item)))
    }
  }, [skills, forbiddenSkills]);

  const onSelectChange = (item) => {
    setSkillValue(item.value);
    onChange({skill_name: item.value, yoe: yoeValue});
  }

  const onYOEChange = (item) => {
    setYOEValue(item.value);
    onChange({skill_name: skillValue, yoe: item.value});
  }

  const isValidNewOption = (inputValue, value, options) => {
    if (!inputValue?.trim()) return false;
    const fromattedInputValue = inputValue.toLocaleLowerCase().trim();
    if (value?.find(item => item.value === fromattedInputValue)) return false;
    if (options?.find(item => item.value === fromattedInputValue)) return false;
    if (forbiddenSkills.includes(fromattedInputValue)) return false;
    return true;
  }

  const formatValue = (stringValue) => {
    if (!stringValue) return undefined;
    const formattedValue = stringValue.toLocaleLowerCase().trim();
    const option = options.find(a => a.value === formattedValue);
    if (option) {
      return option
    } else {
      return createOption(formattedValue);
    }
  }

  const formatYOEValue = (value) => {
    return YOE_OPTIONS.find(item => item.value === value);
  }

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    setOptions((prev) => [...prev, newOption]);
    setSkillValue((prev) => {
      onChange(newOption.value);
      return newOption.value;
    });
  };

  const yoeLabel = yoeValue === '1' ? 'year' : 'years';

  return (
    <Container>
      <Select
        className="skill-select"
        classNamePrefix="skill-select" 
        closeMenuOnSelect={true}
        placeholder={'Select a skill'} 
        ref={selectInputRef}
        isValidNewOption={isValidNewOption}
        isDisabled={false}
        createOptionPosition="first"
        formatCreateLabel={(value) => `Add "${value?.toLocaleLowerCase()}"`}
        onChange={onSelectChange}
        value={formatValue(skillValue)}
        onCreateOption={handleCreate}
        options={options}
        maxMenuHeight={'10.2rem'}
      />
      {
        skillValue &&
        <div className="yoe-wrapper">
          <Select
            className="yoe-select"
            classNamePrefix="yoe" 
            placeholder="Any" 
            options={YOE_OPTIONS}
            value={formatYOEValue(yoeValue)}
            onChange={onYOEChange}
            isSearchable={false}
            maxMenuHeight={'10.2rem'}
          />
          <span>{yoeLabel}</span>
          {
            onDelete &&
            <MdClose onClick={onDelete}/>
          }
        </div>
      }
    </Container>
  );  
}

export default SkillYOESelector;
