import React from "react";
import styled from 'styled-components';
import { useAuth } from "../hooks";
import PageContainer from "../components/PageContainer";

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  flex:1;
  text-align: left;
  padding: 0 2.5rem;
  box-sizing: border-box;
  margin-top: 5rem;
  h3 {
    margin: 2rem 0 1rem;
    font-size: 1rem;
    font-weight: 500;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin: 0 0 1rem;
    a {
      color: black !important;
    }
  }
`;

const GetStarted = () => {
  useAuth(false);
 
  return (
    <>
      <PageContainer 
        selected={'get-started'}
        title={'Get started'}
      >
        <Container>
        <h3>What you need to do</h3>
        <p>
          1) Sign up for the trial.<br/>
          2) Create a role, a role is an open position(software engineering only) you are currently hiring for. 
          Set qualification filters for it.<br/>
          3) On Resume Assessment page select the role you created and Upload resumes.<br/>
          4) Review the assessment results for the resume, you can sort them by most to least ualified.<br/>
          5) Once you decide to upgrade beyond thhe trial period and have purchased credits, please follow the ATS 
          Integration page from the left menu to integrate with your ATS. We will read the candidate resumes 
          automatically from the ATS API, you won't have to upload them.
        </p>
        <h3>How it works</h3>
        <p>
          1) We currently support resume files in .pdf, .doc and .docx formats.<br/>
          2) Uploaded resumes are converted to text format.<br/>
          3) Attributes of the candidate: contact info, employment, education, 
          etc. are parsed from this text copy.<br/>
          4) Filters you defined when creating the role are applied on these attributes and its determined whether 
          the candidate qualifies that filter.<br/>
          5) Sort by time of upload or most to least qualified and review assessments. 
          Click on the name of the candidate and the resume will open in a new tab or will be 
          downloaded (if in *.doc/*.docx format).<br/>
        </p>
        <h3>Legal Terms</h3>
        <p>
          &#x2022; <a href='/terms_and_conditions.html' target='__blanks'>Terms and conditions</a><br/>
          &#x2022; <a href='/privacy_policy.html' target='__blanks'>Privacy policy</a><br/>
          &#x2022; <a href='/cookie_policy.html' target='__blanks'>Cookie policy</a><br/>
        </p>

        <h3>Contact</h3>
        <p>Feel free to send us an email 
          at <a href='mailto: info@honestresume.io' target='__blanks'>info@honestresume.io</a></p>
        </Container>
      </PageContainer>
    </>
  );
};

export default GetStarted;
