import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import Spinner from "./Spinner";
import Modal from 'react-modal';

var mobile = require('is-mobile');
const IS_MOBILE = mobile();

const ModalContent = styled.div`
  max-height:  calc(100vh - 3rem);

  button.close-modal {
    display: flex;
    appearance: none;
    flex-direction: column;
    align-items: center;
    color: gray;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0.5rem;
    right: 0.3rem;
  }
`
const LoadingScreen = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  justify-content: center;
  align-items: center;
  div {
    font-family: "Roboto", sans-serif;
    margin-top: 1rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 300;
  }
`

const StyledModal = ({ 
  onClose, 
  isOpen, 
  loading, 
  children, 
  modalStyle, 
  unclosable, 
  loadingLabel,
  hideCrossButton,
  ...props 
}) => {
  const [visible, setVisible] = useState(!!isOpen);

  useEffect(() => {
    setVisible(isOpen);
  }, [isOpen]);


  const onRequestClose = () => {
    if (!loading && !unclosable) {
      setVisible(false);
      onClose();
    }
  }

  const onAfterOpen = () => {}

  const styleContent = Object.assign({
    top: '20%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, 0)',
    maxWidth: IS_MOBILE ? '90%' : '600px',
    width: IS_MOBILE ? 'auto' : '50%',
    minWidth: IS_MOBILE ? 'auto' : '400px',
    boxSizing: 'border-box',
    maxHeight: 'calc(100vh - 1rem)',
    overflow: 'hidden'
  }, modalStyle)

  const style = {
    content: styleContent,
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      zIndex: 101
    }
  }

  return  (
    <Modal
      isOpen={visible}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      contentLabel="Example Modal"
      style={style}
      overlayClassName="Overlay"
      appElement={document.getElementById('root') || undefined}
      {...props}
    >
      <ModalContent>
        {children} 
        {
          !unclosable &&
          !hideCrossButton &&
          <button className='close-modal' onClick={onRequestClose}>
            <MdClose color={'black'} size={'1.4rem'}/>
          </button>
        }
                
      </ModalContent>  
      {
          loading &&
          <LoadingScreen>
            <Spinner />
            {
              loadingLabel &&
              <div>{loadingLabel}</div>
            }
          </LoadingScreen>
        }
    </Modal>   
  )
}

export default StyledModal;