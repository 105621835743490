import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import StyledModal from "./StyledModal";
import BlackButton from "./BlackButton";
import { firebaseEmailTrial } from "../services/firebase";
import { doesEmailExist } from "../services";

var mobile = require('is-mobile');
const IS_MOBILE = mobile();

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 13rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  h3 {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
  }
  .account-exists, .email-sent {
    flex: 1;
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin: 2rem 0;
    text-align: center;
    span {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
  .email-sent-note {
    font-size: 1rem;
    line-height: 1.5;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: var(--color-gray-0);
    margin-top: 2rem;
    text-align: center;
  }

  .contact-form {
    width: 100%;
    position: relative;
    margin: 2rem 0;
    .form-item-group {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }
    .form-item {
      flex: 1;
      position: relative;
      margin-top: 0.9rem;
      padding-top: 0.85rem;
      .email-error-message {
        position: absolute;
        bottom: -1.2rem;
        font-size: 0.8em;
        left: 0;
        color: var(--color-red0);
        font-weight: 400;
      }
      input, textarea {
        border: none;
        outline: none;
        all: unset;
        display: block;
        width: 100%;
        border-bottom: 1px solid var(--color-gray-2);
        height: 3rem;
        padding: 0;
        margin-top: 1.5rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        box-sizing: border-box;
        &::placeholder {
          color: var(--color-gray-0);
          font-size: 1.25rem;
          padding: 0;
        }
        &.error {
          border-bottom: 1px solid var(--color-red-1);
        }
      }
      textarea {
        margin-top: 1rem;
        height: auto; 
        resize: vertical; 
      }
      textarea[name="description"] {
        min-height: 3rem;
      }

      .floating-label {
        position: absolute;
        pointer-events: none;
        color: var(--color-gray1);
        font-size: 1.25em;
        left: 0;
        bottom: 1.5rem;
        font-weight: normal;
        padding: 0;
      }

      textarea:focus~.floating-label,
      textarea:not(:placeholder-shown)~.floating-label,
      input:focus~.floating-label,
      input:not(:placeholder-shown)~.floating-label
      {
        top: 0.5rem;
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        padding: 0;
      }
    }
  }
  button {
    width: 100%;
    height: 2.5rem;
    text-align: center;
    justify-content: center;
    font-size: 0.9rem;
  }
  .controls {
    display: flex;
    gap: 1rem;
  }
  &.mobile {
    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .account-exists, .email-sent {
      flex: 1;
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
`

const StartTrialModal = ({ isOpen, data, onSubmit, onClose }) => {
  const [isTrialEmailLinkSent, setIsTrialEmailLinkSent] = useState(false);
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const [formData, setFormData] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsTrialEmailLinkSent(false);
      setIsDuplicateEmail(false);
      setLoading(false);
      setFormData({
        email: { value: data?.email || '', error: false },
      })
    }
  }, [data, isOpen])

  const validateFormData = (data) => {
    const validatedData = {...data};
    
    let isValid = true;

    if (validateEmail(validatedData['email'].value)) {
      validatedData['email'].error = false;
    } else {
      validatedData['email'].error = true;
      isValid = false;
    }
    
    // Object.keys(validatedData).forEach(key => {
    //   if(validatedData[key].value.trim() === '') {
    //     validatedData[key].error = true;
    //     isValid = false;
    //   } else {
    //     validatedData[key].error = false;
    //   }
    // });
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = () => {
    setFormData({
      email: { value: '', error: false }
    });
  }

  const onInputChange = (e) => {
    const data = {...formData};
    const name  = e.currentTarget.name;
    const value = e.currentTarget.value;
    data[name].value = value;
    data[name].error = false;
    setFormData(data);
  }

  const onSubmitClick = async () => {
    setIsTrialEmailLinkSent(false);
    if (validateFormData(formData)) {
      setLoading(true);
      const data = {};
      for (let key in formData) {
        data[key] = formData[key]?.value;
      }
      const duplicateEmail = await doesEmailExist(data?.email);
      if (duplicateEmail) {
        setIsDuplicateEmail(true);
        setLoading(false);
      } else {
        await firebaseEmailTrial(data?.email);
        setLoading(false);
        setIsTrialEmailLinkSent(true);
      }
    }  
  }

  const onModalClose = () => {
    onClose();
  }

  const tryAgain = () => {
    clearFormData();
    setIsDuplicateEmail(false);
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  const render = () => {
    if (isTrialEmailLinkSent) return <>
      <h3>Verify your email address</h3>
      <div className="email-sent">
        We have sent a verification link to<br/> 
        <span>{formData?.email?.value}</span> <br/>
      </div>
      <BlackButton  onClick={onSubmitClick} >Resend email </BlackButton>
      <div className="email-sent-note">*Click on the link to complete the verification process.
        You might need to check your spam folder.</div>
    </>
    if (isDuplicateEmail) return <>
      <h3>Start your free trial</h3>
      <div className="account-exists">
        An account with email address<br/> 
        <span>{formData?.email?.value}</span> <br/>
        already exists. Please try another.
      </div>
      <BlackButton onClick={tryAgain}>Try Again </BlackButton>
    </>
    return <>
      <h3>Start your free trial</h3>
      <form className="contact-form">
        <div className="form-item" style={{maxWidth: '100%'}}>
          <input 
            placeholder="   "
            name="email"
            type="text"
            onChange={onInputChange}
            value={formData?.email?.value || ''}
            className={formData?.email?.error ? 'error' : ''}
          />
        <label className="floating-label">Email address</label>
        {
          formData?.email?.error &&
          <div className="email-error-message">Please enter a valid work email</div>
        } 
        </div>
      </form>
      <BlackButton 
        onClick={onSubmitClick} 
        disabled={!!formData?.email?.error}>Submit
      </BlackButton>
    </>
  }

  return (
    <StyledModal 
      onClose={onModalClose} 
      isOpen={isOpen}
      loading={isLoading}
      loadingLabel={'Your email is being sent... Please wait.'}
      modalStyle={{
        maxWidth: IS_MOBILE ? '90%' : 'auto',
        width: '33rem',
        minWidth:  IS_MOBILE ? 'auto' : '22rem',
        padding:'3rem 2rem 3rem',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
      <Container className={IS_MOBILE ? "mobile" : ""}>
        { render() }
      </Container>  
    </StyledModal>
  );
}

export default StartTrialModal;