import React, { useState, useRef } from "react";
import styled from 'styled-components';
import NavBar from "../components/NavBar";
import FileSelectButton from "../components/FileSelectButton";
import WhiteButton from "../components/WhiteButton";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import ParsedResume from "../components/ParsedResume";
import { assessResume , assessmentStatus} from "../services";
import { assessResume as fakeAssessResume } from "../services/fakeAPI"; 

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  text-align: left;
  
  .content-area {
    margin-top: 10rem;
    width: 100%;
    max-width: var(--max-content-width);
    padding: 0 2rem;
    box-sizing: border-box;
    .button-container {
      display: flex;
    }
    .slelected-file {
      margin-top: 1rem;
    }
    .separator {
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--color-gray2);
      margin-top: 1rem;
    }
    .uploading-in-progress {
      height: 3.5rem;
      width: 23rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      p {
        margin-left: 1rem;
      }
    }
    .parsed-data {
      margin-top: 2rem;
      font-size: 1.1rem;
    }
    .error {
      margin-top: 1rem;
      color: var(--color-red0);
    }
  }
  @media screen and (max-width: 40rem) {
    .content-area {
      margin-top: 2rem;
    }
  }

`

const Upload = () => {
  const [uploadingStatusLabel, setUploadingStatus] = useState(null);
  const [data, setData]  = useState(null);
  const [errorMessage, setErrorMessage]  = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const loading = useRef(false);
  const onSelect = file => {
    if (file?.name) {
      setData(null);
      setSelectedFile(file);
      setErrorMessage("");
    }
  };

  const assessmentStatusHandler = (uuid, timeout=2000) => {
    setTimeout(async () => {
      let status = null;
      try {
        status = await assessmentStatus(uuid);
      } catch (err) {
        console.error(err)
      }       
      if (loading.current) {
        if (status) {
          setUploadingStatus(status?.progress_status);
        }
        assessmentStatusHandler(uuid, 5000);
      }
    }, timeout)
  };

  const onSubmit = async () => {
    setUploadingStatus('Uploading...');
    setErrorMessage("");
    try {
      loading.current = true;
      const parsedData = await assessResume(selectedFile, assessmentStatusHandler);  
      if (parsedData) {
        setData(parsedData);
      }
    } catch(err) {
      if (err.response.status >= 400 && err.response.status <= 499) {
        setErrorMessage(err.response.data);
      } else {
        setErrorMessage("We have encountered an issue while processing the resume, please try again after sometime.");
      }
    }
    finally {
      loading.current = false;
      setSelectedFile(null);
      setUploadingStatus(null)
    }
  };

  return (
    <>
    <Container className="App">
      <NavBar/>
      <div className="content-area">
        <p>Please select a resume file .pdf, .doc, .docx</p>
        <p>Software engineering (IC and Manager) resumes only</p>
        <div className="button-container">
            {
              uploadingStatusLabel ?
              <div className="uploading-in-progress">
                <Spinner/>
                <p>{uploadingStatusLabel}</p>
              </div> :
              <FileSelectButton accept=".doc,.docx,.pdf" onChange={onSelect}/>
            }
            
            <WhiteButton 
              style={{
                padding: '0 2rem', 
                height: '3.5rem', 
                marginLeft: '1.5rem'
              }}
              disabled={!selectedFile || uploadingStatusLabel}
              onClick={onSubmit}
            >Submit</WhiteButton>
        </div>
        {
          selectedFile &&
          <div className="slelected-file">Selected file: {selectedFile?.name}</div>
        }
        {
          errorMessage &&
          <div className="error">{errorMessage}</div>
        }
        <br/>
        <div className='separator'/>
        <ParsedResume data={data}/>
      </div>  
    </Container>
    <Footer/>
    </>
  );
};

export default Upload;