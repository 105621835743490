import React from "react";
import styled from 'styled-components';


const Container = styled.div`
  width: 7.45rem;
  height: 0.47rem;
  background: #D3D3D3;
`;

export default function GreyBar(){
  return <Container className='grey-bar'/>;
}