import React from "react";
import styled from 'styled-components';
import StyledModal from "./StyledModal";
import { useAtom } from 'jotai';
import { parsedResumeModalAtom } from "../atoms";
import { openFileInNewTab } from '../services';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  h3 {
    padding: 0;
    margin: 0 0 2rem;
  }
  span {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: left;
  }
  .label-container {
    display: flex;
    width: max-content;
    width: 100%;
    margin-top: 1rem;
    div {
      flex: 0.5;
      font-size: 0.8rem;
      &:first-child {
        margin-right: 1rem;
      }
      &:last-child {
        margin-left: 1rem;
      }
    }
  }
  .resume-link {
    color: var(--color-blue1);
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
  }
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    margin-top: 1rem;
    li {
      margin-bottom: 0.5rem;
      list-style: none;
      font-size: 0.8rem;
      border-radius: 1.7rem;
      padding: 0.5rem 1rem;
      width: fit-content;
      &.red {
        background-color: var(--color-red1);
      }
      &.green {
        background-color: var(--color-green0);
      }
    }
  } 
`

const ParsedResumeModal = () => {
  const [ data, setResumeOpen ] = useAtom(parsedResumeModalAtom);

  const onClose = () => {
    setResumeOpen(null);
  }
  const openLink = (resume_file_name) => {
    if (data) {
      openFileInNewTab(data.resume_s3_path, resume_file_name);
    }
  }

  const resumeName = data && data?.resume_s3_path?.split('/').pop();
  const required = data && Object.values(data.requirement_label);
  const preferred = data && Object.values(data.preferred_label);

  return (
    <StyledModal 
      onClose={onClose} 
      isOpen={!!data}
      modalStyle={{
        width: '35rem',
        minWidth: '35rem',
        padding:'2.5rem 2rem 2rem'
      }}
    >
      <Container>
        {
          data &&
          <> 
            <h3>{data.name}</h3>
            <span className="resume-link" onClick={() => openLink(resumeName)}>{resumeName}</span>
            <div className="label-container">
              <div className="label-required">
                <div>Required</div>
                <ul>
                  {
                    required?.map((item, i) => (
                        <li key={i} className={item.color}><b>{item.name}</b>: {item.result}</li>
                    ))
                  }
                </ul>
              </div>
              <div className="label-preferred">
                <div>Preferred</div>
                <ul>
                  {
                    preferred?.map((item, i) => (
                        <li key={i} className={item.color}><b>{item.name}</b>: {item.result}</li>
                    ))
                  }
                </ul>

              </div>
            </div>
          </>
           
        }
      </Container>  
    </StyledModal>
  );
}

export default ParsedResumeModal;