import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { getBillingData } from "../services";
import { useAuth } from '../hooks';
import RoleNavMenu from "../components/RoleNavMenu";
import Spinner from "../components/Spinner";
import Select from 'react-select';
import BlackButton from "../components/BlackButton";
import PaymentsModal from "../components/PaymentsModal";
import PageContainer from "../components/PageContainer";
import { Tooltip } from 'react-tooltip';

const Container = styled.div`
    margin-top: 7rem;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: row;
    .content {
      flex: 1;
      position: relative;
      padding: 0 0.5rem;
      font-family: Roboto;
      font-weight: 400;
      .data-container {
        >button {
          font-size: 0.9rem;
          padding: 0.4rem 0.8rem;
        }
        >div {
          font-size: 1rem;
          line-height: 1.5;
          &.pay-as-you-go {
            font-weight: 500;
            font-size: 1.25rem;
          }
          &.credit-label {
            margin-top: 1rem;
            font-weight: 400;
            font-size: 1rem;
            position: relative;
            width: fit-content;
            span {
              font-size: 0.8rem;
              display: block;
              position: absolute;
              top: 0.1rem;
              right: -1.2rem;
            }
          }
          &.credit-balance {
            margin-top: 0;
            font-size: 2rem;
            margin-bottom: 1rem;
            line-height: 1.25;
          }
          &.usage-label {
            font-weight: 400;
            font-size: 1rem;
            position: relative;
            width: fit-content;
            label {
              min-width: 15.5rem;
                  display: inline-block;
              }
            }
          }
 
        }
        button {
          margin-top: 0.5rem;
        }
      }
      .spinner-container {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        left: 15rem;
        justify-content: center;
        display: flex;
        align-items: center;
      }

    }
`

const Billing = () => {
  const [ loading, setLoading ] = useState(false);
  const [ data, setData ] = useState(null);
  const isLoading = useRef(false);
  const [isPaymentsModalOpen, setPaymentsModalOpen] = useState(false);
  useAuth();

  useEffect(() => {
    const getBilling = async () => {
      isLoading.current = true;
      setLoading(true);
      try {
        const data = await getBillingData();
        setData(data);
      } catch (err) {
        console.log(err);
      } finally {
        isLoading.current = false;
        setLoading(false);
      }
    }  
    if (!isLoading.current) {
      getBilling();
    }
  }, [])

  const onPaymentsModalClose = () => {
    setPaymentsModalOpen(false);
  }

  const onAddMoneyClick = () => {
    setPaymentsModalOpen(true);
  }

  const updateBalance = (data) => {
    setData(data);
  }

  return (
    <>
      <PageContainer title={'Billing'} selected={'billing'}>
        <Container>
          <div className="content">
            {
              loading ?
              <div className="spinner-container"><Spinner/></div> :
              <div className="data-container">
                {
                  data &&
                  <>
                    <div className="pay-as-you-go">Pay as you go</div>
                    <div 
                      className="credit-label"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-place="right-end"
                    >
                      Credit balance <span>&#9432;</span>
                    </div>
                    <div className="credit-balance">${data.credit_balance}</div>
                    <BlackButton onClick={onAddMoneyClick}>Add to credit balance</BlackButton>
                    <br/>
                    <br/>
                    <div className="pay-as-you-go">Current Usage</div>
                    <div className="usage-label" style={{marginTop: '0.5rem'}}>
                      <label>Resumes successfully assessed:</label> 
                      <b>{data.cnt_resume_assessed}</b>
                    </div>
                    <div className="usage-label">
                      <label>Resumes we could not process:</label>
                      <b>{data.cnt_could_not_assess}</b>
                    </div>
                    <div className="usage-label">
                      <label>Resume assessments left:</label> 
                      <b>{data.cnt_resume_assessment_left}</b>
                    </div>
                  </>
                }      
              </div>
            }
          </div>   
      </Container>
      </PageContainer>
      <PaymentsModal
        updateBalance={updateBalance}
        isOpen={isPaymentsModalOpen}
        onClose={onPaymentsModalClose}
      />
      <Tooltip id="my-tooltip">
          <div style={{width: '13.5rem'}}>Your credit balance will be consumed as you use the API. Details of usage are below.</div>
      </Tooltip>
    </>
  );
};

export default Billing;