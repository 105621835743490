import React, { useState } from "react";
import styled from 'styled-components';
import StyledModal from "./StyledModal";
import BlackButton from "./BlackButton";
import { loginFirebaseUser, firebaseEmailSignup, loginGoogleUser } from "../services/firebase";
import { useAtom } from 'jotai';
import { loadingAtom, loginModalAtom } from "../atoms";
import { doesEmailExist } from "../services";
import { validateEmail } from "../utils";
import { ReactComponent as EyeIcon } from "../assets/images/eye.svg";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { ReactComponent as GoogleIcon } from "../assets/images/google.svg";
import WhiteButton from "./WhiteButton";


const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  h3 {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
  }
  .invalid-creds {
    color: var(--color-red-1);
    font-weight: 400;
    margin-bottom: 1.5rem;
    text-align: left;
    width: 100%;
    margin-top: -0.5rem;
    font-size: 0.9rem;
  }
  .invalid-creds2 {
    color: var(--color-red-1);
    font-weight: 400;
    margin-bottom: 0rem;
    text-align: left;
    width: 100%;
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  .contact-form {
    width: 100%;
    position: relative;
    margin: 2rem 0;
    .password-wrapper {
      position: relative;
      svg {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        bottom: 0.75rem;
        right: 1rem;
      }
    }
   
    input, textarea {
      all: unset;
      display: block;
      width: 100%;
      border-bottom: 1px solid var(--color-gray-2);
      height: 3rem;
      padding: 0 1rem;
      margin-top: 1.5rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      box-sizing: border-box;
      &[name="password"] {
        padding: 0 3rem 0 1rem;
      }
      
      &::placeholder {
        color: var(--color-gray-0);
        font-size: 1.25rem;
      }
      &.error {
        border-bottom: 1px solid var(--color-red-1);
      }
    }
    textarea::placeholder {
      transform: translateY(1rem);
    }
    .message-received {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: white;
      display: flex;
      justify-content: center;
      padding: 4rem 0 0 0;
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  button {
    width: 100%;
    height: 2.5rem;
    text-align: center;
    justify-content: center;
    font-size: 1rem;
  }
  .signup-link {
    cursor: pointer;
    color: var(--color-blue1);
    margin-bottom: 0;
  }
  .setup-complete-message {
    text-align: center;
    padding: 3rem 0 5rem;
  }
  .social-sign-in {
    display: flex;
    margin-top: 0.75rem;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #ccc;
    border-radius: 2rem; */
    cursor: pointer;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`

const LoginModal = () => {
  const [ , setLoading ] = useAtom(loadingAtom);
  const [ isLoginOpen, setLoginOpen ] = useAtom(loginModalAtom);
  const [isSignup, setIsSignup] = useState(false);
  const [formData, setFormData] = useState({
    email: { value: '', error: false },
    password: { value: '', error: false }
  });
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [validationMessage, showValidationMessage] = useState(false);
  const [validationMessage2, showValidationMessage2] = useState(false);
  const [passwordHidden, setPasswordHidden] = useState(true);

  const validateFormData = (data) => {
    const validatedData = {...data};
    let isValid = true;
    Object.keys(validatedData).forEach(key => {
      if (isSignup && key !== "email") return;
      const value = validatedData[key].value.trim(); 
      if(value === '') {
        validatedData[key].error = true;
        isValid = false;
      } else if (key === "email" && !validateEmail(value)) {
        validatedData[key].error = true;
        isValid = false;
      } else {
        validatedData[key].error = false;
      }
    });
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = () => {
    showValidationMessage(false);
    showValidationMessage2(false);
    setFormData({
      email: { value: '', error: false },
      password: { value: '', error: false }
    });
  }

  const onLoginClick = async () => {
    if (validateFormData(formData)) {
      setLoading(true);
      const email = formData?.email?.value || '';
      const password = formData?.password?.value || '';
      const user = await loginFirebaseUser(email, password);
      if (user === "failure") {
        setLoading(false);  
        setInvalidCredentials(true);
        showValidationMessage(true);
        showValidationMessage2(false);
        console.log("Login failed");
      } else {
        setLoginOpen(false);
        clearFormData();
        setLoading(false);  
        setInvalidCredentials(false);
        console.log(`You are now logged in as: ${user.email}`);
        window.location.href = '/resume-assessment';
      }
    } else {
      showValidationMessage(true);
    }
  } 

  const onGoogleClick = async () => {
    const user = await loginGoogleUser();
    if (user === "failure") {
      showValidationMessage(false);
      showValidationMessage2(true);
      console.log("Login failed");
    } else {
      setLoginOpen(false);
      clearFormData();
      setLoading(false);  
      setInvalidCredentials(false);
      console.log(`You are now logged in as: ${user.email}`);
      window.location.href = '/resume-assessment';
    }
  }

  const onClose = () => {
    setLoginOpen(false);
    clearFormData();
    setIsSignup(false);
    setInvalidCredentials(false);
    setPasswordHidden(true);
  }

  const onInputChange = (e) => {
    showValidationMessage(false);
    const data = {...formData};
    data[e.currentTarget.name].value = e.currentTarget.value;
    validateFormData(data);
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13 ) {
      onLoginClick();
    }
  }

  const onEyeIconClick = () => {
    setPasswordHidden(!passwordHidden);
  }

  const renderLogin = () => {
    return (
      <Container>
        <h3>Log in</h3>
        <form className="contact-form" >
          <input 
            name="email"
            placeholder="Email"
            type="text"
            onChange={onInputChange}
            onKeyDown={onKeyDown}
            className={formData?.email?.error ? 'error' : ''}
          />
          <div className="password-wrapper">
            <input 
              name="password"
              placeholder="Password"
              type={passwordHidden ? "password" : "text"}
              onChange={onInputChange}
              onKeyDown={onKeyDown}
              className={formData?.password?.error ? 'error' : ''}
            />
            {
              passwordHidden ? 
              <BsEye onClick={onEyeIconClick}/> : 
              <BsEyeSlash onClick={onEyeIconClick}/>
            }          
          </div>
         
        </form>
        {
          invalidCredentials &&
          validationMessage &&
          <div className="invalid-creds">
            Invalid login or password
          </div>
        }
        <BlackButton onClick={onLoginClick} >Submit</BlackButton>
        <WhiteButton className='social-sign-in' onClick={onGoogleClick}><GoogleIcon /> Sign in with Google</WhiteButton>
        {
          validationMessage2 &&
          <div className="invalid-creds2">
            Coul'd not find your Honest Resume account
          </div>
        }
      </Container>
    )
  }

  return (
    <StyledModal 
      onClose={onClose} 
      isOpen={isLoginOpen}
      modalStyle={{
        width: '33rem',
        minWidth: '22rem',
        padding:'3rem 2rem 3rem'
      }}
    > 
      {
        renderLogin()
      }
    </StyledModal>
  );
}

export default LoginModal;