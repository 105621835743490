import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import Spinner from "./Spinner";
import WhiteButton from "./WhiteButton";

const PAGE_URL = window.location.origin + "/billing";

export default function CheckoutForm({ onSucceed, onError, onCancel }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isElementReady, setElementReady] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: PAGE_URL,
      },
      redirect: 'if_required' 
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {  
        setMessage(error.message);
      } else {
        onError("An unexpected error occurred.");
      }
      // handleError();
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      console.log('paymentIntent', paymentIntent);
      onSucceed(paymentIntent.id);
      // handleSuccess();
    } else {
      onError("An unexpected error occurred.");
      // handleOther();
    }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    defaultCollapsed: false,
  }
  const onReady = () => {
    setElementReady(true);
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement onReady={onReady} id="payment-element" options={paymentElementOptions} />
      {
        isElementReady &&
        <div className="control-buttons">
           <WhiteButton onClick={onCancel}>Cancel</WhiteButton>
            <button 
              disabled={isLoading || !stripe || !elements} 
              id="submit">
                {
                  isLoading ? 
                  <Spinner 
                    size="1.5rem"
                    color='white'
                    secondaryColor="grey"
                  /> : 
                  <span id="button-text"> Pay now </span>
                }
          </button>
        </div>
       
      }
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}