import React from "react";
import styled from 'styled-components';
import { useNavigate } from 'react-router';

const Container = styled.div`
  width: 12rem;
  display: flex;
  flex-direction: column;
  div {
    display: inline-block;
    text-decoration: none;
    color: var(--color-blue1) !important;
    font-weight: 500;
    margin-bottom: 0.5rem; 
    cursor: pointer;
    &:hover, &.selected {
      font-weight: 600;
    }
  }
`

const PlainNavMenu = () => {
  const navigate = useNavigate()
  return <Container className="plain-nav-menu">
      <div id="assessments" onClick={() => navigate("/assessments")}>Assessments</div>
      <div id="integrations" onClick={() => navigate("/integrations")}>API Keys</div>
  </Container>
}

export default PlainNavMenu;