import React from "react";
import styled from 'styled-components';

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
    font-size: 0.75rem;
    line-height: 1.25rem;
    gap: 0.5rem;
    font-family: 'Roboto';
    font-weight: 400;
    color: var(--color-gray-1);
    > div {
      margin: 0 0.5rem;
      cursor: pointer;
      &.selected {
        color: black;
        font-weight: 400;
      }
    }
`
const PREFIX_LENGTH = 4;

const PaginationControl = ({selected, total, onClick}) => {
  const onNumberClick = (el) => {
    onClick(parseInt(el.currentTarget.dataset['number']));
  }

  const renderPageNumbers = (start, end) => {
    return new Array(end-start+1).fill(start).map((a, i) => (
      <div data-number={a+i} key={a+i} className={a+i === selected ? 'selected' : ''} onClick={onNumberClick}>{a+i}</div>
    ))
  }

  const render = () => {
    if (total <= PREFIX_LENGTH + 1) {
      return renderPageNumbers(1, total);
    }
    if (selected <= PREFIX_LENGTH-1) {
      return <>
      { renderPageNumbers(1, PREFIX_LENGTH) }
      <div>...</div>
      <div data-number={total} onClick={onNumberClick}>{total}</div>
      </>
    }
    if (total - selected >= PREFIX_LENGTH-1) {
      return <>
        <div data-number="1" onClick={onNumberClick}>1</div>
        <div>...</div>
        { renderPageNumbers(selected-1, selected+1) }
        <div>...</div>
        <div data-number={total} onClick={onNumberClick}>{total}</div>
      </>
    }
    return <>
      <div data-number="1" onClick={onNumberClick}>1</div>
      <div>...</div>
      { renderPageNumbers(total-PREFIX_LENGTH+1, total) }
    </>
  }

  return (
    <Container>
      {render()}
    </Container>
  );
}

export default PaginationControl;