
import React from "react";
import styled from 'styled-components';
import Select from 'react-select';

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    font-size: 0.9rem;
  
    .major-select {
      max-width: 7rem;
      min-width: 7rem;
      .major__control {
        border: 1px solid var(--color-gray0);
        box-shadow: none;
        height: 2rem;
        color: black;
        font-weight: 400;
        font-family: Roboto;
        padding: 0 0.5rem;
        .major__value-container {
          padding: 0;
        }
        .major__placeholder {
          color: var(--color-gray1);
          font-size: 0.9em;
        }
        .major__single-value {
          color: black; 
          font-weight: 400 !important;
          font-family: Roboto;
          font-size: 0.9em;
        }
        .major__indicators {
          .major__indicator-separator {
            display: none;
          }
          .major__dropdown-indicator {
            color: black !important;
            font-weight: 400 !important;
            font-family: Roboto;
            opacity: 0.5;
            padding: 0.5rem 0;
          }
        }
      }
      .major__menu {
        margin-top: 0 !important;
        padding: 0 !important;
        .major__menu-list {
          padding: 0 !important;
          .major__option {
            color: black;
            height: 2rem;
            font-weight: 400 !important;
            font-family: Roboto;
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
            padding: 0.5rem;
            font-size: 0.9em;
            &.major__option--is-selected {
              color: white;
            }
            &.major__option--is-focused {
              
            }
          }
        }
      }
    }
`

const MAJOR_LIST = [
  {label: 'CS related', value: 'CS related'}, 
  {label: 'STEM', value: 'STEM'}, 
  {label: 'Other', value: 'Other'}
];


const MajorSelect = ({value, onChange}) => {
  const onMajorSelect = (item) => {
    onChange(item.value);
  }
  return (
    <Container>
      <Select
        className="major-select"
        classNamePrefix="major" 
        placeholder="Major" 
        options={MAJOR_LIST}
        onChange={onMajorSelect}
        isSearchable={false}
        value={MAJOR_LIST.find(c => c.value === value) || null}
      />
    </Container>
  );
}

export default MajorSelect;