import { array, func } from "prop-types";
import React, { useState } from "react";
import styles from "./drop-zone.module.css";
import { getDroppedOrSelectedFiles } from "./Html5FileSelector";

const Banner = ({ onClick, onDrop, disabled = false }) => {
  const handleDragOver = (ev) => {
    if (!disabled) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.dataTransfer.dropEffect = "copy";
    }
  };

  const handleDrop = async (ev) => {
    if (!disabled) {
      ev.preventDefault();
      ev.stopPropagation();
      let files = await getDroppedOrSelectedFiles(ev);
      const numberOfDroppedFiles = files.length;
      files = files.filter(file => ['doc', 'docx', 'pdf'].includes(file.fullPath.split('.').pop()));
      console.log(files.length, numberOfDroppedFiles);
      onDrop(files, numberOfDroppedFiles);
    }
  };

  return (
    <div
      className={styles.banner}
      onClick={onClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <span className={styles.banner_text}>Click to Add resumes</span>
      <span className={styles.banner_text}>or</span>
      <span className={styles.banner_text}>Drag and Drop resumes here</span>
    </div>
  );
};

const DropZone = ({ onChange, accept = ["*"], disabled = false }) => {
  const inputRef = React.useRef(null);
  const [warning, setWarning] = useState(null);

  const handleClick = () => {
    if (!disabled) {
      inputRef.current.click();
    }
  };

  const handleChange = async (ev) => {
    if (!disabled) {
      const files = await getDroppedOrSelectedFiles(ev);
      onChange(files);
    }
  };

  const handleDrop = (files, numberOfDroppedFiles) => {
    if (!disabled) {
      onChange(files);
      if (files.length < numberOfDroppedFiles) {
        setWarning("Only resume files of type pdf/doc/docx are supported.")
      } else {
        setWarning(null);
      }
    } 
  };

  return (
    <div className={styles.wrapper}>
      <Banner onClick={handleClick} onDrop={handleDrop} disabled={disabled}/>
      {
        warning &&
        <div className={styles.warning}>{warning}</div>
      }
      <input
        type="file"
        aria-label="add files"
        className={styles.input}
        ref={inputRef}
        multiple="multiple"
        onChange={handleChange}
        accept={accept.join(",")}
      />
    </div>
  );
};

DropZone.propTypes = {
  accept: array,
  onChange: func,
};

export { DropZone };
