import React from "react";
import styled from 'styled-components';

const Container = styled.button`
    all: unset;
    box-sizing: border-box;
    display: flex;
    color: white;
    border-radius: 3px;
    align-items: center;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    font-family: Roboto;
    cursor: pointer;
    color: #000;
    background-color: white;
    box-shadow: unset; 
    height: fit-content;
    border: 2px solid #000;
    &:hover, &:active {
      color: #000;
      box-shadow: 1px 2px 5px 0 var(--drop-shadow);
    }
    ${props => props.disabled && `
      color: rgba(188, 188, 188) !important;
      border-color: rgba(188, 188, 188) !important;
      background-color: white !important;
      cursor: unset !important;
    `}

`

const WhiteButton = ({children, disabled, onClick, ...props}) => {
  const onButtonClick = () => {
    if (!disabled) {
      onClick();
    }
  }
  return (
    <Container {...props} disabled={disabled} onClick={onButtonClick}>
      {children}
    </Container>
  );
}

export default WhiteButton;