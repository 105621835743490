import React from "react";
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { parsedResumeModalAtom } from "../atoms";
import { meetsRequirements } from "../utils";

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    background-color: var(--color-green0);
    width: 100%;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 1rem;
    font-size: 1rem;
    cursor: pointer; 
    .name {
      font-weight: 500;
      min-width: 10rem;
    }  
    .date {
      min-width: 10rem;
      text-align: right;
    }
    .required-preffered {
      span {
        margin: 0 1rem;
      }
    }
`

const AssessmentListItem = ({data}) => {
  const [ , setResumeOpen ] = useAtom(parsedResumeModalAtom);
  const onClick = () => {
    setResumeOpen(data);
  }
  const dataLabel =  () => {
    const delta = Date.now() - data.dt_applied;
    if (delta < 24*3600000) {
      return 'Today';
    } else if (delta < 48*3600000) {
      return 'Yesterday';
    } else {
      return new Date(data.dt_applied).toLocaleDateString('en-US');
    }
  }

  const totalPreferred = Object.keys(data.preferred_label).length;
  const totalRequired = Object.keys(data.requirement_label).length;
  
  return (
    <Container 
      onClick={onClick} 
      style={{
        backgroundColor: meetsRequirements(data) ? 
        `var(--color-green0)` :  `var(--color-red1)`
      }}>
      <div className="name">{data.name}</div>
      <div className="required-preffered">
        <span>Required: <b>{data.requirement_match_count}/{totalRequired}</b></span>
        <span>Preferred: <b>{data.preferred_match_count}/{totalPreferred}</b></span>
      </div>
      <div className="date">{data.dt_applied}</div>
    </Container>
  );
}

export default AssessmentListItem;