import { initializeApp } from "firebase/app";
import { 
    getAuth, 
    getIdToken, 
    onAuthStateChanged, 
    signInWithEmailAndPassword, 
    sendSignInLinkToEmail, 
    isSignInWithEmailLink, 
    signInWithEmailLink, 
    updatePassword,
    signInWithPopup, 
    GoogleAuthProvider
} from "firebase/auth";
import { signup, doesEmailExist } from ".";
import { getUrl } from "../configuration";

// Firebase configuration. These values are safe to be public
const firebaseConfig = {
  apiKey: "AIzaSyAWgRnEw1BTVOWj2W8fdwti_NdDg4BpArY",
  authDomain: "honest-resume-59184.firebaseapp.com",
  projectId: "honest-resume-59184",
  storageBucket: "honest-resume-59184.appspot.com",
  messagingSenderId: "248724747513",
  appId: "1:248724747513:web:d58b58c02342714d7c1e28",
  measurementId: "G-WQPC0Y4Q4P"
};
  
// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);

export const firebaseGoogleProvider = new GoogleAuthProvider();

export const getUserToken = async () => {
    return new Promise((resolve, reject) => {
        const unsub = onAuthStateChanged(firebaseAuth, async (user) => {
            if (user) {
                const token = await getIdToken(user);
                resolve(token)
            } else {
                resolve(null)
            }
            // per call to getUserToken, we only want to check auth state once
            // so we need to unsubscribe at the end of the callback
            unsub();
        });
    })
}

export const firebaseEmailTrial = async (email) => {
  const actionCodeSettings = {
      url: `${await getUrl()}signup/?trial=${email}`,
      handleCodeInApp: true,
  };
  try {
      await sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings);
      localStorage.setItem("signupEmail", email);
      return "success";
  } catch(err) {
      return "failed"
  }
}

export const firebaseEmailSignup = async (email) => {
    const actionCodeSettings = {
        url: `${await getUrl()}signup`,
        handleCodeInApp: true,
    };
    try {
        await sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings);
        localStorage.setItem("signupEmail", email);
        return "success";
    } catch(err) {
        return "failed"
    }
}

export const validateSignupWithEmailLink = () => {
    return isSignInWithEmailLink(firebaseAuth, window.location.href);
}

export const completeFirebaseEmailSignup = async (email, password, fullName, companyName, title) => {
    if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
        try {
            const result = await signInWithEmailLink(firebaseAuth, email, window.location.href)
            await signup(email, fullName, companyName, title, result.user.uid)
            await updatePassword(result.user, password)
            localStorage.setItem('signupEmail', '');
            return result.user;
        } catch (err) {
            console.log(err.code, err);
            if (err.code === "auth/invalid-action-code") return "alreadyUsed";
            return "differentEmail"
        }
      }
}

export const loginFirebaseUser = async (email, password) => {
    try {
        const user = await signInWithEmailAndPassword(firebaseAuth, email, password);
        return user.user;
    } catch(err) {
        return "failure";
    }
}

export const loginGoogleUser = async () => {
  try {
    const result = await signInWithPopup(firebaseAuth, firebaseGoogleProvider);
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    const isExistingUser = await doesEmailExist(user.email);
    if (!isExistingUser) {
      await signup(user.email, user.displayName, '', '', user.uid);
    }
    return user;
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  } catch(error) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    //const email = error?.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
    return "failure";
  }
}

export const logoutFirebaseUser = async () => {
    await firebaseAuth.signOut();
}
