import React from "react";
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { loginModalAtom, userAtom } from "../atoms";
import { logoutFirebaseUser } from "../services/firebase";
import WhiteButton from "./WhiteButton";
import { useNavigate } from 'react-router';
import { p2r } from "../utils";

var mobile = require('is-mobile');
const IS_MOBILE = mobile();

const Container = styled.div`
  z-index: 10;
  height: 5.3125rem;
  color: #000;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 15rem;
  right: 0;
  background-color: white;
  justify-content: space-between;
  padding: 0 2.5rem 0 0;
  box-sizing: border-box;
  .nav_container {
    flex: 1;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid var(--light-greye4);
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
    box-sizing: border-box;
    .pc-title {
      position: absolute;
      height: 5.3125rem;
      top: 0;
      left: 2.5rem;
      font-size: 1.5rem;
      font-weight: 700;
      font-family: 'Roboto';
      display: flex;
      align-items: center;
    }

    h3 {
      font-weight: 700;
      font-size: 1.3rem;
      font-family: Roboto;
      color: black;
    }
    nav {
      grid-column-gap: 3rem;
      grid-row-gap: 3rem;
      justify-content: flex-end;
      align-items: center;
      padding-left: 0;
      display: flex;
      position: static;
      .nav_menu-flex {
          height: 100%;
          grid-column-gap: 3rem;
          grid-row-gap: 3rem;
          align-self: stretch;
          margin-top: auto;
          margin-bottom: auto;
          display: flex;
          .menu_buttons {
            grid-template-rows: auto;
            grid-template-columns: 1fr 1fr;
            grid-auto-columns: 1fr;
            justify-content: flex-end;
            align-items: center;
            display: flex;
            .log-out {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: p2r(13);
              line-height: p2r(24);
              /* or 185% */
              text-align: right;
              span {
                text-decoration-line: underline;
                cursor: pointer;
              }
              color: #212121;
            }
        }
      }
    }
  }

  &.mobile {
    .nav_container {
      h3 {
        font-size: 1.25rem;
      }
      nav {
        .nav_menu-flex {
          .menu_buttons {
            .log-out, .log-in {
              display: none;
            }
          }
        }
      }
    }
  }
`

const NavBar = ({title, controls=true, ...props}) => {
  const navigate = useNavigate();
  const [,setLoginOpen] = useAtom(loginModalAtom);
  const [user] =  useAtom(userAtom);

  const onLoginClick = () => {
    // setLoginOpen(true);
    navigate('/login');
  }

  const onLogoutClick = () => {
    logoutFirebaseUser();
  }

  return (
    <Container {...props} className={IS_MOBILE ? 'mobile' : ''}>
      <div className="nav_container">
        {
          title &&
          <div className="pc-title">{title}</div>
        }
        <div className="spacer"/>
        <nav>
          <div className="nav_menu-flex">
            <div className="menu_buttons">
              {
                user ?
                <div className="log-out">
                  <span onClick={onLogoutClick}>Log out</span> - {user.email}</div> :
                <WhiteButton 
                  onClick={user ? onLogoutClick: onLoginClick} 
                  className="log-in"
                > Log in
                </WhiteButton> 
              }
            </div> 
          </div>   
        </nav>
      </div>   
    </Container>
  );
}

export default NavBar;