import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import SlidingModal from "./SlidingModal";
import BlueButton from "./BlueButton";
import StyledCheckbox from "./StyledCheckbox";
import Select from 'react-select';
import { US_STATES } from '../utils';
import SkillSelector from './SkillSelector';
import SkillYOE from "./SkillYOE";
import StyledRadioGroup from "./StyledRadioGroup";
import { Tooltip } from 'react-tooltip';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  .i-sign {
    font-size: 0.8rem;
    display: block;
    position: absolute;
    top: 0.05rem;
    right: -1rem;
  }
  .with-tooltip {
    position: relative;
    display: inline-block;
  }
  h3 {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
  }
  h4 {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    margin-top: 2rem;
  }
  span.checkbox-label  {
    opacity: 1;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    color: black;
    padding: 0;
  }
  .contact-form {
    width: 100%;
    padding: 0 0 1.2rem;
    position: relative;    
    .form-item-group {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      &.YOE {
        .form-item {
          input:focus~.floating-label,
          input:not(:placeholder-shown)~.floating-label
          {
            top: 1.75rem;
            opacity: 1;
            display: block;
            font-size: 1rem;
            font-weight: 500;
            color: black;
            padding: 0;
          }
        }
      }
    }
    .error-message {
      font-size: 0.8rem;
      color: var(--color-red-1);
      margin-top: 0.5rem;
    }
    .form-item {
      flex: 1;
      position: relative;
      margin-top: 0.9rem;
      padding-top: 0.85rem;
      box-sizing: border-box;
      >label {
        box-sizing: border-box;
        overflow: hidden;
      }
      &.stack-focus {
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        margin-top: 0rem;
        >div {
          margin-top: 0rem;
          border-bottom: none;
          >label {
            opacity: 1;
            display: block;
            font-size: 1rem;
            font-weight: 500;
            color: black;
            padding: 0;
            margin-bottom: 1rem;
          }
          div.styled-radio-container {
            label {
              font-weight: 400;
              font-size: 1rem;
              margin-top: 0.25rem;
            }
          }
        }
      }

      input, textarea {
        &:active {
          padding: 0;
        }
        border: none;
        outline: none;
        display: block;
        width: 100%;
        border-bottom: 1px solid var(--color-gray-2);
        min-height: 3rem;
        padding: 0;
        font-size: 1.25rem;
        box-sizing: border-box;
        &[type="text"], &[type="number"] {
          all: unset;
          display: block;
          width: 100%;
          margin-top: 1.5rem;
          line-height: 1.75rem;
          min-height: 3rem;
          border-bottom: 1px solid var(--color-gray-2);
        }
        &::placeholder {
          color: var(--color-gray-0);
          font-size: 1.25rem;
          padding: 0;
        }
        &.error {
          border-bottom: 1px solid var(--color-red-1);
        }
      }
      span.checkbox-label  {
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 400;
        color: black;
        padding: 0;
      }
      textarea {
        margin-top: 1rem;
        height: auto; 
        resize: vertical; 
      }
      textarea[name="description"] {
        min-height: 3rem;
        resize: vertical;
      }

      .floating-label {
        position: absolute;
        pointer-events: none;
        color: var(--color-gray1);
        font-size: 1em;
        left: 0;
        bottom: 1rem;
        font-weight: normal;
        padding: 0;
        span {
          font-weight: 300;
          font-size: 0.9rem;
        }
      }
      .in-us-since-label {
        top: 0.5rem;
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 400;
        color: black;
        padding: 0;
      }
      textarea:focus~.floating-label,
      textarea:not(:placeholder-shown)~.floating-label,
      input:focus~.floating-label,
      input:not(:placeholder-shown)~.floating-label
      {
        top: 0.75rem;
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        padding: 0;
        br, span {
          display: none;
        } 
      }
      textarea:focus~.floating-label,
      textarea:not(:placeholder-shown)~.floating-label {
        top: 0rem;
      }
    }
  }
  button {
    width: 100%;
    height: 2.5rem;
    text-align: center;
    justify-content: center;
  }
  .controls {
    display: flex;
    gap: 1rem;
  }
  .location-container {
    margin-top: 4rem;
    .location-label {
      display: none;
      opacity: 1;
      font-size: 1rem;
      font-weight: 500;
      color: black;
      padding: 0;
      margin-top: -1rem;
    }
    &.filled {
      .location-label {
        display: block;   
      }
    }
    .location-type-select {
      .location__control {
        border: none !important;
        border-bottom: 1px solid var(--color-gray0) !important;
        box-shadow: none !important;
        .location__value-container {
          padding: 0;
          min-height: 3.5rem;
          .location__multi-value {
            background: white;
            border: 1px solid black;
            >div {
              background: white;
              svg {
                opacity: 0.6;
              }
            }
          }
        }
        .location__multi-value__remove:hover {
          background-color: #ccc;
          color: black;
        }
        .location__placeholder {
          color:  var(--color-gray1);
          font-size: 1rem;
          padding: 0;
        }
      }
      .location__menu {
        margin-top: -1px !important;
        padding: 0 !important;
        .location__menu-list {
          padding: 0 !important;
          .location__option {
            font-size: 0.9rem !important;
          }
        }
      }
    }
  }
  
  .in-us-since-select {
    max-width: 4rem;
    min-width: 4rem;
    .in-us-since__control {
      border: none !important;
      box-shadow: none;
      height: 3rem;
      color: black;
      font-weight: 400;
      font-family: Roboto;
      padding-left: 0;
      .in-us-since__value-container {
        padding: 2px 0;
      }
      .in-us-since__placeholder {
        color: var(--color-gray1);
        font-size: 0.9em;
      }
      .in-us-since__single-value {
        color: black; 
        font-weight: 400 !important;
        font-family: Roboto;
        font-size: 0.9em;
      }
      .in-us-since__indicators {
        .in-us-since__indicator-separator {
          display: none;
        }
        .in-us-since__dropdown-indicator {
          color: black !important;
          font-weight: 400 !important;
          font-family: Roboto;
          opacity: 0.5;
          padding: 0.5rem 0;
        }
      }
    }
    .in-us-since__menu {
      margin-top: 0 !important;
      padding: 0 !important;
      .in-us-since__menu-list {
        padding: 0 !important;
        .in-us-since__option {
          color: black;
          height: 2rem;
          font-weight: 400 !important;
          font-family: Roboto;
          display: flex;
          align-items: center;
          padding-left: 0.5rem;
          padding: 0.5rem;
          font-size: 0.9em;
          &.in-us-since__option--is-selected {
            color: white;
          }
          &.in-us-since__option--is-focused {
            
          }
        }
      }
    }
  }
  .error-red {
    color: var(--color-red-1) !important;
  }
  .error-red-message {
    color: var(--color-red-1) !important;
    margin-top: 1rem;
    font-size: 0.9em;
  }
`

const IN_US_SINCE = [
  {label: 'Any', value: 'Any'}, 
  {label: '5+', value: '5+'}, 
  {label: '10+', value: '10+'}, 
  {label: '15+', value: '15+'}
];

const STATES = US_STATES.map(item => ({
  label: item.name,
  value: item.name
}))

const RoleModal = ({ isOpen, data, onSubmit, onClose }) => {
  const selectInputRef = useRef();
  const [formData, setFormData] = useState();
  const [isUploading, setUploading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setFormData({
        client_name: { value: data?.client_name || '', error: false },
        title: { value: data?.title || '', error: false },
        stack_focus: { value: data?.stack_focus || '', error: false },
        description: { value: data?.description || '', error: false },
        is_contract: { value: data?.is_contract || false, error: false },
        pay_type: { value: data?.pay_type || '', error: false },
        pay_amount: { value: data?.pay_amount || '', error: false },
        us_states: { value: data?.us_states || [], error: false },
        ic_min_yoe: { value: data?.ic_min_yoe || '', error: false },
        ic_max_yoe: { value: data?.ic_max_yoe || '', error: false },
        manager_min_yoe: { value: data?.manager_min_yoe || '', error: false },
        manager_max_yoe: { value: data?.manager_max_yoe || '', error: false },
        usa_remote_ok: { value: data?.usa_remote_ok !== undefined ? !!data.usa_remote_ok : false, error: false },
        north_america_remote_ok: { value: data?.north_america_remote_ok !== undefined ? !!data.north_america_remote_ok : false, error: false },
        anywhere_remote_ok: { value: data?.anywhere_remote_ok !== undefined ? !!data.anywhere_remote_ok : false, error: false },
        role_type_ic: { value: data?.ic_min_yoe !== undefined || data?.ic_max_yoe !== undefined, error: false },
        role_type_manager: { value: data?.manager_min_yoe !== undefined || data?.manager_max_yoe !== undefined, error: false },
        us_bachelors: { value: data?.us_bachelors !== undefined ? !!data.us_bachelors : false, error: false },
        cs_or_stem_major: { value: data?.cs_or_stem_major !== undefined ? !!data.cs_or_stem_major : false, error: false },
        skip_bootcamp_grads: { value: data?.skip_bootcamp_grads !== undefined ? !!data.skip_bootcamp_grads : false, error: false },
        us_citizen_gc_holder: { value: data?.us_citizen_gc_holder !== undefined ? !!data.us_citizen_gc_holder : false, error: false },
        canadian_resident: { value: data?.canadian_resident !== undefined ? !!data.canadian_resident : false, error: false },
        exclude_school_experience: { value: data?.exclude_school_experience !== undefined ? data.exclude_school_experience : true, error: false },
        atleast_one: { value: data?.atleast_one || [], error: false },
        all_present: { value: data?.all_present || [], error: false },
        nice_to_haves: { value: data?.nice_to_haves || [], error: false },
        skills_and_yoe: { value: data?.skills_and_yoe || [],  error: false },
        in_us_since: { value: data?.in_us_since || 'Any', error: false },
        min_work_exp_in_us: { value: data?.min_work_exp_in_us || '', error: false },
        is_active: { value: data?.is_active === undefined ? true : data?.is_active, error: false},
        was_promoted: { value: data?.was_promoted === undefined ? false : data?.was_promoted, error: false},
        is_in_school: { value: data?.is_in_school === undefined ? false : data?.is_in_school, error: false},
        had_internship: { value: data?.had_internship === undefined ? false : data?.had_internship, error: false},
        early_stage_experience: { value: data?.early_stage_experience === undefined ? false : data?.early_stage_experience, error: false},
        "mid-late_stage_experience": { value: data?.["mid-late_stage_experience"] === undefined ? false : data?.["mid-late_stage_experience"], error: false},
        "requires_sponsorship": { value: data?.["requires_sponsorship"] === undefined ? false : data?.["requires_sponsorship"], error: false},
        big_tech: { value: data?.big_tech === undefined ? false : data?.big_tech, error: false},
        common_yoe: {error: false}
      })
    }
  }, [data, isOpen])

  const validateFormData = (data) => {
    const validatedData = {...data};
    let isValid = true;
    if (validatedData['title']?.value?.trim() === '') {
      validatedData['title'].error = 'This field cannot be empty.';
      isValid = false;
    }
    // if (validatedData['us_states']?.value.length === 0 && 
    //     validatedData['usa_remote_ok']?.value === false && 
    //     validatedData['north_america_remote_ok']?.value === false && 
    //     validatedData['anywhere_remote_ok']?.value === false
    // ) {
    //   validatedData['us_states'].error = 'One of location options must be selected.';
    //   isValid = false;
    // }
    // if (validatedData['ic_max_yoe'].value === '' && 
    // validatedData['ic_min_yoe'].value === '' &&  
    // validatedData['manager_max_yoe'].value === '' && 
    // validatedData['manager_min_yoe'].value === '') {
    //   validatedData['common_yoe'].error = true;
    //   isValid = false;
    // }
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = () => {
    setFormData({
      client_name: { value: '', error: false },
      title: { value: '', error: false },
      stack_focus: { value:'', error: false },
      description: { value: '', error: false },
      is_contract: { value: false, error: false },
      pay_type: { value: '', error: false },
      pay_amount: { value: '', error: false },
      us_states: { value: [], error: false },
      ic_min_yoe: { value: '', error: false },
      ic_max_yoe: { value: '', error: false },
      manager_min_yoe: { value: '', error: false },
      manager_max_yoe: { value: '', error: false },
      usa_remote_ok: { value: false, error: false },
      north_america_remote_ok: { value: false, error: false },
      anywhere_remote_ok: { value: false, error: false },
      role_type_ic: { value: false, error: false },
      role_type_manager: { value: false, error: false },
      us_bachelors: { value: false, error: false },
      cs_or_stem_major: { value: false, error: false },
      skip_bootcamp_grads: { value: false, error: false },
      us_citizen_gc_holder: { value: false, error: false },
      canadian_resident: { value: false, error: false },
      exclude_school_experience: { value: true, error: false },
      atleast_one: { value: [], error: false },
      all_present: { value: [], error: false },
      nice_to_haves: { value: [], error: false },
      skills_and_yoe: { value: [],  error: false },
      in_us_since: { value: 'Any', error: false },
      min_work_exp_in_us: { value: '', error: false },
      is_active: { value: false, error: false },
      was_promoted: { value: false, error: false },
      is_in_school: { value: false, error: false },
      had_internship: { value: false, error: false },
      early_stage_experience: { value: false, error: false },
      "mid-late_stage_experience": { value: false, error: false },
      "requires_sponsorship": { value: false, error: false },
      big_tech: { value: false, error: false },
      common_yoe: {error: false}
    });
  }

  const onInputChange = (e) => {
    const data = {...formData};
    const name  = e.currentTarget.name;
    const value = e.currentTarget.value;
    if (name === 'usa_remote_ok') {
      data[name].value = !data[name].value; 
      if (data[name].value) {
        data['north_america_remote_ok'].value = false;
        data['anywhere_remote_ok'].value = false;
        data['us_states'].value = []; 
        selectInputRef.current.clearValue();
        data['us_states'].error = false;
      }
    } else if (name === 'north_america_remote_ok') {
      data[name].value = !data[name].value; 
      if (data[name].value) {
        data['usa_remote_ok'].value = false;
        data['anywhere_remote_ok'].value = false;
        data['us_states'].value = []; 
        selectInputRef.current.clearValue();
        data['us_states'].error = false;
      }
    } else if (name === 'anywhere_remote_ok') {
      data[name].value = !data[name].value; 
      if (data[name].value) {
        data['usa_remote_ok'].value = false;
        data['north_america_remote_ok'].value = false;
        data['us_states'].value = []; 
        selectInputRef.current.clearValue();
        data['us_states'].error = false;
      }
    } else if (name === 'role_type_ic') {
      data[name].value = !data[name].value; 
      if (!data[name].value) {
        data['ic_max_yoe'].value = '';
        data['ic_min_yoe'].value = '';
        data['ic_max_yoe'].error = false;
        data['common_yoe'].error = false;
      }
    } else if (name === 'role_type_manager') {
      data[name].value = !data[name].value; 
      if (!data[name].value) {
        data['manager_max_yoe'].value = '';
        data['manager_min_yoe'].value = '';
        data['manager_max_yoe'].error = false;
        data['manager_min_yoe'].error = false;
        data['common_yoe'].error = false;
      }
    } else if (name === 'is_active' ||
        name === 'was_promoted' || 
        name === 'is_in_school' ||
        name === 'had_internship' ||
        name === 'us_bachelors' ||
        name === 'cs_or_stem_major' ||
        name === 'skip_bootcamp_grads' ||
        name === 'us_citizen_gc_holder' ||
        name === 'canadian_resident' ||
        name === 'early_stage_experience' ||
        name === 'mid-late_stage_experience' ||
        name === 'big_tech' || 
        name === "requires_sponsorship"
      ) {
      data[name].value = !data[name].value; 
    } else {
      data[name].value = value;
      if (name === 'title') {
        data[name].error = false;
      }
      if (name === 'ic_min_yoe' || name === 'ic_max_yoe') {
        if (!!data['ic_max_yoe'].value && 
            !!data['ic_min_yoe'].value && 
            Number(data['ic_max_yoe'].value) < Number(data['ic_min_yoe'].value)) {
              data['ic_max_yoe'].error = true;
              data['ic_min_yoe'].error = true;
              data['common_yoe'].error = false;
            } else {
              data['ic_max_yoe'].error = false;
              data['ic_min_yoe'].error = false;
              data['common_yoe'].error = false;
            }
      }
      if (name === 'manager_min_yoe' || name === 'manager_max_yoe') {
        if (!!data['manager_max_yoe'].value && 
            !!data['manager_min_yoe'].value && 
            Number(data['manager_max_yoe'].value) < Number(data['manager_min_yoe'].value)) {
              data['manager_max_yoe'].error = true;
              data['manager_min_yoe'].error = true;
              data['common_yoe'].error = false;
            } else {
              data['manager_max_yoe'].error = false;
              data['manager_min_yoe'].error = false;
              data['common_yoe'].error = false;
            }
      }
    }
    setFormData(data);
  }

  const onInUSSinceSelect = (item) => {
    const data = {...formData};
    data['in_us_since'].value = item.value;
    setFormData(data);
  }
  
  const getInUSSince = (value) => {
    return IN_US_SINCE.find(item => item.value === value) || IN_US_SINCE[0];
  }

  const onLocationChange = (values) => {
    const data = {...formData};
    data['us_states'].value = values.map(item => item.value);
    data['us_states'].error = false;
    setFormData(data);
  }

  const onSkillSetChange = (key, value) => {
    const data = {...formData};
    data[key].value = value;
    setFormData(data);
  }

  const onSkillYOEChange = (value) => {
    const data = {...formData};
    data["skills_and_yoe"].value = value;
    setFormData(data);
  }

  const getLocation = (values) => {
    return STATES.filter(item => values.includes(item.value));
  }

  const onSubmitClick = async () => {
    if (validateFormData(formData)) {
      //setUploading(true);
      const data = {};
      for (let key in formData) {
        data[key] = formData[key]?.value;
      }
      await onSubmit(data);
      clearFormData();
      //setUploading(false);  
    } else {
      setTimeout(() => {
        const errorInput = document.querySelector('div.error-red-message')
        if (errorInput) {
          errorInput.scrollIntoView();
        }
      }, 50)
    }
  }

  const onModalClose = () => {
    onClose();
  }

  const isLocationEmpty = !formData?.us_states?.value?.length;
  const isSubmitButtonDisabled = formData && Object.keys(formData).find(key => !!formData[key].error);


  return (
    <>
    <SlidingModal 
      onClose={onModalClose} 
      isOpen={isOpen}
      loading={isUploading}
      modalStyle={{
        width: '40rem',
        minWidth: '40rem',
        padding:'2rem 4.8rem 2rem',
        boxSizing: 'border-box'
      }}>
      <Container>
        <h3>Requirements</h3>
        <form className="contact-form">
            {/* <div className="form-item">
              <input 
                placeholder="   "
                name="title"
                type="text"
                onChange={onInputChange}
                value={formData?.title?.value || ''}
                className={formData?.title?.error ? 'error' : ''}
              />
            <label className={formData?.title?.error ? 'floating-label error-red' : 'floating-label'}>Title <span>(we currently only support software engineering roles)</span></label>
            </div> */}
            <h4>Stack Focus</h4>
            <div className="form-item stack-focus">
              <StyledRadioGroup 
                name="stack_focus"
                disabledOptions={["ml_eng"]}
                options={[
                  {name: "Frontend", value: "frontend"},
                  {name: "Backend", value: "backend"},
                  {name: "Fullstack", value: "fullstack"},
                  {name: "DevOps/SRE", value: "devops"},
                  {name: "Machine Learning", value: "ml_eng"}
                ]}
                onChange={onInputChange}
                value={formData?.stack_focus?.value || ''}
              />
            </div>
            {/* {
              formData?.title?.error &&
              <div className="error-message">{formData?.title?.error}</div>
            } */}
          {/* <div className="form-item" style={{marginTop: '1rem'}}>
            <textarea 
              placeholder=" "
              name="description"
              type="text"
              onChange={onInputChange}
              value={formData?.description.value || ''}
              className={formData?.description?.error ? 'error' : ''}
            />
            <label className="floating-label">Description</label>
          </div> */}
          {/* <h4 style={{marginBottom: '0.5rem'}} className={formData?.us_states?.error ? 'error-red' : ''}>Location</h4>
          <div className="form-item" style={{marginTop: '0', marginBottom: '0rem'}}>
            <StyledCheckbox 
              style={{marginTop: '0rem', marginBottom: '0rem'}}
              name="usa_remote_ok"
              onChange={onInputChange}
              checked={formData?.usa_remote_ok.value}>
              USA Remote Ok
            </StyledCheckbox>
          </div>
          <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
            <StyledCheckbox 
              style={{marginTop: '0rem', marginBottom: '0rem'}}
              name="north_america_remote_ok"
              onChange={onInputChange}
              checked={formData?.north_america_remote_ok.value}>
              North America Remote Ok
            </StyledCheckbox>
          </div>
          <div className="form-item" style={{marginTop: '0rem', marginBottom: '-0.5rem'}}>
            <StyledCheckbox 
              style={{marginTop: '0rem', marginBottom: '0rem'}}
              name="anywhere_remote_ok"
              onChange={onInputChange}
              checked={formData?.anywhere_remote_ok.value}>
              Anywhere Remote Ok
            </StyledCheckbox>
          </div>
          <div className={`form-item location-container ${isLocationEmpty ? 'empty' : 'filled'}`}>
            <Select
              className="location-type-select"
              classNamePrefix="location" 
              closeMenuOnSelect={false}
              placeholder="US State" 
              ref={selectInputRef}
              isValidNewOption={()=>true}
              isDisabled={formData?.anywhere_remote_ok.value || formData?.north_america_remote_ok.value || formData?.usa_remote_ok.value}
              // components={animatedComponents}
              onChange={onLocationChange}
              defaultValue={getLocation(formData?.us_states?.value || [])}
              isMulti
              options={STATES}
            />
          </div> */}
          {/* {
            formData?.us_states?.error &&
            <div className="error-message">{formData?.us_states?.error}</div>
          } */}

          <h4 style={{marginBottom: '0.5rem'}} className={formData?.common_yoe?.error ? 'error-red' : ''}>Years of Experience</h4>
          <div className="form-item-group YOE" style={{alignItems: 'center'}}>
              <div className="form-item" style={{marginTop: '0rem'}}>
                <StyledCheckbox 
                  style={{marginTop: '0rem', marginBottom: '0rem'}}
                  name="role_type_ic"
                  onChange={onInputChange}
                  checked={formData?.role_type_ic.value}>
                  Individual Contributor
                </StyledCheckbox>
              </div>
              {
                formData?.role_type_ic.value &&
                <>
                  <div className="form-item" style={{marginTop: '-1rem', maxWidth: '6rem'}}>
                    <input 
                      placeholder=" "
                      name="ic_min_yoe"
                      type="number"
                      min={0}
                      onChange={onInputChange}
                      value={formData?.ic_min_yoe.value || ''}
                      className={formData?.ic_min_yoe.error ? 'error' : ''}
                    />
                    <label className="floating-label">Min YOE</label>
                  </div>
                  <div className="form-item" style={{marginTop: '-1rem', maxWidth: '6rem'}}>
                    <input 
                      placeholder=" "
                      name="ic_max_yoe"
                      type="number"
                      min={0}
                      onChange={onInputChange}
                      value={formData?.ic_max_yoe.value || ''}
                      className={formData?.ic_max_yoe.error ? 'error' : ''}
                    />
                    <label className="floating-label">Max YOE</label>
                  </div>
                </>
              }
                  
            </div>
            <div className="form-item-group YOE" style={{alignItems: 'center'}}>
              <div className="form-item" style={{marginTop: '0rem'}}>
                <StyledCheckbox 
                  style={{marginTop: '0rem', marginBottom: '0rem'}}
                  name="role_type_manager"
                  onChange={onInputChange}
                  checked={formData?.role_type_manager.value}>
                  Manager
                </StyledCheckbox>
              </div>
              {
                formData?.role_type_manager.value &&
                <>
                  <div className="form-item" style={{marginTop: '-1rem', maxWidth: '6rem'}}>
                    <input 
                      placeholder=" "
                      name="manager_min_yoe"
                      type="number"
                      min={0}
                      onChange={onInputChange}
                      value={formData?.manager_min_yoe.value || ''}
                      className={formData?.manager_min_yoe.error ? 'error' : ''}
                    />
                    <label className="floating-label">Min YOE</label>
                  </div>
                  <div className="form-item" style={{marginTop: '-1rem', maxWidth: '6rem'}}>
                    <input 
                      placeholder=" "
                      name="manager_max_yoe"
                      type="number"
                      min={0}
                      onChange={onInputChange}
                      value={formData?.manager_max_yoe.value || ''}
                      className={formData?.manager_max_yoe.error ? 'error' : ''}
                    />
                    <label className="floating-label">Max YOE</label>
                  </div>
                </>
              }  
            </div>
          <div className="form-item-group">
            <div>
              <h4 style={{marginBottom: '0.5rem'}}>Education</h4>
              <div className="form-item" style={{marginTop: '0', marginBottom: '0rem'}}>
                <StyledCheckbox 
                  style={{marginTop: '0rem', marginBottom: '0rem'}}
                  name="us_bachelors"
                  onChange={onInputChange}
                  checked={formData?.us_bachelors.value}>
                  Include: US Bachelors
                </StyledCheckbox>
              </div>
              <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
                <StyledCheckbox 
                  style={{marginTop: '0rem', marginBottom: '0rem'}}
                  name="cs_or_stem_major"
                  onChange={onInputChange}
                  checked={formData?.cs_or_stem_major.value}>
                  Include: Computer Science/STEM Major
                </StyledCheckbox>
              </div>
              <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
                <StyledCheckbox 
                  style={{marginTop: '0rem', marginBottom: '0rem'}}
                  name="skip_bootcamp_grads"
                  onChange={onInputChange}
                  checked={formData?.skip_bootcamp_grads.value}>
                  Exclude: Bootcamp Grads
                </StyledCheckbox>
              </div>
            </div>
          </div>
          <h4 style={{marginBottom: '-0.5rem'}}>Skills</h4>
          {/* <div className="form-item">
            <SkillSelector
              placeholder="Should have one or more of"
              value={formData?.atleast_one.value || ''}
              onChange={(value) => onSkillSetChange('atleast_one', value)}
            />
          </div> */}
          <div className="form-item">
            <SkillSelector
              placeholder="Required skills"
              value={formData?.all_present.value || ''}
              onChange={(value) => onSkillSetChange('all_present', value)}
            />
          </div>
          <div className="form-item">
            <SkillSelector
              placeholder="Preferred skills"
              value={formData?.nice_to_haves.value || ''}
              onChange={(value) => onSkillSetChange('nice_to_haves', value)}
            />
          </div>
          {/* <h4 style={{marginBottom: '-0.5rem'}}>Skills YOE</h4>
          <div className="form-item">
            <SkillYOE value={formData?.skills_and_yoe.value} onChange={onSkillYOEChange}/>
          </div> */}
          <h4 style={{marginBottom: '0.5rem'}}>Past Experience</h4>
          <div 
            className="form-item" 
            style={{marginTop: '0rem', marginBottom: '0rem'}}
            >
            <div 
              className="with-tooltip"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Pre-seed, seed and series A"
              data-tooltip-place="top"
            >
              <StyledCheckbox 
                style={{marginTop: '0rem', marginBottom: '0rem'}}
                name="early_stage_experience"
                onChange={onInputChange}
                checked={formData?.early_stage_experience.value}>
                    Early stage startup experience <span className="i-sign">&#9432;</span>            
              </StyledCheckbox>
            </div>
          </div>
          <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
            <div 
              className="with-tooltip"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Series B and after"
              data-tooltip-place="top"
            >
              <StyledCheckbox 
                style={{marginTop: '0rem', marginBottom: '0rem'}}
                name="mid-late_stage_experience"
                onChange={onInputChange}
                checked={formData?.["mid-late_stage_experience"].value}>
                  Mid/Late stage startup experience <span className="i-sign">&#9432;</span>
              </StyledCheckbox>
            </div>
          </div>
          <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
            <div 
              className="with-tooltip"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Google, Amazon, Uber, etc"
              data-tooltip-place="top"
            >
              <StyledCheckbox 
                style={{marginTop: '0rem', marginBottom: '0rem'}}
                name="big_tech"
                onChange={onInputChange}
                checked={formData?.big_tech.value}>
                Big Tech <span className="i-sign">&#9432;</span>
              </StyledCheckbox>
            </div>
          </div>
          <div className="form-item-group">
            <div>
              <h4>Immigration</h4>
              {/* <div className="form-item" style={{minWidth: '10rem', marginTop: '0.3rem'}}>
                <label className="in-us-since-label">If an immigrant, how many years have they been in US?</label>
                <Select
                    className="in-us-since-select"
                    classNamePrefix="in-us-since" 
                    placeholder="Any" 
                    options={IN_US_SINCE}
                    defaultValue={getInUSSince(formData?.in_us_since.value)}
                    onChange={onInUSSinceSelect}
                    isSearchable={false}
                  />
              </div> */}
              <div className="form-item" style={{marginTop: '0.5rem', marginBottom: '0rem'}}>
                <StyledCheckbox 
                  style={{marginTop: '0rem', marginBottom: '0rem'}}
                  name="us_citizen_gc_holder"
                  onChange={onInputChange}
                  checked={formData?.us_citizen_gc_holder.value}>
                  US Citizen/GC Holder
                </StyledCheckbox>
              </div>
              <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
                <StyledCheckbox 
                  style={{marginTop: '0rem', marginBottom: '0rem'}}
                  name="canadian_resident"
                  onChange={onInputChange}
                  checked={formData?.canadian_resident.value}>
                  Canadian resident
                </StyledCheckbox>
              </div>
              <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
                <StyledCheckbox 
                  style={{marginTop: '0rem', marginBottom: '0rem'}}
                  name="requires_sponsorship"
                  onChange={onInputChange}
                  checked={formData?.["requires_sponsorship"].value}>
                  Requires Sponsorship
                </StyledCheckbox>
              </div>
            </div>
          </div>
          <div className="form-item-group">
            <div>
              <h4>Other</h4>
              <div className="form-item" style={{marginTop: '0.5rem', marginBottom: '0rem'}}>
                <StyledCheckbox 
                  style={{marginTop: '0rem', marginBottom: '0rem'}}
                  name="was_promoted"
                  onChange={onInputChange}
                  checked={formData?.was_promoted.value}>
                  Was promoted
                </StyledCheckbox>
              </div>
              {/* <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
                <StyledCheckbox 
                  style={{marginTop: '0rem', marginBottom: '0rem'}}
                  name="is_in_school"
                  onChange={onInputChange}
                  checked={formData?.is_in_school.value}>
                  Is in school
                </StyledCheckbox>
              </div>
              <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
                <StyledCheckbox 
                  style={{marginTop: '0rem', marginBottom: '0rem'}}
                  name="had_internship"
                  onChange={onInputChange}
                  checked={formData?.["had_internship"].value}>
                  Had internship(s)
                </StyledCheckbox>
              </div> */}
            </div>
          </div>
          {/* <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <StyledCheckbox 
              name="is_active"
              style={{marginTop: '1.5rem', marginBottom: '0rem'}}
              onChange={onInputChange}
              checked={formData?.is_active.value}>
              Actively Hiring
            </StyledCheckbox>
          </div>         */}
        </form>
        <BlueButton style={{marginTop: '1rem'}} onClick={onSubmitClick} disabled={isSubmitButtonDisabled}>Apply Filters</BlueButton>
        {
          isSubmitButtonDisabled &&
          <div className='error-red-message'>Please fill the mandatory fields above.</div>
        }
      </Container>  
      <Tooltip id="my-tooltip" />
    </SlidingModal>
    
    </>
  );
}

export default RoleModal;