import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import SkillYOESelector from "./SkillYOESelector";
import { FaPlus } from "react-icons/fa6";

const Container = styled.div`
  > svg {
    color: hsl(0, 0%, 60%);
    margin-top: 1rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
`

const SkillYOE = ({ 
  value,
  onChange }) => {
  const [newSelector, showNewSelector] = useState(false);

  const onOldSelectorChange = (item, i) => {
    const newValue = [...value];
    newValue[i] = item;
    onChange(newValue);
  }

  const onNewSelectorChange = (item) => {
    console.log(item);
    onChange([...value, item]);
    showNewSelector(false);
  }

  const onDelete = (i) => {
    console.log(i);
    const newValue = [...value];
    newValue.splice(i, 1);
    onChange(newValue);
  }

  const forbiddenSkills = value.map(item => item.skill_name);

  return (
    <Container>
      {
        value.map((item, i) => (
          <SkillYOESelector
            key={item.skill_name} 
            skillValue={item.skill_name}
            yoeValue={item.yoe}
            forbiddenSkills={forbiddenSkills}
            onChange={(value) => onOldSelectorChange(value, i)}
            onDelete={() => onDelete(i)}
          />         
        )) 
      }
      {
        (value.length === 0 || newSelector) && 
        <SkillYOESelector
          forbiddenSkills={forbiddenSkills}
          onChange={onNewSelectorChange}
        />
      }
      {
        (value.length > 0 && !newSelector) &&
        <FaPlus onClick={() => showNewSelector(true)}/>
      }
    </Container>
  )
}

export default SkillYOE;