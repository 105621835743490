import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import styled from 'styled-components';
import { createPaymentIntent, addCredit } from "../services";
import { p2r } from "../utils";
import StyledModal from "./StyledModal";
import StyledRadioGroup from "./StyledRadioGroup";
import CheckoutForm from "./CheckoutForm";
import BlackButton from "./BlackButton";
import WhiteButton from "./WhiteButton";
import Spinner from "./Spinner";
import Select from 'react-select';


var mobile = require('is-mobile');
const IS_MOBILE = mobile();

const Container = styled.div`
  font-family: -apple-system, Roboto, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  padding:1rem;
  .error-message-common {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }

  .select-wrapper {
    height: 14rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    h3 {
      margin: 0;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 2;
      width: 100%;
      margin-bottom: ${p2r(14)};
    }
    .select-label {
      font-size: 1rem;
      font-weight: 500;
      color: #6D6E6F;
      line-height: 1.5;
    }
    
    .select-buttons {
      display: flex;
      gap: 1rem;
      width: 100%;
      justify-content: flex-end;
      margin-top: ${p2r(38)};
      button {
        width: auto;
        padding: 0.625rem 1,25rem;
      }
    }
    .add-credit-amount-select {
      width: 100%;
      min-width: 25rem;
      margin-top: 0.5rem;
      .add-credit-amount__control {
        border: 1px solid #9A9999;
        box-shadow: none;
        height: 3rem;
        color: black;
        font-weight: 400;
        font-family: Roboto;
        padding-left: 0;
        padding: 0 0.5rem;
        .add-credit-amount__value-container {
          padding: 2px 0;
        }
        .add-credit-amount__placeholder {
          color: var(--color-gray1);
          font-size: 0.9em;
        }
        .add-credit-amount__single-value {
          color: black; 
          font-weight: 400 !important;
          font-family: Roboto;
          font-size: 0.9em;
        }
        .add-credit-amount__indicators {
          .add-credit-amount__indicator-separator {
            display: none;
          }
          .add-credit-amount__dropdown-indicator {
            color: black !important;
            font-weight: 400 !important;
            font-family: Roboto;
            opacity: 0.5;
            padding: 0.5rem 0;
          }
        }
      }
      .add-credit-amount__menu {
        margin-top: 0 !important;
        padding: 0 !important;
        .add-credit-amount__menu-list {
          padding: 0 !important;
          .add-credit-amount__option {
            color: black;
            height: 2rem;
            font-weight: 400 !important;
            font-family: Roboto;
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
            padding: 0.5rem;
            font-size: 0.9em;
            &.add-credit-amount__option--is-selected {
              color: white;
            }
            &.add-credit-amount__option--is-focused {
              
            }
          }
        }
      }
    }
  }

  .payment-wrapper {
    min-height: 280px;
    width: 100%;
    form {
      width: 100%;
      min-width: 100%;
      align-self: center;
    }
    #payment-message {
      color: rgb(105, 115, 134);
      font-size: 16px;
      line-height: 20px;
      padding-top: 12px;
      text-align: center;
    }

    #payment-element {
      margin-bottom: 24px;
      min-height: 220px !important;
    }

    .control-buttons {
      display: flex;
      gap: 1rem;
      width: 100%;
      justify-content: flex-end;
      margin-top: ${p2r(38)};
      button {
        width: auto;
        padding: 0.625rem 1,25rem;
      }
    }
        /* Buttons and links */
    button#submit {
      all: unset;
      min-width: 95px;
      box-sizing: border-box;
      border: 1px solid var(--light-black12);
      background-color: var(--light-black12);
      color: #fff;
      text-align: center;
      border-radius: 3px;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem;
      font-weight: 500;
      line-height: 1.5;
      font-size: 1rem;
      text-decoration: none;
      font-family: Roboto;
      transition: transform .35s ease-in-out,box-shadow .35s ease-in-out;
      display: flex;
      cursor: pointer;
    }

    button#submit:hover {
      filter: contrast(115%);
      /* box-shadow: 6px 10px 16px 0 var(--drop-shadow); */
    }

    button#submit:disabled {
      opacity: 0.8;
      cursor: default;
    }
    @media only screen and (max-width: 600px) {
      form {
        width: 80vw;
        min-width: initial;
      }
    }
  }
`

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51Pg1Ki2KIZqC7byq1nzEfIoyUWMOn1u7oZlVb8jFaDzYY8JxvRDbvFfIZQ8bRqcpHmzaGgP62xlooMGCrC7RdlQR00XSoPniME");

const MONEY_AMOUNTS = [
  {
    label: '$25',
    value: '25'
  },
  {
    label: '$50',
    value: '50'
  },
  {
    label: '$100',
    value: '100'
  }
]

export default function PaymentsModal({ isOpen, data, onSubmit, onClose, updateBalance }) {
  const [clientSecret, setClientSecret] = useState("");
  const [customerSessionClientSecret, setCustomerSessionClientSecret] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [loadingLabel, setloadingLabel] = useState("Loading...");
  const [amount, setAmount] = useState(MONEY_AMOUNTS[0].value);
  const [errorMessage, setErrorMessage] = useState("");

  const clearAll = () => {
    setClientSecret("");
    setCustomerSessionClientSecret("");
    setErrorMessage("");
    setAmount(MONEY_AMOUNTS[0].value);
  }

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (!isOpen) {
      clearAll();
    }
  }, [isOpen]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    customerSessionClientSecret,
    appearance,
  };

  const onModalClose = () => {
    clearAll();
    onClose();
  }

  const onAmountSelect = (item) => {
    setAmount(item.value);
  }
  const onGoToCheckoutClick = async () => {
    setLoading(true);
    try {
      const data = await createPaymentIntent(amount);
      setClientSecret(data.client_secret);
      setCustomerSessionClientSecret(data.customer_session_client_secret);
    } catch (err) {
      console.log(err);
      clearAll();
    } finally {
      setLoading(false);
    }
  }

  const onSucceed = async (payment_id) => {
    setLoading(true);
    setloadingLabel("Updating credit balance...");
    clearAll();
    try {
      const data = await addCredit(payment_id);
      updateBalance(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      onClose();
    }
  }

  const onError = async (message) => {
    clearAll();
    setErrorMessage(message);
  }

  const render = () => {
    if (isLoading) return <div className="select-wrapper">
        <Spinner size="2rem"/>
    </div>;
    if (errorMessage) return <div className="select-wrapper">
        <div className="error-message-common">{errorMessage}</div>
        <BlackButton onClick={onModalClose}>Close</BlackButton>
    </div>;
    if (clientSecret) return (
      <div className="payment-wrapper">
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm 
            onSucceed={onSucceed} 
            onError={onError}
            onCancel={onModalClose}
          />
        </Elements>
      </div>
    );
    return <div className="select-wrapper">
      <h3>Add to Credit Balance</h3>
      <div className="select-container">
        <label className="select-label">
          Select amount to add
        </label>
        <Select
          className="add-credit-amount-select"
          classNamePrefix="add-credit-amount" 
          placeholder="Select amount" 
          options={MONEY_AMOUNTS}
          defaultValue={MONEY_AMOUNTS[0]}
          onChange={onAmountSelect}
          isSearchable={false}
        />
      </div>
      <div className="select-buttons">
        <WhiteButton onClick={onClose}>Cancel</WhiteButton>
        <BlackButton onClick={onGoToCheckoutClick}>Continue</BlackButton>
      </div>
    </div>
  }

  return (
    <StyledModal 
      onClose={onModalClose} 
      isOpen={isOpen}
      loading={isLoading}
      loadingLabel={loadingLabel}
      hideCrossButton={true}
      modalStyle={{
        maxWidth: IS_MOBILE ? '90%' : 'auto',
        borderRadius: p2r(12),
        width: '28rem',
        minWidth:  IS_MOBILE ? 'auto' : '28rem',
        padding:'0.5rem 0.5rem 1rem',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
      <Container className={IS_MOBILE ? "mobile" : ""}>
        { render() }
      </Container>
    </StyledModal>
  );
}