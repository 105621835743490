import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { getAllRoles, deleteRole, updateRole } from "../services";
import { useAuth } from '../hooks';
import Spinner from "../components/Spinner";
import StyledModal from "../components/StyledModal";
import BlackButton from "../components/BlackButton"; 
import WhiteButton from "../components/WhiteButton";
import PageContainer from "../components/PageContainer";
import { useAtom } from 'jotai';
import { rolesAtom } from "../atoms";
import { p2r } from "../utils";


const Container = styled.div`
    margin-top: 7rem;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    box-sizing: border-box;
    align-self: flex-start;
    max-width: 60rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    .content {
      flex: 1;
      position: relative;
      .role-item {
        padding: 0 1rem;
        height: 3rem;
        cursor: pointer;
        width: 40rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover {
          background-color: var(--color-grey-00);
        }
        .role-item-controls {
          display: flex;
          align-items: center;
          justify-content: center;
          gap:0.5rem;
          label {
            font-size: 0.8rem;
          }
          button {
            padding: 0.5rem;
            line-height: 1;
            font-size: 0.8rem;
          }
        }
      }
    }
    .no-roles {
      font-size: 0.9rem;
      margin-top: -0.2rem;
      max-width: 39rem;
      font-family: Roboto;
      font-weight: 400;
      text-align: left;
    }
    .filter-container {
      width: 14rem;
      margin-bottom: 2rem;
    }
    .spacer {
      flex: 1;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
    }
    .spinner {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      left: 15rem;
      justify-content: center;
      display: flex;
      align-items: center;
    }


`

const ModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  .form-item {
      flex: 1;
      position: relative;
      margin-top: 0;
      width: 100%;
      padding-top: 0.85rem;
      .error-message {
        position: absolute;
        bottom: -1.2rem;
        font-size: 0.8em;
        left: 0;
        color: var(--color-red0);
        font-weight: 400;
      }
      input:-webkit-autofill {
        font-size: 1.25rem;
        padding: 0;
        line-height: 1.75rem;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
      }
      input, textarea {
        border: none;
        outline: none;
        all: unset;
        display: block;
        width: 100%;
        border-bottom: 1px solid var(--color-gray-2);
        height: 3rem;
        padding: 0;
        margin-top: 1.5rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        box-sizing: border-box;
        &::placeholder {
          color: var(--color-gray-0);
          font-size: 1.25rem;
          padding: 0;
        }
        &.error {
          border-bottom: 1px solid var(--color-red-1);
        }
      }
      textarea {
        margin-top: 1rem;
        height: auto; 
        resize: vertical; 
      }
      textarea[name="description"] {
        min-height: 3rem;
      }

      .floating-label {
        position: absolute;
        pointer-events: none;
        color: var(--color-gray1);
        font-size: 1.25em;
        left: 0;
        bottom: 1.5rem;
        font-weight: normal;
        padding: 0;
      }

      textarea:focus~.floating-label,
      textarea:not(:placeholder-shown)~.floating-label,
      input:focus~.floating-label,
      input:not(:placeholder-shown)~.floating-label,
      input:-webkit-autofill~.floating-label
      {
        top: 0.5rem;
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        padding: 0;
      }
    }
  .select-buttons {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: flex-end;
    margin-top: 1.5rem;
    button {
      width: auto;
      padding: 0rem 1rem;
      height: 2rem;
    }
  }
`

const STATE = {
  role_name: { value: '', error: false }
};

const AllRoles = () => {
  const [ roles, setRoles ] = useAtom(rolesAtom);
  const [ formData, setFormData ] = useState(structuredClone(STATE));
  const [ loading, setLoading ] = useState(true);
  const [ roleToDeactivate, setRoleToDeactivate ] = useState(null);
  const [ roleToEdit, setRoleToEdit ] = useState(null);
  const isLoading = useRef(false);
  useAuth();


  useEffect(() => {
    if (roleToEdit) {
      setFormData(structuredClone(STATE));
    }
  }, [roleToEdit])

  const fetchRoles = async () => {
    setLoading(true);
    isLoading.current = true;
    try {
      const data = await getAllRoles();
      if (data) {
        setRoles(data);
      }
    } catch(err) {
      setRoles([])
      // TODO this should notify the user that a problem occurred
    } finally {
      setLoading(false);
      isLoading.current = false;
    }
  }

  useEffect(() => {
    if (!isLoading.current) {
      fetchRoles();
    }
  }, [])

  const onDeactivateClick = (item) => {
    console.log(item);
    setRoleToDeactivate(item);
  }

  const onEditClick = (item) => {
    console.log(item);
    setRoleToEdit(item);
  }

  const closeDeactivate = () => {
    setRoleToDeactivate(null);
  }

  const deactivateRole = async () => {
    setRoleToDeactivate(null);
    setLoading(true);
    await deleteRole(roleToDeactivate.id);
    await fetchRoles();
  }

  const closeEdit = () => {
    setRoleToEdit(null);
  }

  const editRole = async () => {
    if (validateFormData(formData)) {
      clearFormData();
      setRoleToEdit(null);
      setLoading(true);
      await updateRole(roleToEdit.id, formData.role_name.value);
      await fetchRoles();
    }  
  }

  const clearFormData = () => {
    setFormData({
      role_name: { value: '', error: false }
    });
  }

  const validateFormData = (data) => {
    const validatedData = structuredClone(formData);
    let isValid = true;
    const roleNames = roles.map(item => item.title);
    const name = validatedData['role_name'].value.trim();
    if(name === '') {
      validatedData['role_name'].error = 'The name cannot be empty';
      isValid = false;
    } else if (roleNames.includes(name)) {
      validatedData['role_name'].error = 'Role by this name exists already';
      isValid = false;
    } else {
      validatedData['role_name'].error = false;
    }
    setFormData(validatedData);
    return isValid;
  } 

  const onInputChange = (e) => {
    const data = {...formData};
    data[e.currentTarget.name].value = e.currentTarget.value;
    data[e.currentTarget.name].error = false;
    setFormData(data);
  }

  const renderContent = () => {
    if (loading) return (
      <div className="content">
        <div className="spinner">
          <Spinner/>
        </div>
      </div>
    );
    return  <div className="content">
      {
        roles.map(item => (
          <div key={item.id} className="role-item">
            {item.title}
            <div className="role-item-controls">
              {
                item.is_active ?
                <WhiteButton onClick={() => onDeactivateClick(item)}>Deactivate</WhiteButton> :
                <label>Deactivated</label>
              }
              {
                item.is_active &&
                <WhiteButton onClick={() => onEditClick(item)}>Edit</WhiteButton>
              }
              
            </div>
          </div>
        ))
      }
    </div>
  }

  return (
    <>
    <PageContainer title={'Roles'} selected={'roles'}>
      <Container>
      { renderContent() }
      </Container>
    </PageContainer>
    <StyledModal 
      onClose={closeDeactivate} 
      isOpen={!!roleToDeactivate}
      hideCrossButton={true}
      modalStyle={{
        maxWidth: 'auto',
        borderRadius: '0.25rem',
        width: '25rem',
        minWidth:  '25rem',
        padding:'1.5rem 1.5rem 2rem',
        top: '40%',
        transform: 'translate(-50%, -50%)'

      }}>
        <ModalContainer>
          Are you sure you want to deactivate this role?<br/>
          You won’t be able to upload resumes for it after.
          <div className="select-buttons">
            <BlackButton onClick={deactivateRole}>Yes</BlackButton>
            <WhiteButton onClick={closeDeactivate}>No</WhiteButton>
          </div>  
        </ModalContainer>
      </StyledModal>
      <StyledModal 
        onClose={closeEdit} 
        isOpen={!!roleToEdit}
        hideCrossButton={true}
        modalStyle={{
          maxWidth: 'auto',
          borderRadius: '0.25rem',
          width: '25rem',
          minWidth:  '25rem',
          padding:'1.5rem 1.5rem 2rem',
          top: '40%',
          transform: 'translate(-50%, -50%)'

        }}>
          <ModalContainer>
            <div className="form-item">
              <input 
                placeholder="   "
                autoFocus
                name="role_name"
                type="text"
                onChange={onInputChange}
                defaultValue={roleToEdit?.title}
                className={formData?.role_name?.error ? 'error' : ''}
              />
              <label className="floating-label">Name</label>
              {
                formData?.role_name?.error &&
                <div className="error-message">{formData?.role_name?.error}</div>
              } 
            </div>
            <div className="select-buttons" style={{marginTop: '2rem'}}>
              <BlackButton onClick={editRole}>Submit</BlackButton>
              <WhiteButton onClick={closeEdit}>Cancel</WhiteButton>
            </div>  
          </ModalContainer>
      </StyledModal>
    </>

  );
};

export default AllRoles;