import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import NavBar from "../components/NavBar";
import WhiteButton from "../components/WhiteButton";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import { generateAPIKey, getAPIKey } from "../services";
import { useAuth } from "../hooks";
import PlainNavMenu from "../components/PlainNavMenu";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  text-align: left;
  .content-area {
    width: 100%;
    max-width: 80rem;
    padding: 0 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    .plain-nav-menu {
      #integrations {
        font-weight: 600;
      }
    }
    .content {
      flex: 1;
      height: 100%;
    }
    .button-container {
      display: flex;
    }
    .slelected-file {
      margin-top: 1rem;
    }
    .separator {
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--color-gray2);
    }
    .generation-in-progress {
      height: 3.5rem;
      width: 23rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      margin-left: 1rem;
      p {
        margin-left: .5rem;
      }
    }
    .parsed-data {
      margin-top: 2rem;
      font-size: 1.1rem;
    }
  }
`

const Integrations = () => {
  useAuth();

  const [isGenerating, setIsGenerating] = useState(false);
  const [currentKey, setCurrentKey] = useState("");


  useEffect(() => {
    const getCurrentKey = async () => {
      try {
        const key = await getAPIKey();
        setCurrentKey(key);
      } catch(err) {
        if (err.response && err.response.status_code === 401) {
          window.location.href='/';
        } else {
          // TODO this should notify the user that a problem occurred
        }
      }
    } 
    getCurrentKey();
  }, [])

  const onSubmit = async () => {
    setIsGenerating(true);
    try {
      const key = await generateAPIKey();
      setCurrentKey(key);
      setIsGenerating(false);
    } catch(err) {
      window.location.href = '/';
    }
  }

  return (
    <>
    <Container className="App">
      <NavBar/>
      <div className="content-area">
        <PlainNavMenu/>
        <div className="content">
          <h3>API Keys</h3>
          <p>Generate an API Key. This key will give you access to the TopNerd API.</p>
          <p>Ensure this key is kept secret and avoid adding it to git.</p>
          <h5>{`Current Key: ${currentKey}`}</h5>
          <div className="button-container">
              <WhiteButton 
                style={{
                  padding: '0 2rem', 
                  height: '3.5rem'
                }}
                disabled={isGenerating}
                onClick={onSubmit}
              >Generate New Key</WhiteButton>
              {
                isGenerating && 
                <div className="generation-in-progress">
                  <Spinner/>
                  <p>Generating...</p>
                </div>
              }
          </div>
          <br/><br/>
          <div className="separator"/>
        </div>
        
      </div>  
    </Container>
    <Footer/>
    </>
  );
};

export default Integrations;