import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import WhiteButton from "../components/WhiteButton";
import Spinner from "../components/Spinner";
import { resumeSubmitContent, getNextResumeToBuild } from "../services";
import PageContainer from "../components/PageContainer";
import ExperienceForm from "../components/ExperienceForm";
import EducationForm from "../components/EducationForm";
import PersonalInfoForm from "../components/PersonalInfoForm";
import { useAuth } from '../hooks';
import { MdClose } from 'react-icons/md';
import StyledModal from "../components/StyledModal";
import BlackButton from  "../components/BlackButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  text-align: left;
  .content-area {
    margin-top: 5rem;
    width: 100%;
    height: 100%;
    max-width: 72.5rem;
    padding: 0 2.5rem;
    box-sizing: border-box;
    flex: 1;
    font-weight: 400;
    font-family: Roboto;

    div.form-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      .error-message {
        color: var(--color-red-1) !important;
        font-size: 0.9em;
        align-self: flex-start;
        margin-top: -0.5rem;
      }
      input.simple-input {
        height: 2.2rem;
        width: 100%;
        padding: 0 0 0 0.5rem;
        border: 1px solid var(--color-gray0);
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        box-sizing: border-box;
        &::placeholder {
          color: var(--color-gray1);
        }
      }
    }



    .skills-textarea {
      width: 100%;
      max-width: 45rem;
      padding: 0.5rem;
      box-sizing: border-box;
      border: 1px solid var(--color-gray0);
      height: 6rem;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      margin-top: 1rem;
      &::placeholder {
        color: var(--color-gray1);
        font-family: "Roboto", sans-serif;
        font-weight: 400;
      }
    }
    .filename a {
      color: black !important;
    }
    .separator {
      background-color: var(--light-greye4);
      width: 100%;
      height: 1px;
    }
    h2 {
      font-size: 1.7rem;
      font-weight: 500;
    }
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      margin-top: 2rem;
      font-weight: 500;
    }
    .clipboard-copy {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
      margin-bottom: -0.1rem;
      cursor: pointer;
      &:active {
        opacity: 0.5;
      }
    }
    .pill-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    .qa-feedback {
      border-top: 1px solid var(--light-greye4);
      margin: 3rem 0;
      .qa-feedback-wrapper {
        display: flex;
        gap: 1rem;
        margin: 1.5rem 0;
        span.checkbox-label {
          font-size: 1rem !important;
          font-weight: 400 !important;
        }
      }
      h4 {
        margin: 0 0 0.5rem;
        font-weight: 500;
      }
      textarea {
        margin: 0 0 1.5rem;
        min-width: 17rem;
        min-height: 4rem;
      }
      button {
        padding: 0.3rem 1rem;
      }
    }
    button.close-modal {
      display: flex;
      appearance: none;
      flex-direction: column;
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      svg {
        opacity: 0.6;
      }
    }
    .employment-record {
      position: relative;
      .employment-record-company {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      .employment-record-title {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
      .employment-record-from-to {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
      .employment-record-description {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
    .education-record {
      position: relative;
      .education-record-institution {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      .education-record-degree {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
      .education-record-graduation-date {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
    .spinner-wrapper {
      width: 100%;
      height: 100%;
      min-height: calc(100vh - 15rem);
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
  @media screen and (max-width: 40rem) {
    .content-area {
      margin-top: 2rem;
    }
  }
`

const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
`
const ModalControls= styled.div`
  display: flex; 
  justify-content: center; 
  gap: 1rem; 
  margin-top: 1rem;
  button {
    width: 5.5rem;
    justify-content: center; 
  }
`

const EXPERIENCE_SCHEMA = {
  "topnerd_organization": "",
  "topnerd_job_title": "",
  "job_description": "",
  "location_text": "",
  "is_currently_employed": false,
  "dates": {
    "from_month": "",
    "from_year": "",
    "to_month": "",
    "to_year": "",
  }
};

const EDUCATION_SCHEMA = {
  "topnerd_organization": "",
  "major": "",
  "degree": "",
  "graduation_month": "",
  "graduation_year": "",
}

const PERSONAL_DATA_SCHEMA = {
  "first_name": "",
  "last_name": "",
  "email": "",
  "phone_no": "",
  "linkedin_url": "",
  "location_text": ""
}

const QAHelper = () => {
  const [data, setData]  = useState(null);
  const [experience, setExperience] = useState([]);
  const [education, setEducation] = useState([]);
  const [experienceData, setExperienceData] = useState(structuredClone(EXPERIENCE_SCHEMA));
  const [experienceDataError, setExperienceDataError] = useState({});
  const [educationData, setEducationData] = useState(structuredClone(EDUCATION_SCHEMA));
  const [educationDataError, setEducationDataError] = useState({});
  const [personalData, setPersonalData] = useState(structuredClone(PERSONAL_DATA_SCHEMA));
  const [personalDataError, setPersonalDataError] = useState({});
  const [isLoading, setIsLoading]  = useState(null);
  const [isModalOpen, setIsModalOpen]  = useState(false);
  const [isExperienceModalOpen, setIsExperienceModalOpen]  = useState(false);
  const [isEducationModalOpen, setIsEducationModalOpen]  = useState(false);
  const loading = useRef(false);
  useAuth();

  const getData = async () => {
    if (loading.current) return;
    try {
      setIsLoading(true);
      loading.current = true;
      const data = await getNextResumeToBuild();
      if (data?.resume_id) {
        setData(data);
      } else {
        setData(false);
      }
    } catch (err) {
      console.log(err);
      setData(false);
    } finally {
      setIsLoading(false);
      loading.current = false;
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const onExperienceDataChange = (data) => {
    if (!!experienceData.topnerd_organization) {
      setExperienceDataError({...experienceDataError, topnerd_organization: ''});
    }
    setExperienceData(data);
  }

  const onExperienceAdd = () => {
    if (!experienceData.topnerd_organization) {
      setExperienceDataError({topnerd_organization: 'This field cannot be empty'});
    } else {
      setExperienceDataError({});
      setExperience([...experience, experienceData]);
      setExperienceData(structuredClone(EXPERIENCE_SCHEMA));
      setIsExperienceModalOpen(false);
    }
  }

  const onEducationDataChange = (data) => {
    if (!!educationData.topnerd_organization) {
      setEducationDataError({...educationDataError, topnerd_organization: ''});
    }
    setEducationData(data);
  }

  const onPersonalDataChange = (data) => {
    const error = {...personalDataError};
    if (!!data.first_name) {
      error.first_name = '';
    }
    if (!!data.last_name) {
      error.last_name = '';
    }
    if (!!data.email) {
      error.email = '';
    }
    setPersonalDataError(error);
    setPersonalData(data);
  }

  const onEducationAdd = () => {
    if (!educationData.topnerd_organization) {
      setEducationDataError({topnerd_organization: 'This field cannot be empty'});
    } else {
      setEducationDataError({});
      setEducation([...education, educationData]);
      setEducationData(structuredClone(EDUCATION_SCHEMA));
      setIsEducationModalOpen(false);
    }
  }

  const validate = () => {
    let isValid = true;
    const error = {};
    if (!personalData.first_name) {
      error.first_name = 'This field cannot be empty';
      isValid = false;
    }
    if (!personalData.last_name) {
      error.last_name = 'This field cannot be empty';
      isValid = false;
    }
    if (!personalData.email) {
      error.email = 'This field cannot be empty';
      isValid = false;
    }
    setPersonalDataError(error);
    return isValid;
  }

  const onResumeSubmit = async () => {
    if (validate()) {
      try { 
        window.scrollTo(0,0);
        setIsLoading(true);
        const skills = document.querySelector(".skills-textarea")?.value || '';
        await resumeSubmitContent(data?.resume_id, experience, education, skills, personalData);
        setIsModalOpen(true);
      } catch (err) {
        console.log(err);
      } finally {
        setExperience([]);
        setEducation([]);
        setExperienceData(structuredClone(EXPERIENCE_SCHEMA));
        setExperienceDataError({});
        setEducationData(structuredClone(EDUCATION_SCHEMA));
        setEducationDataError({});
        setPersonalData(structuredClone(PERSONAL_DATA_SCHEMA));
        setPersonalDataError({});
        setIsLoading(false);
      }  
    } else {
      window.scrollTo(0,0);
    }
  }

  const loadNextResume = async () => {
    setIsLoading(true);
    setIsModalOpen(false);
    await getData();
    setIsLoading(false);
  }

  const removeExperienceRecord = (item) => {
    setExperience(experience.filter(c => c !== item));
  }

  const removeEducationRecord = (item) => {
    setEducation(education.filter(c => c !== item));
  }

  const onModalClose = () => {
    setIsModalOpen(false);
  }

  const onExperienceModalClose = () => {
    setExperienceDataError({});
    setExperienceData(structuredClone(EXPERIENCE_SCHEMA));
    setIsExperienceModalOpen(false);
  }

  const openExperienceModal = () => {
    setExperienceDataError({});
    setExperienceData(structuredClone(EXPERIENCE_SCHEMA));
    setIsExperienceModalOpen(true);
  }

  const onEducationModalClose = () => {
    setEducationDataError({});
    setEducationData(structuredClone(EDUCATION_SCHEMA));
    setIsEducationModalOpen(false);
  }

  const openEducationModal = () => {
    setEducationDataError({});
    setEducationData(structuredClone(EDUCATION_SCHEMA));
    setIsEducationModalOpen(true);
  }


  const renderContent = () => {
    if (isLoading) return <div className='spinner-wrapper'>
      <Spinner/>
    </div>
    if (!data) return <div className='spinner-wrapper'>
      No more resumes left to review
    </div>
    return <div className='resume'>
      <br/>
      <br/>
      <div className='filename'>
        <a href={data.s3_path} rel="noreferrer" target="_blank">
          {data.resume_file_name}
        </a> 
      </div>
      <br/>
      <br/>
      <div className="separator"/>
        <PersonalInfoForm
            data={personalData} 
            onChange={onPersonalDataChange}
            error={personalDataError}
        />
        <br/>
      <div className="separator"/>
      <h3>Experience</h3>
      {
        experience?.map((item, i) => <div className='employment-record' key={i}>
          <div className='employment-record-company'>{item.topnerd_organization}</div>
          <div className='employment-record-title'>{item.topnerd_job_title}</div>
          <div className='employment-record-from-to'>
            {item.dates.from_month} {item.dates.from_year} - {item.dates.to_month} {item.dates.to_year}</div>
          <div className='employment-record-description'>{item.job_description}</div>
          <button className='close-modal' onClick={() => removeExperienceRecord(item)}>
            <MdClose color={'black'} size={'1.4rem'}/>
          </button>
        </div>)
      }
      <WhiteButton onClick={openExperienceModal}>Add</WhiteButton>  
      <br/>
      <br/>
      <div className="separator"/>
      <h3>Education</h3>
      {
        education?.map((item, i) => <div className='education-record' key={i}>
          <div className='education-record-institution'>{item.topnerd_organization}</div>
          <div className='education-record-degree'>{item.degree}, {item.major}</div>
          <div className='education-record-graduation-date'>{item.graduation_month} {item.graduation_year}</div>
          <button className='close-modal' onClick={() => removeEducationRecord(item)}>
            <MdClose color={'black'} size={'1.4rem'}/>
          </button>
        </div>)
      }
      <WhiteButton onClick={openEducationModal}>Add</WhiteButton>  
      <br/>
      <br/>
      <div className="separator"/>
      <h3>Skills</h3>
      <textarea 
          name="skills"
          className="skills-textarea"
          placeholder="Comma-separated skills" 
      />
      <br/>
      <br/>
      <div className="separator"/>
      <br/>
      <br/>
      <WhiteButton onClick={onResumeSubmit}>Submit Resume Content</WhiteButton>
      <br/>
      <br/>
    </div>
  }

  return (
    <>
      <PageContainer 
          selected={'resume-builder'}
          title={'Tools / Resume Builder'}
          navGroup='TOOLS'
          hideNavPanel={false}
          hideNavBar={false}
        >
        <Container>
          <div className="content-area">
            {renderContent()}
          </div>  
        </Container>
      </PageContainer>
      <StyledModal
        isOpen={isExperienceModalOpen}
        onClose={onExperienceModalClose}
        unclosable={true}
        modalStyle={{
          width: 'auto',
          minWidth: '45rem',
          padding:'1rem',
          boxSizing: 'border-box'
        }}
      >
        <ExperienceForm 
          data={experienceData} 
          onChange={onExperienceDataChange}
          error={experienceDataError}
        />
        <ModalControls>
          <WhiteButton onClick={onExperienceAdd}>Add</WhiteButton>
          <WhiteButton onClick={onExperienceModalClose}>Cancel</WhiteButton>
        </ModalControls>   
      </StyledModal>
      <StyledModal
        isOpen={isEducationModalOpen}
        onClose={onEducationModalClose}
        unclosable={true}
        modalStyle={{
          width: 'auto',
          minWidth: '23rem',
          padding:'1rem',
          boxSizing: 'border-box',
          overflow: 'visible'
        }}
      >
          <EducationForm 
            data={educationData} 
            onChange={onEducationDataChange}
            error={educationDataError}
          />
          <ModalControls>
            <WhiteButton onClick={onEducationAdd}>Add</WhiteButton>
            <WhiteButton onClick={onEducationModalClose}>Cancel</WhiteButton>
          </ModalControls>      
      </StyledModal>
      <StyledModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        unclosable={true}
        modalStyle={{
          width: '25rem',
          minWidth: '25rem',
          padding:'3rem 2rem 2rem',
          boxSizing: 'border-box'
        }}
      >
        <ModalContainer>
          Resume content submitted successfully.
          <BlackButton onClick={loadNextResume}>Next Resume</BlackButton>
        </ModalContainer>      
      </StyledModal>
    </>
  );
};

export default QAHelper;