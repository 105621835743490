import React, { useState } from "react";
import styled from 'styled-components';
import StyledModal from "./StyledModal";
import BlackButton from "./BlackButton";
import { contactUs } from "../services";
import { useAtom } from 'jotai';
import { contactUsModalAtom} from "../atoms";

var mobile = require('is-mobile');
const IS_MOBILE = mobile();

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  h3 {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
  }
  .message-received {
    flex: 1;
    font-size: 1.5em;
    margin: 2rem 0;
    line-height: 2rem;
    text-align: center;
  }
  .contact-form {
    width: 100%;
    position: relative;
    margin: 2rem 0;
    .form-item-group {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }
    .form-item {
      flex: 1;
      position: relative;
      margin-top: 0.9rem;
      padding-top: 0.85rem;
      .email-error-message {
        position: absolute;
        bottom: -1.2rem;
        font-size: 0.8em;
        left: 0;
        color: var(--color-red0);
        font-weight: 400;
      }
      input:-webkit-autofill {
        font-size: 1.25rem;
        padding: 0;
        line-height: 1.75rem;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
      }
      input, textarea {
        border: none;
        outline: none;
        all: unset;
        display: block;
        width: 100%;
        border-bottom: 1px solid var(--color-gray-2);
        height: 3rem;
        padding: 0;
        margin-top: 1.5rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        box-sizing: border-box;
        &::placeholder {
          color: var(--color-gray-0);
          font-size: 1.25rem;
          padding: 0;
        }
        &.error {
          border-bottom: 1px solid var(--color-red-1);
        }
      }
      textarea {
        margin-top: 1rem;
        height: auto; 
        resize: vertical; 
      }
      textarea[name="description"] {
        min-height: 3rem;
      }

      .floating-label {
        position: absolute;
        pointer-events: none;
        color: var(--color-gray1);
        font-size: 1.25em;
        left: 0;
        bottom: 1.5rem;
        font-weight: normal;
        padding: 0;
      }

      textarea:focus~.floating-label,
      textarea:not(:placeholder-shown)~.floating-label,
      input:focus~.floating-label,
      input:not(:placeholder-shown)~.floating-label,
      input:-webkit-autofill~.floating-label
      {
        top: 0.5rem;
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        padding: 0;
      }
    }
  }
  button {
    width: 100%;
    height: 2.5rem;
    text-align: center;
    justify-content: center;
    font-size: 1rem;
  }
  &.mobile {
    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .message-received {
      flex: 1;
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }
`

const ContactUsModal = () => {
  const [isContactUsOpen, setContactUsOpen] = useAtom(contactUsModalAtom);
  const [formData, setFormData] = useState({
    name: { value: '', error: false },
    email: { value: '', error: false },
    company: { value: '', error: false },
    message: { value: '', error: false }
  });
  const [isUploading, setUploading] = useState(false);
  const [isReceived, setReceived] = useState(false);

  const validateFormData = (data) => {
    const validatedData = {...data};
    let isValid = true;
    Object.keys(validatedData).forEach(key => {
      if(validatedData[key].value.trim() === '') {
        validatedData[key].error = true;
        isValid = false;
      } else {
        validatedData[key].error = false;
      }
    });
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = () => {
    setFormData({
      name: { value: '', error: false },
      email: { value: '', error: false },
      company: { value: '', error: false },
      message: { value: '', error: false }
    });
  }

  const onInputChange = (e) => {
    const data = {...formData};
    data[e.currentTarget.name].value = e.currentTarget.value;
    validateFormData(data);
  }

  const onSubmitClick = async () => {
    if (validateFormData(formData)) {
      setUploading(true);
      await contactUs(
        formData?.name?.value,
        formData?.email?.value,
        formData?.company?.value,
        formData?.message?.value
      );
      clearFormData();
      setReceived(true);
      setUploading(false);  
    }  
  }

  const onClose = () => {
    setContactUsOpen(false);
    clearFormData();
    setReceived(false);
    setUploading(false);
  }

  const render = () => {
    if (isReceived) return <>
      <div className="message-received">
        Your message has been received.<br/>
        Please give us 24 hrs to respond.
      </div>
      <BlackButton onClick={onClose}>Ok</BlackButton>
    </>
    return <>
      <form className="contact-form">
        <div className="form-item">
          <input 
            placeholder="   "
            name="name"
            type="text"
            onChange={onInputChange}
            className={formData?.name?.error ? 'error' : ''}
          />
          <label className="floating-label">Name</label>
        </div>
        <div className="form-item">
          <input 
            placeholder="   "
            name="email"
            type="email"
            onChange={onInputChange}
            className={formData?.email?.error ? 'error' : ''}
          />
            <label className="floating-label">Email</label>
        </div>
        <div className="form-item">
          <input 
            placeholder="   "
            name="company"
            type="text"
            onChange={onInputChange}
            className={formData?.company?.error ? 'error' : ''}
          />
          <label className="floating-label">Company Name</label>
        </div>
        <div className="form-item">
          <input 
            placeholder="   "
            name="message"
            type="text"
            onChange={onInputChange}
            className={formData?.message?.error ? 'error' : ''}
          />
          <label className="floating-label">Message</label>
        </div>
      </form>
      <BlackButton onClick={onSubmitClick}>Submit</BlackButton>
    </>
  }

  return (
    <StyledModal 
      onClose={onClose} 
      isOpen={isContactUsOpen}
      loading={isUploading}
      modalStyle={{
        maxWidth: IS_MOBILE ? '90%' : 'auto',
        width: '33rem',
        minWidth:  IS_MOBILE ? 'auto' : '22rem',
        padding:'3rem 2rem 3rem',
        top: '50%',
        transform: 'translate(-50%, -50%)'

      }}>
      <Container className={IS_MOBILE ? "mobile" : ""}>
        <h3>Contact Us</h3>
        { render() }        
      </Container>  
    </StyledModal>
  );
}

export default ContactUsModal;