import React from "react";
import styled from 'styled-components';

const Container = styled.button`
    all: unset;
    box-sizing: border-box;
    border: 1px solid var(--light-black12);
    background-color: var(--light-black12);
    color: #fff;
    text-align: center;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    font-weight: 500;
    line-height: 1.5;
    font-size: 1rem;
    text-decoration: none;
    font-family: Roboto;
    transition: transform .35s ease-in-out,box-shadow .35s ease-in-out;
    display: flex;
    cursor: pointer;
    &:hover {
      box-shadow: 6px 10px 16px 0 var(--drop-shadow);
    }
    ${props => props.disabled && `
        cursor: unset;
        box-shadow: unset; 
        opacity: 0.2;
      &:hover, &:active {
        cursor: unset;
        box-shadow: unset; 
        opacity: 0.2;
      }`
    }
`

const BlackButton = ({children, disabled, onClick, ...props}) => {
  const onButtonClick = () => {
    if (!disabled) {
      onClick();
    }
  }
  return (
    <Container {...props} disabled={disabled} onClick={onButtonClick}>
      {children}
    </Container>
  );
}

export default BlackButton;