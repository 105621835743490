import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import Spinner from "../components/Spinner";
import ParsedResume from "../components/ParsedResume";
import PageContainer from "../components/PageContainer";
import WhiteButton from "../components/WhiteButton";

import { 
  getHonestResume, 
  submitMatch, 
  saveNameHr, 
  getResumesAround,
  isResumeMatched
} from "../services";

const Container = styled.div`
  margin-top: 0rem;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  align-self: flex-start;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  .loading {
    /* font-weight: bold; */
    display:inline-block;
    /* font-family: monospace; */
    /* font-size: 30px; */
    clip-path: inset(0 0.7rem 0 0);
    animation: l 1s steps(3) infinite;
  }

  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }
  .matches-role {
    opacity: 1;
    transition: opacity 1s; 
    &.fade {
      opacity: 0;
    }
  }
  .top-left-fixed-container {
    position: fixed;
    top: 1rem;
    left: calc(max(50% - 30rem, 1rem));
    display: flex;
    gap: 1rem;
  }
  .top-right-fixed-container {
    position: fixed;
    top: 1rem;
    right: calc(max(50% - 30rem, 1rem));
    display: flex;
    gap: 1rem;
  }
  .content {
    flex: 1;
    position: relative;
    padding: 0 0.5rem;
    max-width: 60rem;
    .spinner {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }
`

const HonestResume = () => {
  const isLoaded = useRef(false);
  const [ loading, setLoading ] = useState(true);
  const [data, setData]  = useState(null);
  const [resumeId, setResumeId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [saving, setSaving] = useState(false);
  const [showMatchesButton, setShowMatchesButton] = useState(null);
  const [resumesAround, setResumesAround] = useState(null);

  useEffect(() => {
    const getResume = async () => {
      try {
        const url = new URL(window.location.href);
        const resume_id = url.searchParams.get("resume_id");
        const role_id = url.searchParams.get("role_id");
        setResumeId(resume_id);
        setRoleId(role_id);
        const data = await getHonestResume(resume_id);
        if (data?.yoe) {
          Object.getOwnPropertyNames(data?.yoe).forEach(key => {
            if (data?.yoe[key] === '0 years, 0 months' && key !== 'total') {
              data.yoe[key] = null;
            }
          })
        }
        setData(data);
        if (role_id) {
          const res = await isResumeMatched(role_id, resume_id);
          setShowMatchesButton(!res?.is_resume_matched);
        }
        if (role_id) {
          const res2 = await getResumesAround(role_id, resume_id);
          setResumesAround(res2?.resume_ids_around);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
    // const checkMatched = async () => {
    //   try {
    //     const url = new URL(window.location.href);
    //     const resume_id = url.searchParams.get("resume_id");
    //     const role_id = url.searchParams.get("role_id");
    //     const res = await isResumeMatched(role_id, resume_id);
    //     setShowMatchesButton(!res?.is_resume_matched);
    //   } catch (err) {
    //     setShowMatchesButton(true);
    //   }
    // }
    if (!isLoaded.current) {
      isLoaded.current = true;
      getResume();
      // checkMatched();
    }
  }, []);

  const renderContent = () => {
    if (loading || !data) return (
      <div className="content">
        <div className="spinner">
          <Spinner/>
        </div>
      </div>
    );
    if (data) return (
      <div className="content">
          <ParsedResume data={data} resumeId={resumeId}/>
      </div>
    )
  }

  const onMatchesRoleClick = async () => {
    try {
      setSaving(true);
      const res = await submitMatch(roleId, resumeId);
      console.log(res);
    } catch (err) {
      console.log(err);
    } finally {
      setSaving(false);
      setShowMatchesButton(false);
    }
  }

  const renderMatchesButton = () => {
    if (!showMatchesButton) return (
      <WhiteButton className="matches-role fade" onClick={()=>{}}>
        Saved!
      </WhiteButton>
    )
    if (saving) return (
      <WhiteButton className="matches-role" onClick={()=>{}}>
        <div className="loading">Saving...</div>
      </WhiteButton>
    )
    return <WhiteButton className="matches-role" onClick={onMatchesRoleClick}>Match</WhiteButton>
  }

  const goToResume = (resumeId) => {
    window.location.href = `/honest-resume?resume_id=${resumeId}&role_id=${roleId}`;
  }

  const renderLeftButton = () => {
    if (!resumesAround || !resumeId) return null;
    const index = resumesAround.findIndex(item => Number(item) === Number(resumeId));
    if (index <= 0) return null;
    const id = resumesAround[index-1];
    return <WhiteButton className="previous-resume-around" onClick={() => goToResume(id)}>Previous Best</WhiteButton>
  }

  const renderRightButton = () => {
    if (!resumesAround || !resumeId) return null;
    const index = resumesAround.findIndex(item => Number(item) === Number(resumeId));
    if (index < 0 || index === resumesAround.length-1)  return null;
    const id = resumesAround[index+1];
    return <WhiteButton className="next-resume-around" onClick={() => goToResume(id)}>Next Best</WhiteButton>
  }


  return (
    <PageContainer 
      title={'Honest Resume'} 
      hideNavPanel={true} 
      hideNavBar={true} 
      hideLogo={true} 
      selected={null}>
    <Container>
      { renderContent() }
      {
        roleId && 
        <div className="top-left-fixed-container">
        { renderLeftButton() }
        </div>
      }
      <div className="top-right-fixed-container">
      {
        roleId &&
        resumeId &&
        renderMatchesButton()
      }
      { 
        roleId &&
        renderRightButton() 
      }
      </div>
      
    </Container>
    </PageContainer>
  );
};

export default HonestResume;