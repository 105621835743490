import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import SlidingModal from "./SlidingModal";
import BlueButton from "./BlueButton";
import StyledCheckbox from "./StyledCheckbox";
import StyledRadioGroup from "./StyledRadioGroup";
import Select from 'react-select';
import SkillSelector from './SkillSelector';
import { Tooltip } from 'react-tooltip';
import KeywordInput from "./KeywordInput";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  height: 100%;
  .i-sign {
    font-size: 0.8rem;
    display: block;
    position: absolute;
    top: 0.05rem;
    right: -1rem;
  }
  .with-tooltip {
    position: relative;
    display: inline-block;
  }
  h3 {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
  }
  h4 {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    margin-top: 2rem;
  }
  span.checkbox-label  {
    opacity: 1;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    color: black;
    padding: 0;
  }
  .contact-form {
    width: 100%;
    padding: 0 0 1.2rem;
    position: relative;    
    .form-item-group {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      &.YOE {
        .form-item {
          input:focus~.floating-label,
          input:not(:placeholder-shown)~.floating-label
          {
            top: 1.75rem;
            opacity: 1;
            display: block;
            font-size: 1rem;
            font-weight: 500;
            color: black;
            padding: 0;
          }
        }
      }
    }
    .error-message {
      font-size: 0.8rem;
      color: var(--color-red-1);
      margin-top: 0.5rem;
    }
    .form-item {
      flex: 1;
      position: relative;
      margin-top: 0.9rem;
      padding-top: 0.85rem;
      box-sizing: border-box;
      >label {
        box-sizing: border-box;
        overflow: hidden;
      }
      &.stack-focus {
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        margin-top: 0rem;
        >div {
          margin-top: 0rem;
          border-bottom: none;
          >label {
            opacity: 1;
            display: block;
            font-size: 1rem;
            font-weight: 500;
            color: black;
            padding: 0;
            margin-bottom: 1rem;
          }
          div.styled-radio-container {
            label {
              font-weight: 400;
              font-size: 1rem;
              margin-top: 0.25rem;
            }
          }
        }
      }

      input, textarea {
        &:active {
          padding: 0;
        }
        border: none;
        outline: none;
        display: block;
        width: 100%;
        border-bottom: 1px solid var(--color-gray-2);
        min-height: 3rem;
        padding: 0;
        font-size: 1.25rem;
        box-sizing: border-box;
        &[type="text"], &[type="number"] {
          all: unset;
          display: block;
          width: 100%;
          margin-top: 1.5rem;
          line-height: 1.75rem;
          min-height: 3rem;
          border-bottom: 1px solid var(--color-gray-2);
        }
        &::placeholder {
          color: var(--color-gray-0);
          font-size: 1.25rem;
          padding: 0;
        }
        &.error {
          border-bottom: 1px solid var(--color-red-1);
        }
      }
      span.checkbox-label  {
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 400;
        color: black;
        padding: 0;
      }
      textarea {
        margin-top: 1rem;
        height: auto; 
        resize: vertical; 
      }
      textarea[name="description"] {
        min-height: 3rem;
        resize: vertical;
      }

      .floating-label {
        position: absolute;
        pointer-events: none;
        color: var(--color-gray1);
        font-size: 1em;
        left: 0;
        bottom: 1rem;
        font-weight: normal;
        padding: 0;
        span {
          font-weight: 300;
          font-size: 0.9rem;
        }
      }
      .in-us-since-label {
        top: 0.5rem;
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 400;
        color: black;
        padding: 0;
      }
      textarea:focus~.floating-label,
      textarea:not(:placeholder-shown)~.floating-label,
      input:focus~.floating-label,
      input:not(:placeholder-shown)~.floating-label
      {
        top: 0.75rem;
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        padding: 0;
        br, span {
          display: none;
        } 
      }
      textarea:focus~.floating-label,
      textarea:not(:placeholder-shown)~.floating-label {
        top: 0rem;
      }
    }
  }
  button {
    width: 100%;
    height: 2.5rem;
    text-align: center;
    justify-content: center;
  }
  .controls {
    display: flex;
    gap: 1rem;
  }
  .location-container {
    margin-top: 4rem;
    .location-label {
      display: none;
      opacity: 1;
      font-size: 1rem;
      font-weight: 500;
      color: black;
      padding: 0;
      margin-top: -1rem;
    }
    &.filled {
      .location-label {
        display: block;   
      }
    }
    .location-type-select {
      .location__control {
        border: none !important;
        border-bottom: 1px solid var(--color-gray0) !important;
        box-shadow: none !important;
        .location__value-container {
          padding: 0;
          min-height: 3.5rem;
          .location__multi-value {
            background: white;
            border: 1px solid black;
            >div {
              background: white;
              svg {
                opacity: 0.6;
              }
            }
          }
        }
        .location__multi-value__remove:hover {
          background-color: #ccc;
          color: black;
        }
        .location__placeholder {
          color:  var(--color-gray1);
          font-size: 1rem;
          padding: 0;
        }
      }
      .location__menu {
        margin-top: -1px !important;
        padding: 0 !important;
        .location__menu-list {
          padding: 0 !important;
          .location__option {
            font-size: 0.9rem !important;
          }
        }
      }
    }
  }
  
  .in-us-since-select {
    max-width: 4rem;
    min-width: 4rem;
    .in-us-since__control {
      border: none !important;
      box-shadow: none;
      height: 3rem;
      color: black;
      font-weight: 400;
      font-family: Roboto;
      padding-left: 0;
      .in-us-since__value-container {
        padding: 2px 0;
      }
      .in-us-since__placeholder {
        color: var(--color-gray1);
        font-size: 0.9em;
      }
      .in-us-since__single-value {
        color: black; 
        font-weight: 400 !important;
        font-family: Roboto;
        font-size: 0.9em;
      }
      .in-us-since__indicators {
        .in-us-since__indicator-separator {
          display: none;
        }
        .in-us-since__dropdown-indicator {
          color: black !important;
          font-weight: 400 !important;
          font-family: Roboto;
          opacity: 0.5;
          padding: 0.5rem 0;
        }
      }
    }
    .in-us-since__menu {
      margin-top: 0 !important;
      padding: 0 !important;
      .in-us-since__menu-list {
        padding: 0 !important;
        .in-us-since__option {
          color: black;
          height: 2rem;
          font-weight: 400 !important;
          font-family: Roboto;
          display: flex;
          align-items: center;
          padding-left: 0.5rem;
          padding: 0.5rem;
          font-size: 0.9em;
          &.in-us-since__option--is-selected {
            color: white;
          }
          &.in-us-since__option--is-focused {
            
          }
        }
      }
    }
  }
  .error-red {
    color: var(--color-red-1) !important;
  }
  .error-red-message {
    color: var(--color-red-1) !important;
    margin-top: 1rem;
    font-size: 0.9em;
  }
`

const SourcingFiltersModal = ({ isOpen, cities, data, onSubmit, onClose }) => {
  const selectInputRef = useRef();
  const [formData, setFormData] = useState();

  useEffect(() => {
    if (isOpen) {
      setFormData({
        location: { value: data?.location || [], error: false },
        stack_skills: { value: data?.stack_skills || [], error: false },
        title_keywords: { value: data?.title_keywords || [], error: false },
        company_funding_stages: { value: data?.company_funding_stages || null, error: false },
        rating: { value: data?.rating || null, error: false },
        early_stage: { value: data?.early_stage === undefined ? false : data?.early_stage, error: false},
        late_stage: { value: data?.late_stage === undefined ? false : data?.late_stage, error: false},
        public: { value: data?.public === undefined ? false : data?.public, error: false},
        big_tech: { value: data?.big_tech === undefined ? false : data?.big_tech, error: false},
        three_year_funding_anniversary: { value: data?.three_year_funding_anniversary === undefined ? false : data?.three_year_funding_anniversary, error: false},
        four_year_funding_anniversary: { value: data?.four_year_funding_anniversary === undefined ? false : data?.four_year_funding_anniversary, error: false},
      })
    }
  }, [data, isOpen])

  const validateFormData = (data) => {
    const validatedData = {...data};
    let isValid = true;
    if (validatedData['title']?.value?.trim() === '') {
      validatedData['title'].error = 'This field cannot be empty.';
      isValid = false;
    }
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = () => {
    setFormData({
      location: { value: [], error: false },
      stack_skills: { value: [], error: false },
      title_keywords: { value: [], error: false },
      early_stage: { value: false, error: false },
      late_stage: { value: false, error: false },
      public: { value: false, error: false },
      big_tech: { value: false, error: false },
      three_year_funding_anniversary: { value: false, error: false },
      four_year_funding_anniversary: { value: false, error: false },
      company_funding_stages: {value: null,  error: false},
      rating: {value: null,  error: false},
    });
  }

  const onInputChange = (e) => {
    const data = {...formData};
    const name  = e.currentTarget.name;
    const value = e.currentTarget.value;
    if (name === 'early_stage' ||
        name === 'late_stage' || 
        name === 'public' ||
        name === 'big_tech' ||
        name === 'four_year_funding_anniversary' ||
        name === 'three_year_funding_anniversary'
      ) {
      data[name].value = !data[name].value; 
    } else {
      data[name].value = value;
    }
    setFormData(data);
  }

  const onLocationChange = (values) => {
    const data = {...formData};
    data.location.value = values.map(item => item.value);
    data.location.error = false;
    setFormData(data);
  }

  const onSkillSetChange = (key, value) => {
    const data = {...formData};
    data[key].value = value;
    setFormData(data);
  }

  const onKeywordsChange = (keywords) => {
    const data = {...formData};
    data.title_keywords.value = keywords;
    setFormData(data);
  }

  const getLocation = (values) => {
    return cities.filter(item => values.includes(item.value));
  }

  const onSubmitClick = async () => {
    if (validateFormData(formData)) {
      //setUploading(true);
      const data = {};
      for (let key in formData) {
        data[key] = formData[key]?.value;
      }
      await onSubmit(data);
      clearFormData();
      //setUploading(false);  
    } else {
      setTimeout(() => {
        const errorInput = document.querySelector('div.error-red-message')
        if (errorInput) {
          errorInput.scrollIntoView();
        }
      }, 50)
    }
  }

  const onModalClose = () => {
    onClose();
  }

  const isLocationEmpty = !formData?.us_states?.value?.length;
  const isSubmitButtonDisabled = formData && Object.keys(formData).find(key => !!formData[key].error);


  return (
    <>
    <SlidingModal 
      onClose={onModalClose} 
      isOpen={isOpen}
      modalStyle={{
        width: '30rem',
        minWidth: '30rem',
        minHeight: '100vh',
        padding:'2rem 2rem 2rem',
        boxSizing: 'border-box'
      }}>
      <Container>
        <h3>Filters</h3>
        <form className="contact-form">
          <h4>Company funding stage</h4>
          <div className="form-item" style={{marginTop: '1rem'}}>
            <StyledRadioGroup 
              name="company_funding_stages"
              options={[
                {name: "Early Stage", value: "early_stage"},
                {name: "Mid/Late Stage", value: "mid_late_stage"},
                {name: "Public", value: "public"},
                // {name: "Big Tech", value: "big_tech"}
              ]}
              onChange={onInputChange}
              value={formData?.company_funding_stages?.value || ''}
            />
          </div>
          {/* <div className="form-item" style={{marginTop: '0.5rem', marginBottom: '0rem'}}></div>
            <StyledCheckbox 
              style={{marginTop: '0rem', marginBottom: '0rem'}}
              name="early_stage"
              onChange={onInputChange}
              checked={formData?.early_stage.value}>
              Early Stage
            </StyledCheckbox>
          </div> */}
          {/* <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
            <StyledCheckbox 
              style={{marginTop: '0rem', marginBottom: '0rem'}}
              name="late_stage"
              onChange={onInputChange}
              checked={formData?.late_stage.value}>
              Late Stage
            </StyledCheckbox>
          </div> */}
          {/* <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
            <StyledCheckbox 
              style={{marginTop: '0rem', marginBottom: '0rem'}}
              name="public"
              onChange={onInputChange}
              checked={formData?.public.value}>
              Public
            </StyledCheckbox>
          </div> */}
          {/* <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
            <StyledCheckbox 
              style={{marginTop: '0rem', marginBottom: '0rem'}}
              name="big_tech"
              onChange={onInputChange}
              checked={formData?.big_tech.value}>
              Big Tech
            </StyledCheckbox>
          </div> */}


          {/* <h4 style={{marginBottom: '-1rem'}} className={formData?.us_states?.error ? 'error-red' : ''}>Location</h4>
          <div className={`form-item location-container ${isLocationEmpty ? 'empty' : 'filled'}`}>
            <Select
              className="location-type-select"
              classNamePrefix="location" 
              closeMenuOnSelect={false}
              placeholder="Cities" 
              ref={selectInputRef}
              isValidNewOption={() => true}
              onChange={onLocationChange}
              defaultValue={getLocation(formData?.location?.value || [])}
              isMulti
              options={cities}
            />
          </div> */}

          <h4 style={{marginBottom: '-0.5rem'}}>Title keywords</h4>
          <div className="form-item">
              <KeywordInput 
                label="e.g.: staff, frontend, devops" 
                value={formData?.title_keywords?.value || []} 
                onChange={onKeywordsChange}/>
          </div>

          <h4 style={{marginBottom: '-1rem'}}>Tech stack</h4>
          <div className="form-item">
            <SkillSelector
              noLabel={true}
              placeholder="e.g.: python, java, react"
              value={formData?.stack_skills.value || ''}
              onChange={(value) => onSkillSetChange('stack_skills', value)}
            />
          </div>

          <h4>Rating</h4>
          <div className="form-item" style={{marginTop: '1rem'}}>
            <StyledRadioGroup 
              name="rating"
              options={[
                {name: "<=3.2", value: "rating_low"},
                {name: "3.2-4.2", value: "rating_moderate"},
                {name: ">=4.2", value: "rating_good"},
                // {name: "Big Tech", value: "big_tech"}
              ]}
              onChange={onInputChange}
              value={formData?.rating?.value || ''}
            />
          </div>

          <h4>Funding anniversary</h4>
            <div className="form-item" style={{marginTop: '0.5rem', marginBottom: '0rem'}}>
              <StyledCheckbox 
                style={{marginTop: '0rem', marginBottom: '0rem'}}
                name="three_year_funding_anniversary"
                onChange={onInputChange}
                disabled={true}
                checked={formData?.three_year_funding_anniversary.value}>
                3 year funding anniversary
              </StyledCheckbox>
            </div>
            <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
              <StyledCheckbox 
                style={{marginTop: '0rem', marginBottom: '0rem'}}
                name="four_year_funding_anniversary"
                onChange={onInputChange}
                disabled={true}
                checked={formData?.four_year_funding_anniversary.value}>
                4 year funding anniversary
              </StyledCheckbox>
           </div>
           <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
              <StyledCheckbox 
                style={{marginTop: '0rem', marginBottom: '0rem'}}
                name="four_year_funding_anniversary"
                onChange={onInputChange}
                disabled={true}
                checked={formData?.four_year_funding_anniversary.value}>
                Layoffs in last 6 months
              </StyledCheckbox>
           </div>
           <div className="form-item" style={{marginTop: '0rem', marginBottom: '0rem'}}>
              <StyledCheckbox 
                style={{marginTop: '0rem', marginBottom: '0rem'}}
                name="four_year_funding_anniversary"
                onChange={onInputChange}
                disabled={true}
                checked={formData?.four_year_funding_anniversary.value}>
                Latest round was a Down round
              </StyledCheckbox>
           </div>
        </form>
        <div style={{flex: 1}}/>
        <BlueButton style={{marginTop: '1rem'}} onClick={onSubmitClick} disabled={isSubmitButtonDisabled}>Apply Filters</BlueButton>
        {
          isSubmitButtonDisabled &&
          <div className='error-red-message'>Please fill the mandatory fields above.</div>
        }
      </Container>  
      <Tooltip id="my-tooltip" />
    </SlidingModal> 
    </>
  );
}

export default SourcingFiltersModal;