import React, { useState, useRef } from "react";
import styled from 'styled-components';
import Spinner from "./Spinner";
import { GrEdit } from "react-icons/gr";
import { MdOutlineCancel } from "react-icons/md";
import { MdOutlineCheckCircle } from "react-icons/md";
import { saveNameHr } from "../services";

const Container = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  svg {
    margin-left: 0.3rem;
  }
  .edit-icon {
    cursor: pointer;
    width: 0.8rem;
    margin-left: 0.5rem;
  }
  .save-icon {
    margin-left: 0.5rem;
  }
  .save-icon, .cancel-icon {
    cursor: pointer;
    width: 1.2rem;
  }
  input {
    padding: 0.2rem 0.5rem;
    font-size: 1rem;
  }
`

const EditableName = ({ resumeId, candidateName, link }) => {
  const [ name, setName ] = useState(candidateName?.trim());
  const [ nameEditing, setNameEditing ] = useState(false);
  const [ isUpdating, setIsUpdating ] = useState(false);
  const oldName = useRef(null);

  const editName = () => {
    oldName.current = name;
    setNameEditing(true);
  }

  const cancelName = () => {
    setName(oldName.current);
    setNameEditing(false);
  }

  const saveName = async () => {
    setIsUpdating(true);
    setNameEditing(false);
    try {
      await saveNameHr(resumeId, name);  
    } catch (err) {
      setName(oldName.current);
    } finally {
      setIsUpdating(false);
    }
  }

  const onNameChange = (e) => {
    setName(e.target.value);
  }

  if (nameEditing) return (
    <Container className="editable-name">
      <input value={name} onChange={onNameChange} autoFocus/>
      <MdOutlineCheckCircle onClick={saveName} className="save-icon"/>
      <MdOutlineCancel onClick={cancelName} className="cancel-icon"/>
    </Container>
  )

  return (
    <Container className="editable-name">
      {
        link ?
        <a href={link} rel="noreferrer" target="_blank">{name}</a> : 
        <>{name}</>
      }
      {
        isUpdating ?
        <Spinner size={'0.8rem'} thickness={250}/> :
        <GrEdit className="edit-icon" onClick={editName}/>
      }
    </Container>
  )
}

export default EditableName;