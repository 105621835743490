
import React from "react";
import styled from 'styled-components';
import Select from 'react-select';

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    font-size: 0.9rem;
  
    .degree-select {
      max-width: 11rem;
      min-width: 11rem;
      .degree__control {
        border: 1px solid var(--color-gray0);
        box-shadow: none;
        height: 2rem;
        color: black;
        font-weight: 400;
        font-family: Roboto;
        padding: 0 0.5rem;
        .degree__value-container {
          padding: 0;
        }
        .degree__placeholder {
          color: var(--color-gray1);
          font-size: 0.9em;
        }
        .degree__single-value {
          color: black; 
          font-weight: 400 !important;
          font-family: Roboto;
          font-size: 0.9em;
        }
        .degree__indicators {
          .degree__indicator-separator {
            display: none;
          }
          .degree__dropdown-indicator {
            color: black !important;
            font-weight: 400 !important;
            font-family: Roboto;
            opacity: 0.5;
            padding: 0.5rem 0;
          }
        }
      }
      .degree__menu {
        margin-top: 0 !important;
        padding: 0 !important;
        .degree__menu-list {
          padding: 0 !important;
          .degree__option {
            color: black;
            height: 2rem;
            font-weight: 400 !important;
            font-family: Roboto;
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
            padding: 0.5rem;
            font-size: 0.9em;
            &.degree__option--is-selected {
              color: white;
            }
            &.degree__option--is-focused {
              
            }
          }
        }
      }
    }
`

const DEGREE_LIST = [
  {label: 'MBA', value: 'MBA'}, 
  {label: 'CodingBootcamp', value: 'CodingBootcamp'}, 
  {label: 'Associate', value: 'Associate'}, 
  {label: 'Bachelors', value: 'Bachelors'},
  {label: 'Masters', value: 'Masters'}, 
  {label: 'Doctorate', value: 'Doctorate'}, 
  {label: 'Certification', value: 'Certification'}
];


const DegreeSelect = ({value, onChange}) => {
  const onDegreeSelect = (item) => {
    onChange(item.value);
  }
  return (
    <Container>
      <Select
        className="degree-select"
        classNamePrefix="degree" 
        placeholder="Degree" 
        options={DEGREE_LIST}
        onChange={onDegreeSelect}
        isSearchable={false}
        value={DEGREE_LIST.find(c => c.value === value) || null}
      />
    </Container>
  );
}

export default DegreeSelect;