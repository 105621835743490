import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import AssessmentListItem from "../components/AssessmentListItem";
import ParsedResumeModal from "../components/ParsedResumeModal";
import PaginationControl from "../components/PaginationControl";
import StyledSelect from "../components/StyledSelect";
import { getJobs, getRequirementAssessment } from "../services";
import { useAuth } from '../hooks';
import PlainNavMenu from "../components/PlainNavMenu";
import Spinner from "../components/Spinner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  text-align: left;
  .content-area {
    width: 100%;
    height: 100%;
    max-width: 80rem;
    padding: 0 2rem 2rem;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: row;
    .plain-nav-menu {
      #assessments {
        font-weight: 600;
      }
    }
    .content {
      flex: 1;
      height: 100%;
    }
    .filter-container {
      width: 14rem;
      margin-bottom: 2rem;
    }
    .spacer {
      flex: 1;
    }
    .spinner {
      position: fixed;
      right: 0;
      top: 10rem;
      bottom: 0;
      left: 0;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }
`

const Assessments = () => {
  const [assessments, setAssessments] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [page, setPage] = useState(1);
  //const [assessmentsPerPage, setAssessmentsPerPage] = useState(10);
  const assessmentsPerPage = 10;
  const [loading, setLoading] = useState(false);
  //const windowSize = useWindowRemSize();
  useAuth();
 
  // useEffect(() => {
  //   setAssessmentsPerPage(windowSize?.assessmentsPerPage);
  //   setPage(1);
  // }, [windowSize?.assessmentsPerPage])


  const onPageSelect = (n) => {
    window.scrollTo(0,0);
    setPage(n);
  }

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true)
      try {
        const jobs = await getJobs();
        setJobs(jobs)
        if (jobs.length > 0) {
          await fetchAssessments({label: jobs[0][1], value: jobs[0][0]})
        }
      } catch(err) {
        // TODO this should notify the user that a problem occurred
        setAssessments([])
      } finally {
        setLoading(false)
      }
    }
    fetchJobs()
  }, [setLoading])

  const fetchAssessments = async (option) => {
    setLoading(true)
    setSelectedJob(option)
    try {
      const data = await getRequirementAssessment(option.value);
      if (data) {
        setAssessments(data);
      }
    } catch(err) {
      setAssessments([])
      // TODO this should notify the user that a problem occurred
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
    <Container className="App">
      <NavBar/>
      <div className="content-area">  
        <PlainNavMenu/>
        <div className="content">
          <div className="filter-container">
            <StyledSelect 
              placeholder="Jobs" 
              options={jobs.map((job) => {
                return {label: job[1], value: job[0]}
              })}
              value={selectedJob}
              onChange={fetchAssessments}
            />
          </div>
          {
            loading.current ?
            <div className="spinner">
              <Spinner/>
            </div>
             :
            assessments
            .slice((page-1)*assessmentsPerPage, page*assessmentsPerPage)
            .map((item, i) => <AssessmentListItem key={i} data={item}/>)
          }
          <div className="spacer"/>
          {
            !!assessments.length && 
            !loading.current &&
            <PaginationControl 
              selected={page} 
              total={Math.ceil(assessments.length/assessmentsPerPage)} 
              onClick={onPageSelect}/>
          }
          </div>
        </div>    
    </Container>
    <Footer/>
    <ParsedResumeModal/>
    </>
  );
};

export default Assessments;