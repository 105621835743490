import React from "react";
import styled from 'styled-components';

const Container = styled.button`
    all: unset;
    box-sizing: border-box;
    display: flex;
    color: white;
    border-radius: 0.2rem;
    align-items: center;
    padding: 0.5rem 2.5rem;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 600ms;
    /* background-color: var(--color-blue1); */
    background-color: var(--light-black12);
    cursor: pointer;
    &:hover {
      /* background-color: --color-blue2;
      box-shadow: inset 0 0 0 2px #0a66c2; */
      box-shadow: 6px 10px 16px 0 var(--drop-shadow);
    }
    &:active {
      /* background-color: #09223b; */
    }
    ${props => props.disabled && `
        cursor: unset;
        box-shadow: unset; 
        opacity: 0.2;
      &:hover, &:active {
        cursor: unset;
        box-shadow: unset; 
        opacity: 0.2;
      }`
    }
`


const BlueButton = ({children, disabled, onClick, ...props}) => {
  const onButtonClick = () => {
    if (!disabled) {
      onClick();
    }
  }
  return (
    <Container {...props} disabled={disabled} onClick={onButtonClick}>
      {children}
    </Container>
  );
}

export default BlueButton;