import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { useAuth } from "../hooks";
import BlackButton from "../components/BlackButton";
import StartTrialModal from "../components/StartTrialModal";
import CompleteTrialModal from "../components/CompleteTrialModal";
import PageContainer from "../components/PageContainer";
import GreyBar from "../components/GreyBar";
import { p2r } from "../utils";
import { useNavigate } from 'react-router';


var mobile = require('is-mobile');
const IS_MOBILE = mobile();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  flex:1;
  h1 {
    margin: ${p2r(230)} 0 0;
    font-size: 3rem;
    font-weight: 500;
    line-height: 3.5rem;
    max-width: 70rem;
    text-align: center;
  }
  .grey-bar {
    margin-top: ${p2r(10)};
  }
  .sign-up-note {
    font-style: italic;
    margin-top: 0.5rem;
    font-size: 0.9rem;
  }
  p {
    font-size: 1rem;
    margin: 2rem 0 2rem;
  }
  div.mobile-disclaimer {
    display: none;
  }
  &.mobile {
    h1 {
      font-size: 2rem;
      line-height: 3.5rem;
      margin: 0 2rem;
      text-align: center;
    }
    p {
      margin: 2rem;
      line-height: 1.5rem;
    }
    div.mobile-disclaimer {
      display: block;
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 1.25rem;
      margin: 2rem 3rem 0;
    }
  }
`;

const Main = () => {
  const navigate = useNavigate();
  useAuth(false);

  const onTryNowClick = () => {
    navigate('/signup');
  }

  return (
    <PageContainer selected={'home'}>
      <Container>
        <h1>Tired Of Screening Resumes<br/> Manually?</h1>
        <GreyBar/>
        <p>Define roles. Specify filters. Upload resumes. We screen in seconds. Simple.</p>
        <BlackButton onClick={onTryNowClick} disabled={IS_MOBILE}>Start Trial</BlackButton>
        <div className="mobile-disclaimer">
        *Honest Resume is not supported on mobile devices, please load the site on a desktop/laptop.
      </div>
      </Container>
    </PageContainer>
  );
};

export default Main;