import React from "react";
import styled from 'styled-components';
import { useAuth } from "../hooks";
import PageContainer from "../components/PageContainer";

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  flex:1;
  text-align: left;
  padding: 0 2.5rem;
  box-sizing: border-box;
  margin-top: 5rem;
  h3 {
    margin: 2rem 0 1rem;
    font-size: 1rem;
    font-weight: 500;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin: 0 0 1rem;
  }
`;

const Pricing = () => {
  useAuth(false);
 
  return (
    <>
      <PageContainer 
        selected={'pricing'}
        title={'Pricing'}
      >
        <Container>
        <h3>Trial</h3>
        <p>We offer a 15 day trial period. You can create one role and 
          upload and get assessment on upto 50 resumes</p>
        <h3>Single Plan</h3>
        <p>
          1. We charge 18 cents/resume for our assessments. 
          No fee is charged if we are unable to process a resume.<br/>
          2. You can purchase credits in multiples of $100.<br/>
          3. Credits stay valid for three years.
        </p>
        </Container>
      </PageContainer>
    </>
  );
};

export default Pricing;
