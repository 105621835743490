import { FilePicker } from "./file-picker";
import StyledModal from "../StyledModal";

function FileSelector({ isOpen, onClose, onSubmit, disabled=false}) {
  return (
    <StyledModal 
      onClose={onClose} 
      isOpen={isOpen}
      unclosable={disabled}
      modalStyle={{
        width: '50rem',
        minWidth: '50rem',
        padding:'2rem 2rem 1rem',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
      <FilePicker onSubmit={onSubmit} disabled={disabled} accept={['doc', '.docx', '.pdf']}/>
    </StyledModal>
  );
}

export default FileSelector;