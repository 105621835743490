
import React from "react";
import styled from 'styled-components';
import MonthSelect from "./MonthSelect";
import MajorSelect from "./MajorSelect";
import DegreeSelect from "./DegreeSelect";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: 45rem;
  border-radius: 0.2rem;
  margin-bottom: 0.5rem;
  align-items: flex-start;
  padding: 1.25rem 0 0.5rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  div.form-group {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
  }
  div.form-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    .error-message {
      color: var(--color-red-1) !important;
      font-size: 0.9em;
      align-self: flex-start;
      margin-top: -0.5rem;
    }
    input.simple-input {
      height: 2.2rem;
      width: 100%;
      padding: 0 0 0 0.5rem;
      border: 1px solid var(--color-gray0);
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      box-sizing: border-box;
      &::placeholder {
        color: var(--color-gray1);
      }
    }
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }    
`

const EducationForm = ({ data, error, onChange }) => {
  const onMonthChange = (value) => {
    const education = {...data};
    education.graduation_month = value;
    onChange(education);
  }

  const onMajorChange = (value) => {
    const education = {...data};
    education.major = value;
    onChange(education);
  }
  const onDegreeChange = (value) => {
    const education = {...data};
    education.degree = value;
    onChange(education);
  }
  const onInputChange = (e) => {
    const education = {...data};
    education[e.target.name] = e.target.value;
    onChange(education);
  }
  
  return (
    <Container>
      <div className="form-item" style={{width: '100%', flexDirection: "column"}}>
        <input 
          name="topnerd_organization"
          value={data?.topnerd_organization}
          className="simple-input"
          placeholder="Institution Name*" 
          onChange={onInputChange}/>
          {
            error?.topnerd_organization && 
            <div className="error-message">{error?.topnerd_organization}</div>
          }
      </div>
      <div  className="form-group" style={{ marginTop: '1rem', width: 'auto'}}>
        <div  className="form-item" >
          <DegreeSelect value={data?.degree} onChange={onDegreeChange}/>
        </div>
        <div  className="form-item" >
          <MajorSelect value={data?.major} onChange={onMajorChange}/>
        </div>
      </div>
      <div className="form-item" style={{ marginTop: '1rem'}}>
        <label>Graduation date</label> 
        <MonthSelect value={data?.graduation_month} onChange={onMonthChange}/>
        <input 
          name="graduation_year"
          value={data?.graduation_year}
          className="simple-input"
          style={{width: '3rem'}}
          onChange={onInputChange}  
          placeholder="YYYY" 
          type="number" 
          step="1" 
          min="1900"/>
          <div style={{flex: 1}}/>
      </div>       
    </Container>
  );
}

export default EducationForm;