import axios from 'axios';

export const getStage = async () => {
  if (process.env.NODE_ENV === 'production') {
    const origin = window.location.origin;
    const response = await axios.get(`${origin}/env`);
    return response.data.stage;
  } else {
    return 'development';
  }
}

export const getDomain = async () => {
    const stage = await getStage();
    switch(stage) {
      case "production":
        return "https://api.honestresume.io"
      case "preprod":
        return "https://api.preprod.honestresume.io"
      default:
        return "http://localhost:5000/"
    }
}

export const getUrl = async () => {
    const stage = await getStage();
    switch(stage) {
      case "production":
        return "https://honestresume.io/"
      case "preprod":
        return "https://preprod.honestresume.io/"
      default:
        return "http://localhost:3000/"
    }
}