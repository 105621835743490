const assessments = [
  {
     "name":"Mathieu Prichonnet",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/mprichonnet_resume_us.pdf",
     "requirement_match_count":2,
     "preferred_match_count": 0,
     "dt_applied": new Date(2023,
     8,
     9).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"tableau",
           "color":"green"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"No",
           "color":"red"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"No",
           "color":"red"
        }
     }
  },
  {
     "name":"Doug Wade",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Doug Wade - Resume.pdf",
     "requirement_match_count":1,
     "preferred_match_count":0,
     "dt_applied": new Date(2023,
     8,
     7).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"No",
           "color":"red"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"No",
           "color":"red"
        }
     }
  },
  {
     "name":"MICHELLE A",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Adelufosi resume and cv.pdf",
     "requirement_match_count":2,
     "preferred_match_count":2,
     "dt_applied": new Date(2023,
     8,
     5).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"tableau",
           "color":"green"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"US Bachelors: nonprofit leadership center - tampa",
           "color":"green"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"Yes",
           "color":"green"
        }
     }
  },
  {
     "name":"Abhinav Chauhan",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Resume_Abhinav_Chauhan.pdf",
     "requirement_match_count":2,
     "preferred_match_count":1,
     "dt_applied": new Date(2023,
     8,
     5).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"germany",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"tableau",
           "color":"green"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"No",
           "color":"red"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"Yes",
           "color":"green"
        }
     }
  },
  {
     "name":"Bridget Dixon",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Bridget_Dixon_-_Account_Manager+(6).pdf",
     "requirement_match_count":1,
     "preferred_match_count":1,
     "dt_applied":new Date(2023,
     8,
     5).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"US Bachelors: university of central florida - orlando",
           "color":"green"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"No",
           "color":"red"
        }
     }
  },
  {
     "name":"Adam Sparrow",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Adam Sparrow Resume - Bidease.pdf",
     "requirement_match_count":0,
     "preferred_match_count":1,
     "dt_applied":new Date(2023,
     8,
     4).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"us",
           "color":"red"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"No",
           "color":"red"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"Yes",
           "color":"green"
        }
     }
  },
  {
     "name":"Steven McCullough",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/-43ee4970d502a19bc365096ce2df996e-00 - Steven McCullough - Steven McCullough-1.pdf",
     "requirement_match_count":1,
     "preferred_match_count":1,
     "dt_applied":new Date(2023,
     8,
     3).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"US Bachelors: california university of pennsylvania us-pennsylvania-california",
           "color":"green"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"No",
           "color":"red"
        }
     }
  },
  {
     "name":"VISHNU KUMAR",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Vishnu kumar_RSM.pdf",
     "requirement_match_count":1,
     "preferred_match_count":2,
     "dt_applied":new Date(2023,
     8,
     2).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"US Bachelors: university of california, berkeley",
           "color":"green"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"Yes",
           "color":"green"
        }
     }
  },
  {
     "name":"Traci Brown",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Traci Brown Resume.pdf",
     "requirement_match_count":1,
     "preferred_match_count":0,
     "dt_applied":new Date(2023,
     8,
     1).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"No",
           "color":"red"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"No",
           "color":"red"
        }
     }
  },
  {
     "name":"Srikanta Sahoo",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Srikanta_Sahoo.pdf",
     "requirement_match_count":2,
     "preferred_match_count":1,
     "dt_applied":new Date(2023,
     8,
     1).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"tableau",
           "color":"green"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"No",
           "color":"red"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"Yes",
           "color":"green"
        }
     }
  },
  {
     "name":"Amir Younesi",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/AmirY_Resume_2023.pdf",
     "requirement_match_count":1,
     "preferred_match_count":1,
     "dt_applied":new Date(2023,
     8,
     1).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"US Bachelors: purdue university",
           "color":"green"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"No",
           "color":"red"
        }
     }
  },
  {
     "name":"Lisa Gordon",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/lisa_gordon.pdf",
     "requirement_match_count":2,
     "preferred_match_count":1,
     "dt_applied":new Date(2023,
     8,
     1).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"Present",
           "color":"green"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"US Bachelors: university of iowa",
           "color":"green"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"No",
           "color":"red"
        }
     }
  },
  {
     "name":"Jackie Grogan",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Jackie Grogan_Resume.pdf",
     "requirement_match_count":1,
     "preferred_match_count":1,
     "dt_applied":new Date(2023,
     8,
     1).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"Could not determine",
           "color":"grey"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"Present",
           "color":"green"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"US Bachelors: university of richmond",
           "color":"green"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"No",
           "color":"red"
        }
     }
  },
  {
     "name":"Asher Salik",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Asher Salik 2023 Revenue Operations Leader CV.docx.pdf",
     "requirement_match_count":1,
     "preferred_match_count":2,
     "dt_applied":new Date(2023,
     8,
     1).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"US Bachelors: new york university",
           "color":"green"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"Yes",
           "color":"green"
        }
     }
  },
  {
     "name":"Dario Osowski",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Dario Osowski Resume 2023 - Technical Partnership Manager.docx",
     "requirement_match_count":1,
     "preferred_match_count":2,
     "dt_applied":new Date(2023,
     8,
     1).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"US Bachelors: johnson & wales university - providence",
           "color":"green"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"Yes",
           "color":"green"
        }
     }
  },
  {
     "name":"Anil Kumar Papisetty",
     "resume_s3_path":"s3://topnerd-com-resumes/topnerd/client_resumes/bidease/Technical Partnership Manager/fdd94439-f0c0-4fbe-a4c8-0af8d2903dcb/Profile.pdf",
     "requirement_match_count":1,
     "preferred_match_count":0,
     "dt_applied":new Date(2023,
     8,
     1).toLocaleDateString('en-US'),
     "requirement_label":{
        "location":{
           "name":"Location",
           "result":"united states",
           "color":"green"
        },
        "programmatic_ad_experience":{
           "name":"Programmatic Ad Experience",
           "result":"None",
           "color":"red"
        },
        "data_visualization":{
           "name":"Data Visualization Tools",
           "result":"Not Present",
           "color":"red"
        },
        "mobile_ad_manager":{
           "name":"Mobile Ad Manager",
           "result":"0 years, 0 months",
           "color":"red"
        }
     },
     "preferred_label":{
        "has_us_bachelors":{
           "name":"Has US Bachelors",
           "result":"No",
           "color":"red"
        },
        "sql":{
           "name":"Knows SQL",
           "result":"No",
           "color":"red"
        }
     }
  }
]

const resume1 = {
  "country_of_residence": "United States",
  "email": "test@test.com",
  "github": "",
  "ic_yoe": "0 years, 0 months",
  "immigration_status": "US Citizen or GC Holder",
  "linkedin": "",
  "manager_yoe": "8 year(s), 10 month(s)",
  "name": "Test Test",
  "phone": "1234567890",
  "school_work_experience": [],
  "total_yoe": "12 year(s), 10 month(s)",
  "work_experience": [
      {
          "company_name": "sharkninja",
          "description": "\u00d7 Led the development and successful launch of four SKU families, including purifiers, heaters, and humidifiers, driving significant revenue growth within the category. \u00d7 Defined the category roadmap and growth strategies, focusing on new product categories, global expansion, and impactful messaging to drive sales. \u00d7 Managed a global team of direct reports, including people managers, ensuring effective collaboration and \nperformance delivery.",
          "domain": "Other",
          "from_and_to": "From: Jan, 2016 - To: Nov, 2023",
          "tenure": "7 year(s), 10 month(s)",
          "tenure_in_months": 94,
          "title": "Senior Engineering Manager, Home Environment",
          "was_promoted": true
      },
      {
          "company_name": "sharkninja",
          "description": "\u00d7 Conceptualized and brought the Shark Air Purifier 4 & 6 (Amazon 4.6\u2606, 2.6k ratings) to market within one \nyear in response to COVID-19 pandemic. In doing so, created the Shark Home Environment category. \u00d7 Orchestrated the end-to-end product development process, including hardware design and validation, UI and software development, ID/CMF, global component sourcing, product cost management, and marketing strategy. \u00d7 Acted as main technical point of contact for all cross-functional teams, ensuring seamless collaboration and successful product launches. \u00d7 Managed and guided direct reports and global design teams in Asia and Europe.",
          "domain": "Other",
          "from_and_to": "From: Jan, 2020 - To: Jan, 2021",
          "tenure": "1 year(s)",
          "tenure_in_months": 12,
          "title": "Engineering Manager, Home Environment",
          "was_promoted": false
      },
      {
          "gap_in_months": 11,
          "length_label": "11 month(s)"
      },
      {
          "company_name": "heated cooking",
          "description": "\u00d7 Led the launch of innovative consumer products, such as the Ninja Foodi (Amazon 4.8\u2606, 9.8k ratings), the world's first combination air fryer pressure cooker, and the Ninja AF101 (Amazon 4.8\u2606, 45.1k ratings). \u00d7 Achieved substantial annual revenue growth with category by introducing a series of new products, such as the Ninja FD401 (Amazon 4.8\u2606, 33.7k ratings) and driving continuous product improvements. \u00d7 Owned project schedules, product costs, and technical documentation for multiple SKUs across their lifecycle. \u00d7 Collaborated with global cross-functional teams to define key performance indicators, identify and resolve failures, optimize product performance, and drive cost savings.",
          "domain": "Finance",
          "from_and_to": "From: Jan, 2017 - To: Jan, 2019",
          "tenure": "2 year(s)",
          "tenure_in_months": 24,
          "title": "Technical Project Manager",
          "was_promoted": false
      },
      {
          "company_name": "coffee",
          "description": "\u00d7 Managed project schedules, product costs, and technical documentation for multiple SKUs within the Heated Engineering team. \u00d7 Demonstrated expertise in mechanical design, ensuring product quality and meeting design specifications.",
          "domain": "Other",
          "from_and_to": "From: Jan, 2016 - To: Jan, 2017",
          "tenure": "1 year(s)",
          "tenure_in_months": 12,
          "title": "Mechanical Design Engineer",
          "was_promoted": false
      },
      {
          "company_name": "raytheon",
          "description": "Immersive Design Center Mechanical Engineer, Integrated Defense Systems \u00d7 Lead and coordinated design reviews with Raytheon teams and supply bases in Raytheon's Immersive Design Center, utilizing advanced CAD software and virtual reality environments. \u00d7 Represented Raytheon in high visibility presentations to executives, domestic and international customers, government officials, and military personnel. \u00d7 Developed and implemented new technologies in the Immersive Design Center, including a flexible collaboration solution that enhanced transmission capabilities. Awarded Six Sigma Specialist Certification upon successful completion of project.",
          "domain": "Other",
          "from_and_to": "From: Jan, 2015 - To: Jan, 2016",
          "tenure": "1 year(s)",
          "tenure_in_months": 12,
          "title": "Mechanical Engineer, Integrated Defense Systems",
          "was_promoted": false
      }
  ]
}

const roles = [];

const assessResume = async (timer=1000) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const result = resume1;
      result?.work_experience?.forEach((role, i) => {
        if (role?.company_name && result?.work_experience[i-1]?.company_name === role?.company_name) {
          console.log(i, role?.company_name);
          role.same_company = true;
        }
      });
      resolve(result);
    }, timer);
  })
)

const getAssessments = async (timer=600) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const result = assessments;
      resolve(result);
    }, timer);
  })
)

const getRoles = async (timer=600) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const result = roles;
      resolve(result);
    }, timer);
  })
)

const createRole = async (data, timer=600) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const result = { id: window?.crypto.randomUUID(), ...data };
      roles.push(result);
      resolve(roles);
    }, timer);
  })
)

const updateRole = async (data, timer=600) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const roleIndex = roles?.findIndex(item => item.id === data.id);
      const result = { ...roles[roleIndex],  ...data };
      roles[roleIndex] = result;
      console.log('YYY', data, result);
      resolve(roles);
    }, timer);
  })
)

const deleteRole = async (id, timer=600) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const roleIndex = roles?.findIndex(item => item.id === id);
      roles.splice(roleIndex, 1);
      resolve(roles);
    }, timer);
  })
)

export { assessResume, getAssessments, getRoles, createRole, updateRole, deleteRole }
            
