import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import NavBar from "../components/NavBar";
import WhiteButton from "../components/WhiteButton";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import StyledCheckbox from "../components/StyledCheckbox";
import { Pill } from "../components/ResumeAssesmentListItem";
import { FaRegCopy } from "react-icons/fa";
import { getNextResumeToQA, submitQAFeedback } from "../services";
import PageContainer from "../components/PageContainer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  text-align: left;
  .content-area {
    margin-top: 5rem;
    width: 100%;
    height: 100%;
    max-width: 72.5rem;
    padding: 0 2.5rem;
    box-sizing: border-box;
    flex: 1;
    font-weight: 400;
    font-family: Roboto;
    h2 {
      font-size: 1.7rem;
      font-weight: 500;
    }
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      margin-top: 2rem;
      font-weight: 500;
    }
    .clipboard-copy {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
      margin-bottom: -0.1rem;
      cursor: pointer;
      &:active {
        opacity: 0.5;
      }
    }
    .pill-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    .qa-feedback {
      border-top: 1px solid var(--light-greye4);
      margin: 3rem 0;
      .qa-feedback-wrapper {
        display: flex;
        gap: 1rem;
        margin: 1.5rem 0;
        span.checkbox-label {
          font-size: 1rem !important;
          font-weight: 400 !important;
        }
      }
      h4 {
        margin: 0 0 0.5rem;
        font-weight: 500;
      }
      textarea {
        margin: 0 0 1.5rem;
        min-width: 17rem;
        min-height: 4rem;
      }
      button {
        padding: 0.3rem 1rem;
      }
    }
    .employment-record {
      .employment-record-company {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      .employment-record-title {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
      .employment-record-from-to {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
      .employment-record-description {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
    .education-record {
      .education-record-institution {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      .education-record-degree {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
      .education-record-graduation-date {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
    .spinner-wrapper {
      width: 100%;
      height: 100%;
      min-height: calc(100vh - 15rem);
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
  @media screen and (max-width: 40rem) {
    .content-area {
      margin-top: 2rem;
    }
  }

`

const QAHelper = () => {
  const [data, setData]  = useState(null);
  const [isLoading, setIsLoading]  = useState(null);
  const loading = useRef(false);


  const getData = async () => {
    if (loading.current) return;
    try {
      setIsLoading(true);
      loading.current = true;
      const data = await getNextResumeToQA();
      if (data) {
        setData(data);
      } else {
        setData(false);
      }
    } catch (err) {
      console.log(err);
      setData(false);
    } finally {
      setIsLoading(false);
      loading.current = false;
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const copyText = () => {
    navigator.clipboard.writeText(data?.file_name);
  }

  const onQAFeedbackSubmitClick = async () => {
    const has_issues = document.querySelector('input[name="has_issues"]')?.checked;
    const notes = document.querySelector('textarea[name="notes"]')?.value; 
    try { 
      window.scrollTo(0,0);
      setIsLoading(true);
      await submitQAFeedback(data?.resume_parsing_result_id, has_issues, notes);
      await getData();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const renderContent = () => {
    if (isLoading) return <div className='spinner-wrapper'>
      <Spinner/>
    </div>
    if (!data) return <div className='spinner-wrapper'>
      No more resumes left to review
    </div>
    return <div className='resume'>
      <h3>File name</h3>
      <div className='filename'>{data?.file_name} 
      <FaRegCopy className='clipboard-copy' onClick={copyText}/></div>
      <h3>Employment</h3>
      {
        data?.employment_parsed_result.map((item, i) => <div className='employment-record' key={i}>
          <div className='employment-record-company'>{item.company_name}</div>
          <div className='employment-record-title'>{item.title}</div>
          <div className='employment-record-from-to'>{item.from_dt} - {item.to_dt}</div>
          <div className='employment-record-description'>{item.description}</div>
        </div>)
      }
      <h3>Education</h3>
      {
        data?.education_parsed_result.map((item, i) => <div className='education-record' key={i}>
        <div className='education-record-institution'>{item.institution_name}</div>
        <div className='education-record-degree'>{item.degree}, {item.major}</div>
        <div className='education-record-graduation-date'>{item.graduation_dt}</div>
      </div>)
      }
      <h3>Inference</h3>
      <div className="pill-wrapper">
      {
        data?.inference_result.map((item, i) => <Pill key = {i} className={item.color}>
        {item.label}
      </Pill>)
      }
      </div>
      <div className='qa-feedback'>
      <h3>QA Feedback</h3>
        <div className='qa-feedback-wrapper'>
          <StyledCheckbox 
            style={{marginTop: '0rem', marginBottom: '0rem'}}
            name="has_issues">
            Has Issues
          </StyledCheckbox>
        </div>
        <h4>Notes</h4>
        <textarea name="notes"/>
        <WhiteButton onClick={onQAFeedbackSubmitClick}>Submit</WhiteButton>
      </div>
    </div>
  }

  return (
    <PageContainer 
      selected={'qa-helper'}
      title={'Tools / QA Helper'}
      navGroup='TOOLS'
      hideNavPanel={false}
      hideNavBar={false}
    >
    <Container>
      <div className="content-area">
        {renderContent()}
      </div>  
    </Container>
  </PageContainer>
  );
};

export default QAHelper;