import React from "react";
import styled from 'styled-components';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { CopyBlock, dracula } from "react-code-blocks";
import PlainNavMenu from "../components/PlainNavMenu";
import { getDomain } from '../configuration';

const CODE_BLOCK = `  import requests

  topnerd_api = "https://api.topnerd.io"

  # Replace with your API key
  api_key = "your_api_key"

  # Replace with the path to the resume you want to process
  resume_path = "path/to/resume.pdf"

  endpoint = "/process-resume/basic"
  url = f"{topnerd_api}{endpoint}"
  headers = {"X-API-KEY": api_key}
  with open(resume_path, "rb") as resume_f:
      files = {"file": resume_f}
      response = requests.post(url, headers=headers, files=files)
      print(response.text)`;

const BASIC_RESPONSE = `{
  "located_in_us": true,
  "total_yoe": 1.5,
  "ic_yoe": 1.5,
  "manager_yoe": 0,
  "has_us_degree": true,
  "bs_in_cs": true,
  "bs_in_stem": true,
  "bootcamp_graduate": false,
  "num_internships": 0,
  "immigration_status": "US Citizen or GC Holder"
}`
const ADVANCED_RESPONSE = `{
  "last_three_roles": [
    {
      "domain": "BigTech",
      "company_name": "google",
      "tenure_in_months": 16
    },
    {
      "domain": "Startup",
      "company_name": "topnerd",
      "tenure_in_months": 23
    }
  ]
}`
const INFERENCE_RESPONSE = `{
  "job_hopper": true,
  "intern_to_ft": false,
  "was_promoted": false,
  "top_school_overseas": false
}`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
  .content-area {
    width: 100%;
    max-width: 80rem;
    padding: 0 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;

    .content {
      max-width: var(--max-content-width);
      box-sizing: border-box;
      .code-block {
        font-family: 'Fira Code', monospace;
        font-size: 1rem;
        font-size: 0.9rem;
        margin-bottom: 2rem;
      }
      h3 {
        margin-bottom: 2rem;
        font-size: 1.2rem;
      }
      p {
        margin: 1rem 0;
      }
      .code {
        font-weight: 600;
        font-family: 'Fira Code', monospace;
      }
      .steps {
        .step {
          margin-top: 1rem;
          ul {
            display: block;
            margin: 0.5rem 0;
            li {
              margin-top: 0.5rem;
              div {
                margin-top: 0.2rem;
                font-size: 0.8rem;
              }
            }
          }
          .link {
            cursor: pointer;
            color: var(--color-blue1);
            font-weight: 600;
          }
        }
      }
    }
  }
  @media screen and (max-width: 40rem) {
    .content-area {
      margin-top: 0;
    }
    .code-block {
      font-family: 'Fira Code', monospace;
      font-size: 0.7rem;
    }
  }
`

const Developers = () => {

  const openSwaggerDocs = async () => {
    window.open(`${await getDomain()}/docs`)
  }
  return (
    <>
    <Container className="App">
      <NavBar/>
      <div className="content-area">
        <div className="content"> 
          <h3>Getting Started with Topnerd API</h3>

          <p>This page will help you get started with Topnerd API. You'll be up and running in a jiffy!</p>
        
          <div className="separator"/>
          <p className="steps">
            Steps to use the API: <br/>
            <div className="step">
              1. Create a user account.
            </div>
            <div className="step">
              2. On the API Keys page, create an API key.
            </div>
            <div className="step">
              3. Start processing resumes using one of Topnerd's three API endpoints. Each endpoint provides different information:
                <ul>
                  <li>
                    Basic: <span className="code">/process-resume/basic</span><br/>
                    <div>Provides basic attributes parsed from the resume. e.g.: <em>Years of Experience</em>.</div>
                    <h5>Example Response</h5>
                    <div className="code-block">
                      <CopyBlock
                        text={BASIC_RESPONSE}
                        language="json"
                        theme={dracula}
                        codeBlock
                      />
                    </div>
                  </li>
                  <li>
                    Advanced: <span className="code">/process-resume/advanced</span><br/>
                    <div>Provides more complex attributes parsed from the resume. Also uses data sourced from elsewhere to elaborate on that data. e.g.: <em>Employer industry</em></div>
                    <h5>Example Response</h5>
                    <div className="code-block">
                      <CopyBlock
                        text={ADVANCED_RESPONSE}
                        language="json"
                        theme={dracula}
                        codeBlock
                      />
                    </div>
                  </li>
                  <li>
                    Inference: <span className="code">/process-resume/inference</span><br/>
                    <div>Provides attributes inferrred from the resume. e.g.: <em>if the candidate was promoted in any role</em></div>
                    <h5>Example Response</h5>
                    <div className="code-block">
                      <CopyBlock
                        text={INFERENCE_RESPONSE}
                        language="json"
                        theme={dracula}
                        codeBlock
                      />
                    </div>
                  </li>
                </ul>
            </div>
            <div className="step">
              4. Check out our <span className="link" onClick={openSwaggerDocs}>API documentation</span> for a full breakdown of each endpoint
            </div>
          </p>  

          <h3>Python Code Sample</h3>
          <div className="code-block">
            <CopyBlock
              text={CODE_BLOCK}
              language="python"
              theme={dracula}
              codeBlock
            />
          </div>
        
          <p>
            To receive other sets of attributes you can replace <span className="code">endpoint</span> with any of these
          </p>
          <p>
            <span className="code">/process-resume/advanced<br/> /process-resume/inference</span>
          </p>
        </div> 
      </div> 
    </Container>
    <Footer/>
    </>
  );
};

export default Developers;