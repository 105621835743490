
import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { searchCandidates, getCandidate } from "../services";
import { useAuth } from '../hooks';
import Spinner from "../components/Spinner";
import Select from 'react-select';
import PageContainer from "../components/PageContainer";
import HR from "../assets/images/hr.png";

const Container = styled.div`
    margin-top: 7rem;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: row;
    .content {
      flex: 1;
      position: relative;
      padding: 0 0.5rem;
      font-family: Roboto;
      font-weight: 400;
      label {
        font-size: 1rem;
      }
      .search-container {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;
        align-items: center;
        position: relative;
        width: 30rem;
        input {
          all: unset;
          padding: 0 0.5rem;
          height: 2rem;
          /* border-bottom-color: hsl(0, 0%, 80%) !important; */
          border-bottom: 1px solid var(--color-gray-2);
          width: 100%;
          &::-webkit-search-cancel-button {
            -webkit-filter: grayscale(100%); 
            filter: grayscale(100%);
            opacity: 0.6;
          }
        }
        .result-container {
          top: 100%;
          position: absolute;
          width: 100%;
          z-index: 1;
          background-color: hsl(0, 0%, 100%);
          border-radius: 4px;
          box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
          box-sizing: border-box;
          max-height: 300px;
          overflow-y: auto;
          margin-top: -1px;
          .search-item {
            padding: 0.5rem;
            &:hover {
              background-color: var(--color-gray-2);
            }
          }
        }
      }
    }
    .no-roles {
      font-size: 0.75rem;
      line-height: 1.5;
      margin-top: 0.5rem;
      font-weight: 400;
      color: var(--color-gray-1);
    }
    .filter-container {
      width: 14rem;
      margin-bottom: 2rem;
    }
    .spacer {
      flex: 1;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
    }
    .spinner {
      position: fixed;
      right: 0;
      top: 10rem;
      bottom: 0;
      left: 0;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    .candidate-info {
      margin-top: 1.5rem;
      font-family: Roboto;
      font-weight: 400;

      .candidate-info-item {
        margin-top: 0.5rem;
        label {
          font-weight: 500;
        }
        a {
          color: black !important;
          &.hr-link {
            border: 1px solid black;
            border-radius: 2px;
            display: inline-block;
            margin-right: 0.5rem;
            padding: 1px 0 0 1px;
          }
        }
      }
    }
`


const Search = () => {
  const [ searchResults, setSearchResults ] = useState([]);
  const [ searchText, setSearchText ] = useState('');
  const [ isCandidateDataLoading, setIsCandidateDataLoading ] = useState(null);
  const [ selectedCandidate, setSelectedCandidate ] = useState(null);
  const [ showSearchResults, setShowSearchResults ] = useState(false);
  const isLoading = useRef(false);
  const currentSearchText = useRef('');
  const tm = useRef(null);
  useAuth();

  useEffect(() => {
    const search = async (text) => {
      isLoading.current = true;
      const res = await searchCandidates(text);
      if (text === currentSearchText.current) {
        setSearchResults(res?.search_results);
        isLoading.current = false;
      } else if (currentSearchText.current < 3) {
        setSearchResults([]);
        isLoading.current = false;
      } else {
        search(currentSearchText.current);
      }
    }
    if (searchText && searchText.length > 2) {
      if (!isLoading.current) {
        clearTimeout(tm.current);
        tm.current = setTimeout(() => {
          search(searchText);
        }, 300)
      } 
    } else {
      setSearchResults([]);
    }
  }, [searchText])



  const onChange = (e) => {
    if (selectedCandidate && e.target.value !== selectedCandidate.name) {
      setSelectedCandidate(null);
    }
    setSearchText(e.target.value);
    currentSearchText.current = e.target.value;
  }

  const onCandidateSelect = async (item) => {
    if (item?.[0]) {
      try {
        isLoading.current = true;
        setSearchText(item?.[1]);
        currentSearchText.current = item?.[1];
        setIsCandidateDataLoading(true);
        setShowSearchResults(false);
        const res = await getCandidate(item[0]);
        setSelectedCandidate(res);
      } catch (err) {

      } finally {
        setIsCandidateDataLoading(false);
        isLoading.current = false;
      }

    }
  }

  const onFocus = () => {
    setShowSearchResults(true);
  }

  const onBlur = (event) => {
    if (event?.relatedTarget?.className !== "search-item") {
      setShowSearchResults(false);
    }
  }

  const renderCandidateInfo = () => {
    if (selectedCandidate) {
      return (
        <div className="candidate-info">
          <div className="candidate-info-item">
            <a 
              className="hr-link"
              href={`/honest-resume?resume_id=${selectedCandidate?.resume_id}`} 
              rel="noreferrer" 
              target="_blank">
                <img 
                  src={HR}
                  alt={"Honest Resume"}
                  style={{
                    margin: "0 0.2rem",
                    height: "0.8rem"
                  }}
                />
            </a>
            {selectedCandidate?.name}
          </div>
          {
            selectedCandidate?.phone_number &&
            <div className="candidate-info-item">
              <label>Phone:</label> {selectedCandidate?.phone_number}
            </div>
          }
          {
            selectedCandidate?.email &&
            <div className="candidate-info-item">
              <label>Email: </label> 
              <a target="__blank"
                href={`mailto:${selectedCandidate?.email}`}>{selectedCandidate?.email}</a>
            </div>
          }
          {
            selectedCandidate?.linkedin_url &&
            <div className="candidate-info-item">
              <a target="__blank"
                href={selectedCandidate?.linkedin_url}>Linkedin profile</a>
            </div>
          }
          {
            selectedCandidate?.topnerd_s3_url &&
            <div className="candidate-info-item">
              <a target="__blank"
                href={selectedCandidate?.topnerd_s3_url}>Download resume</a>
            </div>
          }
        </div>
      )
    }
    return null;
  }

  return (
    <PageContainer title={'Search'} selected={'search'}>
      <Container>  
        <div className="content">
          <div 
            className="search-container">
            <input 
              name="api_key" 
              type="search"
              autoFocus
              placeholder="Search by candidate name"
              autoComplete="off"
              value={searchText || ''} 
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={isCandidateDataLoading}/>
             {
              showSearchResults &&
              <div className="result-container">
                {
                  searchResults.map(item => (
                  <div key={item[0]} 
                    className="search-item"
                    tabIndex="0"
                    onClick={() => onCandidateSelect(item)}>
                    {item[1]}
                  </div>
                  ))
                }
              </div> 
             }
          </div>
          {
            renderCandidateInfo()

          }
        </div>   
      </Container>
    </PageContainer>
  );
};

export default Search;