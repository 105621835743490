
import React from "react";
import styled from 'styled-components';
import Select from 'react-select';

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    font-size: 0.9rem;
  
    .month-select {
      max-width: 7rem;
      min-width: 7rem;
      .month__control {
        border: 1px solid var(--color-gray0);
        box-shadow: none;
        height: 2rem;
        color: black;
        font-weight: 400;
        font-family: Roboto;
        padding: 0 0.5rem;
        .month__value-container {
          padding: 0;
        }
        .month__placeholder {
          color: var(--color-gray1);
          font-size: 0.9em;
        }
        .month__single-value {
          color: black; 
          font-weight: 400 !important;
          font-family: Roboto;
          font-size: 0.9em;
        }
        .month__indicators {
          .month__indicator-separator {
            display: none;
          }
          .month__dropdown-indicator {
            color: black !important;
            font-weight: 400 !important;
            font-family: Roboto;
            opacity: 0.5;
            padding: 0.5rem 0;
          }
        }
      }
      .month__menu {
        margin-top: 0 !important;
        padding: 0 !important;
        z-index: 999 !important;
        .month__menu-list {
          padding: 0 !important;
          z-index: 999 !important;
          .month__option {
            color: black;
            height: 2rem;
            font-weight: 400 !important;
            font-family: Roboto;
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
            padding: 0.5rem;
            font-size: 0.9em;
            &.month__option--is-selected {
              color: white;
            }
            &.month__option--is-focused {
              
            }
          }
        }
      }
    }
`

const MONTHS = [
  {label: 'January', value: 'January'}, 
  {label: 'February', value: 'February'}, 
  {label: 'March', value: 'March'}, 
  {label: 'April', value: 'April'},
  {label: 'May', value: 'May'},
  {label: 'June', value: 'June'},
  {label: 'July', value: 'July'},
  {label: 'August', value: 'August'},
  {label: 'September', value: 'September'},
  {label: 'October', value: 'October'},
  {label: 'November', value: 'November'},
  {label: 'December', value: 'December'},
];


const MonthSelect = ({value, onChange}) => {
  const onMonthSelect = (item) => {
    onChange(item.value);
  }
  const customFilter = (option, searchText) => {
    if (
      option.data.label.toLowerCase().startsWith(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container>
      <Select
        className="month-select"
        classNamePrefix="month" 
        placeholder="Month" 
        options={MONTHS}
        onChange={onMonthSelect}
        isSearchable={true}
        maxMenuHeight="10rem"
        filterOption={customFilter}
        value={MONTHS.find(c => c.value === value) || null}
      />
    </Container>
  );
}

export default MonthSelect;