import React from "react";
import styled from 'styled-components';
import { useNavigate } from 'react-router';

const Container = styled.div`
  min-width: 12rem;
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
  div {
    display: inline-block;
    text-decoration: none;
    color: black !important;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 3px;
    &.selected {
      background-color: var(--color-gray-2);
    }
    &:hover {
      /* font-size: 1.25rem;
      line-height: 1.75rem; */
      /* font-weight: 400; */
    }
  }
`

const RoleNavMenu = ({ selected }) => {
  const navigate = useNavigate();
  const getClassname = (id) => {
    if (selected === id) return 'selected';
    return '';
  }
  return <Container className="role-nav-menu">
      <div id="roles" className={getClassname("roles")} onClick={() => navigate("/roles")}>Roles</div>
      <div id="resume-assessment" className={getClassname("resume-assessment")}  onClick={() => navigate("/resume-assessment")}>Resume Assessment</div>
      <div id="ats" className={getClassname("ats")} onClick={() => navigate("/ats")}>ATS Integration</div>
      <div id="billing" className={getClassname("billing")} onClick={() => navigate("/billing")}>Billing</div>
  </Container>
}

export default RoleNavMenu;