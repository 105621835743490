
import React from "react";
import styled from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: 25rem;
  border-radius: 0.2rem;
  margin-bottom: 0.5rem;
  align-items: flex-start;
  padding: 1.25rem 0 0.5rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  div.form-group {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
  }
  div.form-item {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
    .error-message {
      color: var(--color-red-1) !important;
      font-size: 0.9em;
      align-self: flex-start;
      margin-top: -0.5rem;
    }
    input.simple-input {
      height: 2.2rem;
      width: 100%;
      padding: 0 0 0 0.5rem;
      border: 1px solid var(--color-gray0);
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      box-sizing: border-box;
      &::placeholder {
        color: var(--color-gray1);
      }
    }
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }    
`

const PersonalInfoForm = ({ data, error, onChange }) => {
  const onInputChange = (e) => {
    const _data = {...data};
    _data[e.target.name] = e.target.value;
    onChange(_data);
  }
  
  return (
    <Container>
      <div className="form-item">
        <input 
          name="first_name"
          value={data?.first_name}
          className="simple-input"
          placeholder="First name*" 
          onChange={onInputChange}/>
          {
            error?.first_name && 
            <div className="error-message">{error?.first_name}</div>
          }
      </div>
      <div className="form-item">
        <input 
          name="last_name"
          value={data?.last_name}
          className="simple-input"
          placeholder="Last name*" 
          onChange={onInputChange}/>
          {
            error?.last_name && 
            <div className="error-message">{error?.last_name}</div>
          }
      </div>     
      <div className="form-item">
        <input 
          name="email"
          value={data?.email}
          className="simple-input"
          placeholder="Email*" 
          onChange={onInputChange}/>
          {
            error?.email && 
            <div className="error-message">{error?.email}</div>
          }
      </div> 
      <div className="form-item">
        <input 
          name="phone_no"
          value={data?.phone_no}
          className="simple-input"
          placeholder="Phone No" 
          onChange={onInputChange}/>
          {
            error?.phone_no && 
            <div className="error-message">{error?.phone_no}</div>
          }
      </div> 
      <div className="form-item">
        <input 
          name="linkedin_url"
          value={data?.linkedin_url}
          className="simple-input"
          placeholder="LinkedIn Url" 
          onChange={onInputChange}/>
          {
            error?.linkedin_url && 
            <div className="error-message">{error?.linkedin_url}</div>
          }
      </div>   
      <div className="form-item">
        <input 
          name="location_text"
          value={data?.location_text}
          className="simple-input"
          placeholder="Location" 
          onChange={onInputChange}/>
          {
            error?.location_text && 
            <div className="error-message">{error?.location_text}</div>
          }
      </div>   
    </Container>
  );
}

export default PersonalInfoForm;