import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import StyledModal from "./StyledModal";
import BlackButton from "./BlackButton"; 
import WhiteButton from "./WhiteButton";
import { contactUs } from "../services";
import { p2r } from "../utils";


var mobile = require('is-mobile');
const IS_MOBILE = mobile();


const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  h3 {
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2;
  }
      
  .select-buttons {
      display: flex;
      gap: 1rem;
      width: 100%;
      justify-content: flex-end;
      margin-top: ${p2r(38)};
      button {
        width: auto;
        padding: 0.625rem 1,25rem;
      }
    }
  .message-received {
    flex: 1;
    font-size: 1.5em;
    margin: 2rem 0;
    line-height: 2rem;
    text-align: center;
  }
  .contact-form {
    width: 100%;
    position: relative;
    margin: 0.5rem 0;
    .form-item-group {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }
    .form-item {
      flex: 1;
      position: relative;
      margin-top: 0.9rem;
      padding-top: 0.85rem;
      .error-message {
        position: absolute;
        bottom: -1.2rem;
        font-size: 0.8em;
        left: 0;
        color: var(--color-red0);
        font-weight: 400;
      }
      input:-webkit-autofill {
        font-size: 1.25rem;
        padding: 0;
        line-height: 1.75rem;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
      }
      input, textarea {
        border: none;
        outline: none;
        all: unset;
        display: block;
        width: 100%;
        border-bottom: 1px solid var(--color-gray-2);
        height: 3rem;
        padding: 0;
        margin-top: 1.5rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        box-sizing: border-box;
        &::placeholder {
          color: var(--color-gray-0);
          font-size: 1.25rem;
          padding: 0;
        }
        &.error {
          border-bottom: 1px solid var(--color-red-1);
        }
      }
      textarea {
        margin-top: 1rem;
        height: auto; 
        resize: vertical; 
      }
      textarea[name="description"] {
        min-height: 3rem;
      }

      .floating-label {
        position: absolute;
        pointer-events: none;
        color: var(--color-gray1);
        font-size: 1.25em;
        left: 0;
        bottom: 1.5rem;
        font-weight: normal;
        padding: 0;
      }

      textarea:focus~.floating-label,
      textarea:not(:placeholder-shown)~.floating-label,
      input:focus~.floating-label,
      input:not(:placeholder-shown)~.floating-label,
      input:-webkit-autofill~.floating-label
      {
        top: 0.5rem;
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        padding: 0;
      }
    }
  }
  button {
    width: 100%;
    height: 2.5rem;
    text-align: center;
    justify-content: center;
    font-size: 1rem;
  }
  &.mobile {
    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .message-received {
      flex: 1;
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }
`

const STATE = {
  role_name: { value: '', error: false }
};

const CreateRoleModal = ({ isOpen, onClose, onSubmit, roles }) => {
  const [formData, setFormData] = useState(structuredClone(STATE));

  useEffect(() => {
    if (isOpen) {
      setFormData(structuredClone(STATE));
    }
  }, [isOpen])


  const validateFormData = (data) => {
    const validatedData = structuredClone(formData);
    let isValid = true;
    const roleNames = roles.map(item => item.title);
    const name = validatedData['role_name'].value.trim();
    if(name === '') {
      validatedData['role_name'].error = 'The name cannot be empty';
      isValid = false;
    } else if (roleNames.includes(name)) {
      validatedData['role_name'].error = 'Role by this name exists already';
      isValid = false;
    } else {
      validatedData['role_name'].error = false;
    }
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = () => {
    setFormData({
      role_name: { value: '', error: false }
    });
  }

  const onInputChange = (e) => {
    const data = {...formData};
    data[e.currentTarget.name].value = e.currentTarget.value;
    data[e.currentTarget.name].error = false;
    setFormData(data);
  }

  const onSubmitClick = () => {
    if (validateFormData(formData)) {
      clearFormData();
      onSubmit(formData.role_name.value);
    }  
  }

  return (
    <StyledModal 
      onClose={onClose} 
      isOpen={isOpen}
      hideCrossButton={true}
      modalStyle={{
        maxWidth: IS_MOBILE ? '90%' : 'auto',
        borderRadius: p2r(12),
        width: '28rem',
        minWidth:  IS_MOBILE ? 'auto' : '28rem',
        padding:'1.5rem 1.5rem 2rem',
        top: '40%',
        transform: 'translate(-50%, -50%)'

      }}>
      <Container className={IS_MOBILE ? "mobile" : ""}>
        <h3>Create Role</h3>
        <form className="contact-form">
          <div className="form-item">
            <input 
              placeholder="   "
              name="role_name"
              type="text"
              onChange={onInputChange}
              className={formData?.role_name?.error ? 'error' : ''}
            />
            <label className="floating-label">Name</label>
            {
              formData?.role_name?.error &&
              <div className="error-message">{formData?.role_name?.error}</div>
            } 
          </div>
        </form>
        <div className="select-buttons">
          <WhiteButton onClick={onClose}>Cancel</WhiteButton>
          <BlackButton onClick={onSubmitClick}>Submit</BlackButton>
        </div>      
      </Container>  
    </StyledModal>
  );
}

export default CreateRoleModal;