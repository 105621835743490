import React from "react";
import styled from 'styled-components';
import { SpinnerCircularFixed } from 'spinners-react';

const PieLoader = styled.div`
  width: ${props => props.$size};
  height: ${props => props.$size};
  background-image: conic-gradient(black 0%, black ${props => props.$percent}%, var(--color-gray-1) ${props => props.$percent}%, var(--color-gray-1) 100%);
  border-radius: 50%;
`

const AnimatedPieLoader = styled.div`
  width: ${props => props.$size};
  height: ${props => props.$size};
  background-image: conic-gradient(black 0%, black ${props => props.$percent}%, var(--color-gray-1) ${props => props.$percent}%, var(--color-gray-1) 100%);
  border-radius: 50%;
  -webkit-animation:spin 3s linear infinite;
  -moz-animation:spin 3s linear infinite;
  animation:spin 3s linear infinite;
  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
  }
  @-webkit-keyframes spin { 
      100% { -webkit-transform: rotate(360deg); } 
  }
  @keyframes spin { 
      100% { 
          -webkit-transform: rotate(360deg); 
          transform:rotate(360deg); 
      } 
  }
`

const HorizontalSpinner = styled.div`
  display: flex;
  gap: 0.5rem;
  font-family: Roboto;
  font-weight: 400;
  font-size: 0.9rem;
  align-items: center;
`

const NestedSpinner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  >span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: Roboto;
    font-weight: 500;
    font-size: 0.5rem;
    line-height: 1;
    transform: translate(-50%, -50%);
  }
`

const PercentLoader =  ({
  view=0,
  size='1.5rem', 
  percent=0,
  ...props
}) =>  {
  const render = () => {
    if (view === 0) return (
      <SpinnerCircularFixed 
        size={size} 
        thickness={250} 
        speed={100} 
        color="rgba(0, 0, 0, 0.4)" 
        secondaryColor="rgba(0, 0, 0, 0.4)" />
    );
    if (view === 1) return (
      <PieLoader 
        $size={size} 
        $percent={percent}
        {...props}/>
    );
    if (view === 2) return (
      <AnimatedPieLoader 
        $size={size} 
        $percent={percent}
        {...props}/>
    );
    if (view === 3) return (
      <HorizontalSpinner>
        <span>{percent}%</span>
        <SpinnerCircularFixed 
          size={size} 
          thickness={250} 
          speed={100} 
          color="rgba(0, 0, 0, 0.4)" 
          secondaryColor="rgba(0, 0, 0, 0.4)" />
      </HorizontalSpinner>
    );
    if (view === 4) return (
      <NestedSpinner>
        <SpinnerCircularFixed 
          size={'2rem'} 
          thickness={250} 
          speed={100} 
          color="rgba(0, 0, 0, 0.4)" 
          secondaryColor="rgba(0, 0, 0, 0.4)" />
          <span>{percent}%</span>
      </NestedSpinner>
    );
  }
  return render();
}

export default PercentLoader;