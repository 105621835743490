import React from "react";
import styled from 'styled-components';

const Container = styled.div`
  min-height: 3rem;
  border-bottom: 1px solid var(--color-gray0);
  padding-bottom: 1rem;
  margin-top: -1rem;
  >label {
    display: block;
    font-size: 0.8rem;
    font-weight: 600;
    color: black;
    margin-bottom: 0.5rem;
  }
  .styled-radio-container {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    margin-top: 0.3rem;
    &.disabled {
      opacity: 0.3;
    }
    input.styled-radio[type='radio'] {
      appearance: none;
      -webkit-appearance: none;
      /* For a circular appearance we need a border-radius. */
      border-radius: 50%;
      /* The background will be the radio dot's color. */
      background: white;
      /* The border will be the spacing between the dot and the outer circle */
      border: 0.25rem solid white;
      /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
      box-shadow: 0 0 0 1px black;
      width: 1rem !important;
      height: 1rem !important;
      min-height: 1rem !important;
    }
    input.styled-radio[type='radio']:checked {
      background: black;
    }
    label {
      font-weight: 500;
      font-size: 0.8rem;
      margin-top: 0.2rem;
    }
  }
`

const StyledRadioGroup = ({ label, name, value, onChange, disabledOptions, options, ...props }) => {
  return (
    <Container {...props}>
      {
        label && <label>{label}</label>
      }
      {
        options.map((item, i) => {
          const disabled = disabledOptions?.includes(item.value);
          const className = disabled ? "styled-radio-container disabled" : "styled-radio-container";
          return (<div key={i} className={className}>
            <input 
              type="radio" 
              className="styled-radio"
              id={name+item.value} 
              name={name} 
              value={item.value} 
              checked={item.value === value}
              onChange={onChange}
              disabled={!!disabled}
            />
            <label htmlFor={name+item.value}>{item.name}</label>
          </div>)
        })
      }
  </Container>
  );
}

export default StyledRadioGroup;