
import React from "react";
import styled from 'styled-components';
import MonthSelect from "./MonthSelect";
import StyledCheckbox from "./StyledCheckbox";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: 45rem;
  border-radius: 0.2rem;
  margin-bottom: 0.5rem;
  align-items: flex-start;
  padding: 1.25rem 0 0.5rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  div.form-group {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
  }
  div.form-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    .error-message {
      color: var(--color-red-1) !important;
      font-size: 0.9em;
      align-self: flex-start;
      margin-top: -0.5rem;
    }
    .currently-employed {
      margin-top: 0 !important;
      span.checkbox-label {
        font-size: 0.9rem;
        font-weight: 400;
      }
    }
    input.simple-input {
      height: 2.2rem;
      width: 100%;
      padding: 0 0 0 0.5rem;
      border: 1px solid var(--color-gray0);
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      box-sizing: border-box;
      &::placeholder {
        color: var(--color-gray1);
      }
    }
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    textarea.simple-input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid var(--color-gray0);
      height: 6rem;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      box-sizing: border-box;
      &::placeholder {
        color: var(--color-gray1);
        font-family: "Roboto", sans-serif;
        font-weight: 400;
      }
    }
  }    
`
const MONTHS = [
  'January', 
  'February', 
  'March', 
  'April', 
  'May', 
  'June', 
  'July', 
  'August', 
  'September', 
  'October', 
  'November', 
  'December'];

const YEAR = new Date().getFullYear();
const MONTH =  MONTHS[Number(new Date().getMonth())];

const ExperienceForm = ({ data, error, onChange }) => {
  
  const onFromMonthChange = (value) => {
    const experience = {...data};
    experience.dates.from_month = value;
    onChange(experience);
  }

  const onToMonthChange = (value) => {
    const experience = {...data};
    experience.dates.to_month = value;
    if (experience.dates.to_month !== MONTH) {
      experience.is_currently_employed = false;
    }
    onChange(experience);
  }

  const onInputChange = (e) => {
    const experience = {...data};
    if (e.target.name === "from_year") {
      experience.dates.from_year = e.target.value;
    } else if (e.target.name === "to_year") {
      experience.dates.to_year = e.target.value;
      if (experience.dates.to_year !== YEAR) {
        experience.is_currently_employed = false;
      }
    } else {
      experience[e.target.name] = e.target.value;
    }
    onChange(experience);
  }

  const onCurrentlyEmployedChange = (e) => {
    const experience = {...data};
    experience.is_currently_employed = e.target.checked;
    if (experience.is_currently_employed) {
      experience.dates.to_month = MONTH;
      experience.dates.to_year = YEAR;
    }
    onChange(experience);
  }
  
  return (
    <Container>
      <div className="form-group">
        <div className="form-item" >
          <label>From:</label> 
          <MonthSelect value={data?.dates?.from_month} onChange={onFromMonthChange}/>
          <input 
            name="from_year"
            value={data?.dates?.from_year}
            className="simple-input"
            style={{width: '3rem'}}
            onChange={onInputChange} 
            placeholder="YYYY" 
            type="number" 
            step="1" 
            min="1900"/>
        </div>
        <div style={{flex: 1}}/>
        <div className="form-item">
          <label>To:</label> 
          <MonthSelect value={data?.dates?.to_month} onChange={onToMonthChange}/>
          <input 
            name="to_year"
            value={data?.dates?.to_year}
            className="simple-input"
            style={{width: '3rem'}}
            onChange={onInputChange}  
            placeholder="YYYY" 
            type="number" 
            step="1" 
            min="1900"/>
           {/*  */}
        </div>
        <div className="form-item">
        <StyledCheckbox 
          checked={data?.is_currently_employed}
          onChange={onCurrentlyEmployedChange}
          className="currently-employed">Currently employed</StyledCheckbox>
        </div>
      </div>
      <div className="form-item" style={{width: '20rem', marginTop: '1rem', flexDirection: "column"}}>
        <input 
          name="topnerd_organization"
          value={data?.topnerd_organization}
          className="simple-input"
          placeholder="Company Name*" 
          onChange={onInputChange}/>
        {
          error?.topnerd_organization && 
          <div className="error-message">{error?.topnerd_organization}</div>
        } 
      </div>
      <div  className="form-item" style={{width: '20rem', marginTop: '1rem'}}>
        <input 
          name="topnerd_job_title"
          value={data?.topnerd_job_title}
          className="simple-input"
          placeholder="Title" 
          onChange={onInputChange}/>
      </div>
      <div  className="form-item" style={{width: '20rem', marginTop: '1rem'}}>
        <input 
          name="location_text"
          value={data?.location_text}
          className="simple-input"
          placeholder="Location" 
          onChange={onInputChange}/>
      </div>    
      <div  className="form-item" style={{width: '100%', marginTop: '1rem'}}>
        <textarea 
          name="job_description"
          value={data?.job_description}
          className="simple-input"
          placeholder="Description" 
          onChange={onInputChange}/>
      </div>         
    </Container>
  );
}

export default ExperienceForm;