import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import StyledModal from "./StyledModal";
import BlackButton from "./BlackButton";
import { completeFirebaseEmailSignup } from "../services/firebase";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  h3 {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
    text-align: center;
  }
  .details {
    flex: 1;
    font-size: 1.5em;
    margin: 2rem 0;
    line-height: 2rem;
    text-align: center;
    span {
      line-height: 2;
      font-weight: 600;
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
  .contact-form {
    width: 100%;
    padding: 0;
    position: relative;   
    margin: 1rem 0 2rem;
    .form-item-group {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }
    .form-item {
      flex: 1;
      position: relative;
      margin-top: 2rem;
      padding-top: 0.85rem;
      .email-error-message {
        position: absolute;
        bottom: -1.2rem;
        font-size: 0.8em;
        left: 0;
        color: var(--color-red0);
        font-weight: 400;
      }
      input, textarea {
        /* &:active {
          padding: 0;
        } */
        border: none;
        outline: none;
        all: unset;
        display: block;
        width: 100%;
        border-bottom: 1px solid var(--color-gray-2);
        height: 3rem;
        padding: 0;
        margin-top: 0.5rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        box-sizing: border-box;
        &::placeholder {
          color: var(--color-gray-0);
          font-size: 1.25rem;
          padding: 0;
        }
        &.error {
          border-bottom: 1px solid var(--color-red-1);
        }
      }

      .floating-label {
        position: absolute;
        pointer-events: none;
        color: var(--color-gray1);
        font-size: 1.25em;
        left: 0;
        bottom: 1.5rem;
        font-weight: normal;
        padding: 0;
      }

      textarea:focus~.floating-label,
      textarea:not(:placeholder-shown)~.floating-label,
      input:focus~.floating-label,
      input:not(:placeholder-shown)~.floating-label
      {
        top: 0;
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        padding: 0;
      }
    }
  }
  button {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .controls {
    display: flex;
    gap: 1rem;
  }
`

const CompleteTrialModal = ({ isOpen, data, onSubmit, onClose }) => {
  const [formData, setFormData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [differentEmail, setDifferentEmail] = useState(false);
  const [alreadyUsed, setAlreadyUsed] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDifferentEmail(false);
      setAlreadyUsed(false);
      setLoading(false);
      setFormData({
        email: { value: data?.email || '', error: false },
        fullname: { value: data?.fullname || '', error: false },
        title: { value: data?.title || '', error: false },
        company: { value: data?.company || '', error: false },
        password: { value: data?.password || '', error: false },
        confirmPassword: { value: data?.confirmPassword || '', error: false },
      })
    }
  }, [data, isOpen])

  const validateFormData = (data) => {
    const validatedData = {...data};
    
    let isValid = true;

    Object.keys(validatedData).forEach(key => {
      if (key === 'email') {
        if (validateEmail(validatedData[key].value)) {
          validatedData['email'].error = false;
        } else {
          validatedData['email'].error = 'Please enter a valid work email';
        } 
      } else if(validatedData[key].value.trim() === '') {
        validatedData[key].error = 'This field is required';
        isValid = false;
      } else {
        validatedData[key].error = false;
      }
      const password = validatedData['password'].value.trim();
      const confirmPassword = validatedData['confirmPassword'].value.trim();
      if (password !== confirmPassword) {
        validatedData['confirmPassword'].error = 'The password confirmation does not match';
        isValid = false;
      }
    });
    setFormData(validatedData);
    return isValid;
  } 

  const onInputChange = (e) => {
    const data = {...formData};
    const name  = e.currentTarget.name;
    const value = e.currentTarget.value;
    data[name].value = value;
    data[name].error = false;
    setFormData(data);
  }

  const onSubmitClick = async () => {
    if (validateFormData(formData)) {
      setLoading(true);
      const email = formData?.email?.value || '';
      const password = formData?.password?.value || '';
      const fullName = formData?.fullname?.value || '';
      const companyName = formData?.company?.value || '';
      const title = formData?.title?.value || '';
      const user = await completeFirebaseEmailSignup(email, password, fullName, companyName, title);
      if (user === "alreadyUsed") {
          setLoading(false);
          setAlreadyUsed(true);
      } else if (user === "differentEmail") {
          setLoading(false);  
          setDifferentEmail(true);
      } else {
          setLoading(false);  
          console.log(`You are now logged in as: ${user.email}`);
          //window.location.href = '/resume-assessment';
          onClose();
      }
    }  
  }

  const onModalClose = () => {
    onClose();
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  const render = () => {
    if (alreadyUsed) return <>
      <h3>An account with this email address already exists</h3>
      <div className="details">
        Please try to log in with your email and password.<br/>
        Contact us if you have any questions.
      </div>
      <BlackButton onClick={onModalClose}>Close</BlackButton>
    </>
    if (differentEmail) return <>
      <h3>Wrong email address</h3>
      <div className="details">
        The email provided does not match the sign-in email address. 
        Please contact us if you have any questions.
      </div>
      <BlackButton onClick={onModalClose}>
          Close
      </BlackButton>
    </>
    return <>
      <h3>Complete the form to start your free trial</h3>
      <form className="contact-form">
        <div className="form-item" style={{maxWidth: '100%'}}>
          <input 
            placeholder="   "
            name="email"
            type="text"
            disabled={true}
            onChange={onInputChange}
            value={formData?.email?.value || ''}
            className={formData?.email?.error ? 'error' : ''}
          />
        <label className="floating-label">Work email address</label>
        {
          formData?.email?.error &&
          <div className="email-error-message">{formData?.email?.error}</div>
        } 
        </div>
        <div className="form-item" style={{maxWidth: '100%'}}>
          <input 
            placeholder="   "
            name="fullname"
            type="text"
            onChange={onInputChange}
            value={formData?.fullname?.value || ''}
            className={!!formData?.fullname?.error ? 'error' : ''}
          />
        <label className="floating-label">Full Name</label>
        {
          formData?.fullname?.error &&
          <div className="email-error-message">{formData?.fullname?.error}</div>
        } 
        </div>
        <div className="form-item" style={{maxWidth: '100%'}}>
          <input 
            placeholder="   "
            name="title"
            type="text"
            onChange={onInputChange}
            value={formData?.title?.value || ''}
            className={!!formData?.title?.error ? 'error' : ''}
          />
        <label className="floating-label">Title</label>
        {
          formData?.title?.error &&
          <div className="email-error-message">{formData?.title?.error}</div>
        } 
        </div>
        <div className="form-item" style={{maxWidth: '100%'}}>
          <input 
            placeholder="   "
            name="company"
            type="text"
            onChange={onInputChange}
            value={formData?.company?.value || ''}
            className={!!formData?.company?.error ? 'error' : ''}
          />
        <label className="floating-label">Company Name</label>
        {
          formData?.company?.error &&
          <div className="email-error-message">{formData?.company?.error}</div>
        } 
        </div>
        <div className="form-item" style={{maxWidth: '100%'}}>
          <input 
            placeholder="   "
            name="password"
            type="password"
            autoComplete="new-password"
            onChange={onInputChange}
            value={formData?.password?.value || ''}
            className={!!formData?.password?.error ? 'error' : ''}
          />
        <label className="floating-label">Password</label>
        {
          formData?.password?.error &&
          <div className="email-error-message">{formData?.password?.error}</div>
        } 
        </div>
        <div className="form-item" style={{maxWidth: '100%'}}>
          <input 
            placeholder="   "
            name="confirmPassword"
            type="password"
            autoComplete="new-password"
            onChange={onInputChange}
            value={formData?.confirmPassword?.value || ''}
            className={!!formData?.confirmPassword?.error ? 'error' : ''}
          />
        <label className="floating-label">Confirm Password</label>
        {
          formData?.confirmPassword?.error &&
          <div className="email-error-message">{formData?.confirmPassword?.error}</div>
        } 
        </div>
      </form>
      <BlackButton 
        onClick={onSubmitClick} 
        disabled={!!formData?.email?.error}>Submit
      </BlackButton>
    </>
  }

  return (
    <StyledModal 
      onClose={onModalClose} 
      isOpen={isOpen}
      loading={isLoading}
      modalStyle={{
        width: '42rem',
        minWidth: '42rem',
        padding:'3rem 2rem 3rem',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
      <Container>
        {render()}
      </Container>  
    </StyledModal>
  );
}

export default CompleteTrialModal;