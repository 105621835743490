import React from 'react';
import styled from 'styled-components';
import { PiFolders } from 'react-icons/pi';

const Button = styled.button`
  all: unset;
  background-color: var(--color-blue4);
  color: var(--color-blue1);
  padding: 0 1rem;
  height: 3.5rem;
  width: 23rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  border: 1px black dashed;
  border-radius: 0.5rem;
  cursor: pointer;
  box-sizing: border-box;
  span:last-of-type {
    display: none;
  }
  @media screen and (max-width: 40rem) {
    width: auto;
    span:first-of-type {
      display: none;
    }
    span:last-of-type {
      display: inline;
    }
  }
`;
const FileSelectButton = ({ children, value, onChange, disabled, accept, ...props }) => {
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    onChange(fileUploaded);
  };
  return (
    <>
      <Button onClick={handleClick} {...props}>
        <PiFolders color={`var(--color-blue1)`} size={'1.5rem'}/>
        <span>&nbsp;&nbsp;Click here to browse</span>
        <span>&nbsp;&nbsp;Browse</span>
      </Button>
      <input type="file"
             accept={accept || '*'}
             ref={hiddenFileInput}
             onChange={handleChange}
             style={{display:'none'}} 
      /> 
    </>
  );
}
export default FileSelectButton;