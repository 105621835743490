
import React, { useState } from "react";
import styled from 'styled-components';

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.9rem;
    position: relative;
    .keywords-container {
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-overflow-scrolling: touch;
      position: relative;
      overflow: hidden;
      padding: 0;
      box-sizing: border-box;
    }

    input, textarea {
      padding: 0 0 0.2rem !important;
      &:active {
        padding: 0 0.5rem;
      } */
      border: none;
      outline: none;
      display: block;
      width: 100%;
      border: 1px solid var(--color-gray-2);
      min-height: 3rem;
      font-size: 1.25rem;
      box-sizing: border-box;
      margin-top: 0 !important;
      &[type="text"], &[type="number"] {
        all: unset;
        display: block;
        width: 100%;
        line-height: 1.75rem;
        min-height: 3rem;
        border: 1px solid var(--color-gray-2);
      }
      &::placeholder {
        padding: 0 !important;
        pointer-events: none;
        color: var(--color-gray1) !important;
        font-weight: 400 !important;
        font-size: 1rem !important;
      }
      &.error {
        border-bottom: 1px solid var(--color-red-1);
      }
    }
`
const Keyword = styled.div`
  background: white;
  border: 1px solid black;
  display: flex;
  min-width: 0;
  background-color: white;
  border-radius: 2px;
  margin: 2px;
  box-sizing: border-box;
  .keyword-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    padding: 3px;
    padding-left: 6px;
    box-sizing: border-box;
  }
  .remove-button {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    svg {
      opacity: 0.6;
      display: inline-block;
      fill: currentColor;
      line-height: 1;
      stroke: currentColor;
      stroke-width: 0;
    }
  }
`

const KeywordInput = ({ label, value, onChange }) => {
  const [keywords, setKeywords] = useState(value || []);
  const [inputValue, setInputValue] = useState("");

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  }

  const handleKeyPress = e => {
    if (e.key === "Enter" && inputValue.length > 0 && !keywords.includes(inputValue)) {
      const kw = [...keywords, inputValue];
      setKeywords(kw);
      onChange(kw);
      setInputValue("");
    }
  }

  const removeKeyword = (e) => {
    const kw = keywords.filter(item => (item !== e));
    setKeywords(kw);
    onChange(kw);
  }

  return (
    <Container>
      {
        keywords.length > 0 &&
        <div className="keywords-container">
          {
            keywords.map((item, i) => (
              <Keyword>
                <div className="keyword-name">{item}</div>
                <div 
                  role="button" 
                  class="remove-button" 
                  aria-label={item} 
                  onClick={() => removeKeyword(item)}
                ><svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg></div>
              </Keyword>
            ))
          }
        </div>
      }
      <input 
        placeholder={label}
        type="text"
        value={inputValue}
        onChange={onInputChange}
        onKeyPress={handleKeyPress}
      />
    </Container>
  );
}

export default KeywordInput;