import classNames from "classnames";
import { nanoid } from "nanoid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DropZone } from "./drop-zone";
import styles from "./file-picker.module.css";
import { FilesList } from "./files-list";
import BlackButton from "../BlackButton";
import Spinner from "../Spinner";

const MAX_FILES_NUMBER = 5;

const FilePicker = ({ accept, onSubmit, disabled }) => {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadStarted, setUploadStarted] = useState(false);

  // handler called when files are selected via the Dropzone component
  const handleOnChange = useCallback((_files) => {
    let filesArray = Array.from(_files);
    filesArray = filesArray.filter(file => {
      const match =  files.find(a => a?.file?.name === file.name);
      return !match
    });

    filesArray = filesArray.map((file) => ({
      id: nanoid(),
      file
    }));

    setFiles([...files, ...filesArray]);
    setProgress(0);
    setUploadStarted(false);
  }, [ files ]);

  // handle for removing files form the files list view
  const handleClearFile = useCallback((id) => {
    setFiles((prev) => prev.filter((file) => file.id !== id));
  }, []);

  // whether to show the progress bar or not
  const canShowProgress = useMemo(() => files.length > 0, [files.length]);

  // execute the upload operation
  const handleUpload = useCallback(async () => {
    try {
      setUploadStarted(true);
      const res = await onSubmit(files.map(item => item.file.fileObject), setProgress);
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setUploadStarted(false);
    }
  }, [ files.length ]);

  // set progress to zero when there are no files
  useEffect(() => {
    if (files.length < 1) {
      setProgress(0);
    }
  }, [files.length]);

  // set uploadStarted to false when the upload is complete
  useEffect(() => {
    if (progress === 100) {
      setUploadStarted(false);
    }
  }, [progress]);

  const uploadComplete = useMemo(() => progress === 100, [progress]);

  const renderUploadButton = () => {
    // if (uploadStarted) return <div className={styles.upload_started}>
    //   Uploading files.... <Spinner size="1.2rem"/>
    // </div>;
    if (files.length > 0) return <BlackButton
      onClick={handleUpload}
      disabled={uploadStarted || files.length > MAX_FILES_NUMBER}
    >
      {`Upload ${files.length} resumes`}
    </BlackButton>;
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {/* canvas */}
      <div className={styles.canvas_wrapper}>
        <DropZone onChange={handleOnChange} accept={accept} disabled={disabled}/>
      </div>

      {/* files listing */}
      {files.length ? (
        <div className={styles.files_list_wrapper}>
          <FilesList
            files={files}
            onClear={handleClearFile}
            uploadComplete={uploadComplete}
          />
        </div>
      ) : null}

      {
        files.length > MAX_FILES_NUMBER &&
        <div className={styles.max_files_number_exceeded}>
          Upload batch size is limited to {MAX_FILES_NUMBER} resumes. 
          Please contact us at sales@honestresume.io to upgrade.
        </div>
      }

      {/* progress bar */}
      {canShowProgress ? (
        <div className={styles.files_list_progress_wrapper}>
          <progress value={progress} max={100} style={{ width: "100%" }} />
        </div>
      ) : null}

      {/* {
        canShowProgress &&
        files.length <= MAX_FILES_NUMBER &&
        <div className={styles.disclaimer}>
          Please note: These resumes will be considered up to date as of today. 
          If they have employment or education with a date of the form, 
          "From date - present", <b>present</b> will be considered to be today. 
          This can impact YOE calculations.
        </div>
      } */}

      {/* upload button */}
      {renderUploadButton()}
    </div>
  );
};

export { FilePicker };
