import React, { useState, useRef } from "react";
import styled from 'styled-components';
import NavBar from "../components/NavBar";
import WhiteButton from "../components/WhiteButton";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import ParsedResume from "../components/ParsedResume";
import { reviewInference, assessmentStatus } from "../services";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  text-align: left;
  .content-area {
    width: 100%;
    max-width: var(--max-content-width);
    padding: 0 2rem;
    box-sizing: border-box;

    input, textarea {
        all: unset;
        display: block;
        width: 20rem;
        border-bottom: 1px solid var(--color-gray0);
        height: 3rem;
        padding: 0;
        margin-top: 1rem;
        &::placeholder {
            color: var(--color-gray1);
            font-size: 0.9em;
        }
        &.error {
            border-bottom: 1px solid var(--color-red0);
        }
    }

    textarea::placeholder {
        transform: translateY(1rem);
    }
    .button-container {
      display: flex;
    }
    .slelected-file {
      margin-top: 1rem;
    }
    .separator {
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--color-gray2);
    }
    .uploading-in-progress {
      height: 3.5rem;
      width: 23rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      p {
        margin-left: 1rem;
      }
    }
    .parsed-data {
      margin-top: 2rem;
      font-size: 1.1rem;
    }
    .error {
      margin-top: 1rem;
      color: var(--color-red0);
    }
  }
  @media screen and (max-width: 40rem) {
    .content-area {
      margin-top: 2rem;
    }
  }

`

const ReviewInference = () => {
  const [uploadingStatusLabel, setUploadingStatus] = useState(null);
  const [data, setData]  = useState(null);
  const [errorMessage, setErrorMessage]  = useState("");
  const [resumeId, setResumeId] = useState("");
  const loading = useRef(false);

  const assessmentStatusHandler = (uuid, timeout=2000) => {
    setTimeout(async () => {
      let status = null;
      try {
        status = await assessmentStatus(uuid);
      } catch (err) {
        console.error(err)
      }       
      if (loading.current) {
        if (status) {
          setUploadingStatus(status?.progress_status);
        }
        assessmentStatusHandler(uuid, 5000);
      }
    }, timeout)
  };

  const onSubmit = async () => {
    setData(null)
    setUploadingStatus("Fetching...");
    setErrorMessage("");
    try {
      loading.current = true;
      const parsedData = await reviewInference(resumeId, assessmentStatusHandler);  
      if (parsedData) {
        setData(parsedData);
      }
    } catch(err) {
      console.log(err);
      if (err.response.status >= 400 && err.response.status <= 499) {
        setErrorMessage(err.response.data);
      } else {
        setErrorMessage("We have encountered an issue while processing the resume, please try again after sometime.");
      }
    }
    finally {
      loading.current = false;
      setResumeId("");
      setUploadingStatus(null)
    }
  };

  const onInputChange = async (e) => {
    setResumeId(e.target.value);
  }

  return (
    <>
    <Container className="App">
      <NavBar/>
      <div className="content-area">
        <div className="button-container">
            {
              uploadingStatusLabel ?
              <div className="uploading-in-progress">
                <Spinner/>
                <p>{uploadingStatusLabel}</p>
              </div> :
              <input 
              placeholder="resume id"
              type="text"
              value={resumeId}
              onChange={onInputChange}
            />
            }
            
            <WhiteButton 
              style={{
                padding: '0 2rem', 
                height: '3.5rem', 
                marginLeft: '1.5rem'
              }}
              disabled={!resumeId || uploadingStatusLabel}
              onClick={onSubmit}
            >Submit</WhiteButton>
        </div>
        <br/>
        {
          errorMessage &&
          <div className="error">{errorMessage}</div>
        }
        <ParsedResume data={data}/>
      </div>  
    </Container>
    <Footer/>
    </>
  );
};

export default ReviewInference;