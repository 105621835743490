import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { getAtsApiKey, saveAtsApiKey } from "../services";
import { useAuth } from '../hooks';
import RoleNavMenu from "../components/RoleNavMenu";
import Spinner from "../components/Spinner";
import Select from 'react-select';
import BlueButton from "../components/BlueButton";
import PageContainer from "../components/PageContainer";

const Container = styled.div`
    margin-top: 7rem;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: row;
    .content {
      flex: 1;
      position: relative;
      padding: 0 0.5rem;
      font-family: Roboto;
      font-weight: 400;
      label {
        font-size: 1rem;
      }
      .ats-steps {
        font-size: 0.9rem;
        margin-top: 1rem;
        a {
          color: black !important;
        }
        &.disabled {
          opacity: 0.4;
          a {
            pointer-events: none;
          }
        }
      }
      .ats-key-container {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;
        align-items: center;
        input {
          all: unset;
          padding: 0 0.5rem;
          height: 2rem;
          border-color: hsl(0, 0%, 80%) !important;
          border: 1px solid var(--color-gray-2);
          width: 20rem;
        }
      }
    }
    .no-roles {
      font-size: 0.75rem;
      line-height: 1.5;
      margin-top: 0.5rem;
      font-weight: 400;
      color: var(--color-gray-1);
    }
    .filter-container {
      width: 14rem;
      margin-bottom: 2rem;
    }
    .spacer {
      flex: 1;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
    }
    .spinner {
      position: fixed;
      right: 0;
      top: 10rem;
      bottom: 0;
      left: 0;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }
  .ats-select {
    width: 14.5rem;
    .ats__single-value, .ats__placeholder, .ats__input {
      font-weight: 400 !important;
      padding: 0.5rem 0 0.5rem 0.5rem !important;
      font-size: 0.9rem;
      font-family: Roboto;
      color: black;
    }
    .ats__control {
      /* background-color: #c8eecf; */
      border-color: hsl(0, 0%, 80%) !important;
      /* border: none !important; */
      box-shadow: none;
      .ats__input-container {
        margin: 0;
        padding: 0;
      }
      .ats__indicators {
        .ats__indicator-separator {
          display: none;
        }
        .ats__dropdown-indicator {
          color: black !important;
          font-weight: 500 !important;
          opacity: 0.5;
        }
      }
    }
    .ats__menu {
        z-index: 1;
        margin-top: 0 !important;
        padding: 0 !important;
        .ats__menu-list {
          z-index: 12;
          padding: 0 !important;
          .ats__option {
            color: black;
            height: auto;
            font-weight: 400 !important;
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem !important;
            font-size: 0.9rem;
            box-sizing: border-box;
            font-family: Roboto;
            &.ats__option--is-selected {
              color: white;
            }
            &.ats__option--is-focused {
              
            }
          }
        }
      }
  }
`

const ATS_OPTIONS = [
  {
    label: 'GreenHouse', 
    value: 'GreenHouse', 
    url: 'https://help.merge.dev/en/articles/5431484-greenhouse-how-do-i-link-my-account'
  },
  {
    label: 'Lever', 
    value: 'Lever',
    url: 'https://help.merge.dev/en/articles/6250014-lever-how-do-i-link-my-sandbox-account',
  },
  {
    label: 'BambooHR', 
    value: 'BambooHR',
    url: 'https://help.merge.dev/en/articles/6597267-bamboohr-how-do-i-link-using-oauth'
  },
  {
    label: 'Ashby', 
    value: 'Ashby',
    url: 'https://help.merge.dev/en/articles/5431238-ashby-how-do-i-link-my-account'
  },
  {
    label: 'SmartRecruiters', 
    value: 'SmartRecruiters',
    url: 'https://help.merge.dev/en/articles/5528423-smartrecruiters-how-do-i-link-my-account'
  }
]

const ATSIntegration = () => {
  const [ selectedATS , setSelectedATS ] = useState(null);
  const [ savedATS, setSavedATS] = useState(null);
  const [ savedAPIKey, setSavedAPIKey] = useState(null);
  const [ currentAPIKey, setCurrentAPIKey] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const isLoading = useRef(false);
  useAuth();

  useEffect(() => {
    const getATS = async () => {
      isLoading.current = true;
      setLoading(true);
      try {
        const ats = await getAtsApiKey();
        if (ats?.api_key && ats?.ats_name) {
          setSavedATS(ats?.ats_name);
          setSavedAPIKey(ats?.api_key);
          setCurrentAPIKey(ats?.api_key);
          setSelectedATS(ATS_OPTIONS?.find(x => x.value === ats?.ats_name));
        }
      } catch (err) {
        console.log(err);
      } finally {
        isLoading.current = false;
        setLoading(false);
      }
    }  
    if (!isLoading.current) {
      getATS();
    }
  }, [])

  const onSubmit = async () => {
    isLoading.current = true;
    setLoading(true);
    try {
      const ats_name = selectedATS?.value;
      const api_key = currentAPIKey;
      await saveAtsApiKey(ats_name, api_key);
      setSavedATS(ats_name);
      setSavedAPIKey(api_key);
    } catch (err) {
      console.log(err);
    } finally {
      isLoading.current = false;
      setLoading(false);
    }
  }

  const onATSSelect = async (ats) => {
    if (ats.value !== selectedATS?.value) {
      if (ats.value === savedATS) {
        setCurrentAPIKey(savedAPIKey);
      } else {
        setCurrentAPIKey('');
      }
      setSelectedATS(ats);
    }
  }

  const onChange = (e) => {
    setCurrentAPIKey(e.target.value);
  }

  const isDisabled = loading || !selectedATS;

  return (
    <PageContainer title={'ATS Integration'} selected={'ats'}>
      <Container>  
        <div className="content">
          <Select
              className="ats-select"
              classNamePrefix="ats" 
              placeholder="Select ATS" 
              options={ATS_OPTIONS}
              onChange={onATSSelect}
              value={selectedATS}
            />
          <div className={"ats-steps" + (selectedATS?.url ? '' : ' disabled')}>
            Please follow the steps <a href={selectedATS?.url || ''} target='__blank'>here</a> and 
            create an API key and submit it below.
          </div>
          <div className="ats-key-container">
            <label>API Key</label>
            <input 
              name="api_key" 
              disabled={isDisabled} 
              value={currentAPIKey || ''} 
              onChange={onChange}/>
            <BlueButton onClick={onSubmit} disabled={isDisabled}>Save</BlueButton>
          </div>
        </div>   
      </Container>
    </PageContainer>
  );
};

export default ATSIntegration;